<template>
    <b-overlay :show="loading" class="user-reset-password card my-5 p-4">

                <h2>{{ $i18n.gt('FORGOT_PASSWORD_HEADING') }}</h2>
                <b-alert show variant="info" class="mt-3" v-if="state === SUBMITTING">
                    {{ $i18n.gt('FORGOT_PASSWORD_INSTRUCTIONS') }}
                </b-alert>

                <data-form v-if="state === SUBMITTING"
                           :save-button-label="$i18n.gt('FORGOT_PASSWORD_SUBMIT')"
                           :field-model="userFields"
                           :field-groups="fieldGroups"
                           :on-submit-handler="onSubmit"
                           :top-submit-button="false"
                           :bottom-submit-button="true"/>

                <div v-if="state === SUBMITTED">
                    <b-alert show variant="success" class="mt-3">
                        {{ $i18n.gt('FORGOT_PASSWORD_SUBMITTED') }}
                    </b-alert>
                </div>

                <div v-if="state === SUBMIT_ERROR">
                    <b-alert show variant="danger" class="mt-3">
                        {{ $i18n.gt('FORGOT_PASSWORD_ERROR')}}
                    </b-alert>
                </div>

    </b-overlay>
</template>

<script>

import Vue from 'vue'
import UserFields from '@/models/User'

/**
 * Page for a User to reset their password
 */
export default Vue.extend({
    name: 'UserPassword',
    data() {
        return {
            userFields: UserFields,
            fieldGroups: [
                ['email'],
            ],
            SUBMITTING: 1,
            SUBMITTED: 2,
            SUBMIT_ERROR: 3,
            state: 1,
            loading: false,
        }
    },
    computed: {
        email() {
            return this.$route.params.email
        },
        confirm() {
            return this.$route.params.confirm
        }
    },
    methods: {
        onSubmit(modelInstance) {
            this.loading = true
            return this.$api.resetPassword(this, modelInstance.email, modelInstance.password)
                .then(response => {
                        this.loading = false
                        this.state = this.SUBMITTED
                    },
                    response => {
                        this.loading = false
                        this.state = this.SUBMIT_ERROR
                    })
                .catch(error => {
                    this.loading = false
                    this.state = this.SUBMIT_ERROR
                })
        }
    },
})

</script>

<style lang="scss">
.user-reset-password {
    max-width:460px!important;
    margin: 0 auto;
}
</style>
