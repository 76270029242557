<template>
    <div class="locations-view">
        <spinner-until :condition="locLoaded">
            <b-row class="m-3 mt-4 justify-content-between" v-if="$mq === 'xl' || $mq === 'lg'">
                <h1>{{ location.name }}</h1>
                <div>
                    <b-btn variant="success" class=" ml-2 btn-icon"
                           @click="navigate(`/locations/${locId}/edit`)"
                           v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')">
                        <b-icon-pencil/>
                        {{ $i18n.gt('LOCATIONS_EDIT_HEADING') }}
                    </b-btn>
                    <b-btn class="delete-loc ml-2 btn-icon" variant="danger" @click="deleteLoc()"
                           v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')">
                        <b-icon-x-lg/>
                        {{ $i18n.gt('LOCATIONS_DELETE') }}
                    </b-btn>
                </div>
            </b-row>
            <b-col v-else class="px-0 mb-2">
                <h1 class="m-1 mt-4">{{location.name}}</h1>
                <b-row class="m-1">
                    <b-btn variant="success" class="btn-icon px-2"
                           @click="navigate(`/locations/${locId}/edit`)"
                           v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')">
                        {{ $i18n.gt('LOCATIONS_EDIT_HEADING') }}
                    </b-btn>
                    <b-btn class="delete-loc btn-icon ml-2 px-2" variant="danger" @click="deleteLoc()"
                           v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')">
                        {{ $i18n.gt('LOCATIONS_DELETE') }}
                    </b-btn>
                </b-row>
            </b-col>
            <b-card>
                <b-row>
                    <div class="col-6">
                        <strong> {{ $i18n.gt('LOCATION_NOTES_FIELD') }} </strong>
                        <p>{{ location.notes }}</p>
                        <strong>{{ $i18n.gt('CARDS_CARDS') }}</strong>
                        <ul class="ml-3">
                            <li v-for="(card, i) in cards" :key="i">
                                <router-link :to="`/locations/${locId}/cards/${card.id}/view`">
                                    {{card.name}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <image-viewer class="col-6 text-right" :source="location.image"/>
                </b-row>
            </b-card>
        </spinner-until>
    </div>
</template>

<script>

import SpinnerUntil from "@/components/SpinnerUntil.vue";

export default {
    name: 'LocationsView',
    components: {SpinnerUntil},
    props: {
        locId: String,
    },
    data() {
        return {
            location: {},
            locLoaded: false,
            cards: [],
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        getLocation(locId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/`, locId)
        },
        getCards(locId) {
            return this.$api.getAllItems(this, `users/${this.userId}/locations/${locId}/cards`)
        },
        async deleteLoc() {
            if (await this.$bvModal.msgBoxConfirm(this.$i18n.gt('LOCATIONS_CONFIRM_DELETE'), {okVariant: 'danger', okTitle: this.$i18n.gt('DELETE_TOOLTIP')})) {
                await this.$api.deleteItem(this, `users/${this.userId}/locations`, {id: this.locId})
                if (this.location.image) {
                    await this.$api.deleteStorageItem(this, this.location.image)
                }
                this.$toasted.success(this.$i18n.gt('LOCATIONS_DELETED_SUCCESS'))
                this.navigate('/locations')
            }
        }
    },
    async mounted() {
        this.location = await this.getLocation(this.locId)
        this.cards = await this.getCards(this.locId)
        this.locLoaded = true
    }
}
</script>

<style lang="scss">

.locations-view {
    @media (min-width: 768px) {
        max-width: 70%;
    }
    > .spinner-until {
        max-width: 1200px !important;
        margin: 0 auto;
    }
    img {
        max-height: 20em;
    }
    ul {
        list-style: none;
    }
}

</style>