<template>
    <div class="pests-create narrow-form">
        <h1 class="page-heading">{{ $i18n.gt('PESTS_CREATE_HEADING') }}</h1>

        <data-form :field-model="pestModel"
                   :on-submit-handler="createPest"
                   :field-groups="fieldGroups"
                   cancel-route="/pests"
                   bottom-submit-button bottom-cancel-button/>

    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Pest from "../models/Pest"

export default {
    name: 'PestsCreate',
    data() {
        return {
            pestModel: Pest,
            fieldGroups: [
                ['name', 'name_es', 'scientificName', 'pestTypeId', 'mainImage', 'factSheetUrl', 'symptoms', 'beneficials', 'textSections'],
            ]
        }
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async createPest(modelInstance) {
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadFile(modelInstance, 'mainImage')
            await Utils.uploadModelTextSectionImages(modelInstance)

            // NB: pestTypeId is 'pest_types/xxx'
            const itemResponse = await this.$api.createItem(this, `${modelInstance.pestTypeId}/pests/`, modelInstance)

            // reload pest list in store to include new name
            await Pest.loadPestsNested()
            await Pest.loadPestsUnnested()

            this.navigate(`/pests/view/${itemResponse.id}`)
            this.$toasted.success(this.$i18n.gt('PEST_UPDATED_SUCCESS'))
        }
    }
}
</script>

<style lang="scss">
.pests-create {
  label.custom-file-label {
    z-index: 0;
  }
}
</style>
