/**
 * Model class for Symptoms
 */

import Api from '@/providers/Api'
import * as _ from 'lodash'
import { store } from '@/store'
import i18n from '@/providers/i18n'
import Pest from "@/models/Pest";
import Disease from "@/models/Disease";
import {required} from "vuelidate/lib/validators";

export default {

    // load master list of symptoms into the store
    async loadSymptoms () {
        store.state.symptoms = await Api.getAllItems(null, 'symptoms')
    },

    // build checkbox options based on master list
    buildSymptomOptions () {
        const symptoms = store.state.symptoms
        let options = _.map(symptoms, (symptom) => {
            return {
                value: `symptoms/${symptom.id}`, text: i18n.getSymptomName(symptom.id)
            }
        })
        return _.sortBy(options, ['text'])
    },

    fields: {
        name: {
            translationKey: 'SYMPTOM_NAME_FIELD',
            type: 'text',
            validations: { required }
        },
        name_es: {
            translationKey: 'SYMPTOM_NAME_ES_FIELD',
            type: 'text',
            // validations: { required }
        },
        textSections: {
            translationKey: 'SYMPTOM_TEXT_SECTIONS_FIELD',
            type: 'textsections',
        },
        pests: {
            translationKey: 'SYMPTOM_PESTS_FIELD',
            type: 'checksgroups',
            options: [],
            class: 'symptom-pests',
            loadOptions: async function(model) {
                this.options = Pest.buildPestOptionsNested(this)
            },
            collapsible: true,
        },
        diseases: {
            translationKey: 'SYMPTOM_DISEASES_FIELD',
            type: 'checks',
            options: [],
            loadOptions: async function(model) {
                this.options = Disease.buildDiseaseOptions()
            },
            collapsible: true,
        },
    }

}
