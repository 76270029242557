<template>
    <div class="text-section-view">
        <b-row v-if="section.image" class="text-section-view__image mb-3">
            <b-col cols="12" v-if="section.image" @click="showingModal = true">
                <image-viewer :source="section.image"/>
            </b-col>
        </b-row>
        <b-row class="text-section-view__text">
            <b-col cols="12">
                <h4>{{ heading }}</h4>
                <p v-html="text"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>

export default {
    name: 'TextSectionView',
    props: {
        section: Object,
    },
    computed: {
        heading() {
            return this.$i18n.getSectionFieldValue(this.section, 'heading')
        },
        text() {
            return this.$i18n.getSectionFieldValue(this.section, 'text')
        }
    },
}
</script>

<style lang="scss">
.text-section-view {
  clear: both;
  margin-bottom: 10px;
    ul,ol {
        padding-left: 1rem;
    }
  p,ul,ol   {
      &:last-child {
          margin-bottom:0!important;
      }
  }
}

.text-section-view__image img {
    max-width: 200px;
}

</style>
