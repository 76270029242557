<template>
    <div class="data-form-input-multiselect">
        <multiselect v-model="selectedItems"
                     :label="field.multiselectOptions.label"
                     :track-by="field.multiselectOptions.trackBy || 'id'"
                     :placeholder="field.multiselectOptions.placeholder || 'Type to search'"
                     open-direction="bottom"
                     :options="items"
                     :multiple="field.multiselectOptions.multiple || false"
                     :searchable="true"
                     :loading="isLoading"
                     :internal-search="false"
                     :clear-on-select="false"
                     :close-on-select="true"
                     :options-limit="300"
                     :limit="10"
                     :max-height="field.multiselectOptions.maxHeight || 600"
                     :show-no-results="true"
                     :hide-selected="field.multiselectOptions.hideSelected"
                     @search-change="debouncedSearch"
                     @select="itemSelected"
                     @remove="itemSelected">

            <span slot="noResult">
                {{ field.multiselectOptions.noMatchMessage || 'No matches found (Enter at least 2 characters)' }}
            </span>

            <span slot="noOptions">
                Start typing...
            </span>

            <!-- display current value (for single mode) -->
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                    <span class="option__title">
                        <span v-if="props.option.query">
                            <!-- unsaved no-match value -->
                            {{ props.option.query }}
                        </span>
                        <span v-else>
                            <!-- saved value -->
                            {{ props.option[label] }}
                        </span>
                    </span>
                    <b-btn size="sm" variant="default" class="py-0 px-1"
                           v-if="props.option[label] || props.option.query"
                           @mousedown.prevent.stop="clearSelection">
                        <b-icon-x-circle/>
                    </b-btn>
                </span>
            </template>

            <!-- display option in search results -->
            <template slot="option" slot-scope="props">
                <div class="option__desc">
                    <span class="option__title">
                        <!-- matching result -->
                        {{ props.option[label] }}
                    </span>
                    <span v-if="props.option.query">
                        <!-- result inserted if no match -->
                        {{ props.option.query }}
                    </span>
                </div>
            </template>

        </multiselect>
    </div>
</template>

<script>
import Vue from 'vue'
import * as _ from 'lodash'
import Multiselect from 'vue-multiselect'

/**
 * A wrapper for the vue-multiselect widget, for use with DataForms.
 */

export default Vue.extend({
    name: 'DataFormInputMultiselect',
    components: { Multiselect },
    props: {
        value: {
            required: true
        },
        $v: { type: Object, required: true },
        fieldModel: { type: Object, required: true }, // vuelidate validation model
        fieldName: String,
        modelInstance: Object,
        form: Object,
        disabled: Boolean,
        state: Boolean,
    },
    data () {
        return {
            selectedItems: [],  // this is a single object, not an array, if multiple mode is false
            items: [],
            isLoading: false,
        }
    },
    computed: {
        localValue: {
            get () {
                return this.selectedItems
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        field () {
            return this.fieldModel.fields[this.fieldName]
        },
        label () {
            return this.field.multiselectOptions.label
        }
    },
    methods: {
        // this is replaced on mount - empty version provided to prevent Vue warning
        debouncedSearch () {},
        // trigger search for typeahead
        async doSearch (query) {
            this.isLoading = true
            if (this.field.doSearch && query) {
                this.items = await this.field.doSearch(query, this.modelInstance)
            } else {
                this.items = []
            }
            this.isLoading = false
        },
        // called when a single item is selected or removed
        itemSelected (item) {
            this.updateSelected()
        },
        clearSelection () {
            if (this.field.multiselectOptions.multiple) {
                this.selectedItems = []
            } else {
                this.selectedItems = null
            }
            this.updateSelected()
        },
        updateSelected () {
            Vue.nextTick(() => {
                // NB: call this before setting localValue, so that any resulting changes will be autosaved
                if (this.field.multiselectOptions.onSelect) {
                    this.field.multiselectOptions.onSelect(this.selectedItems, this.form, this.$v.form, this.fieldModel, this)
                }
                this.localValue = this.selectedItems
            })
        }
    },
    mounted () {
        this.selectedItems = this.value
        this.debouncedSearch = _.debounce(this.doSearch, 600)
        this.doSearch(null)
    },
    watch: {
        value: {
            immediate: true,
            handler: function (newVal, oldVal) {
                this.selectedItems = newVal
            }
        }
    }
})
</script>

<style lang="scss">
@import "../../node_modules/vue-multiselect/dist/vue-multiselect.min";
</style>