<template>

    <div class="spinner-until">

        <div class="text-center spinner-until__spinner"
             v-if="!ready">
            <b-spinner variant="primary"/>
        </div>

        <template v-if="ready">
            <slot/>
        </template>
    </div>

</template>

<script>
import Vue from 'vue'

/**
 * Component to display a 'loading' spinner in place of the child component until a given condition is met.
 */
export default Vue.extend({
    name: 'SpinnerUntil',
    props: {
        condition: Boolean
    },
    computed: {
        ready () {
            return this.condition
        }
    }
})
</script>

<style lang="scss">

.spinner-until__spinner {
    margin: 50px auto;
}
</style>
