<template>
    <div class="card-actions row">
        <b-col>
        <spinner-until :condition="cardLoaded">
            <div class="card-top-row">

                    <b-badge variant="success">
                        <template v-if="locId">
                            <b-icon-house-door-fill/>
                            {{ currentLocation.name }}
                        </template>
                        <template v-else>
                            <em>{{ $i18n.gt('NO_LOCATION') }}</em>
                        </template>
                    </b-badge>
                    <b-form-select :options="cardOptions" v-model="selectedCard" @change="loadNewCard" :disabled="disabled"></b-form-select>
                    <div class="card-actions">
                        <b-btn variant="success"
                               @click="$router.push(`/locations/${locId}/cards/${cardId}/scouts/create`)"
                               :disabled="currentAction === 'scouts/create' || !locId || !cardId">
                            <b-icon-check-circle/>
                            {{ $i18n.gt('SCOUT_TOOLTIP') }}
                            <b-icon-plus-circle-fill/>
                        </b-btn>
                        <b-btn variant="success"
                               @click="$router.push(`/locations/${locId}/cards/${cardId}/applications/create`)"
                               :disabled="currentAction === 'applications/create' || !locId || !cardId">
                            <b-icon-bar-chart-fill/>
                            {{ $i18n.gt('APPLICATION_TOOLTIP') }}
                            <b-icon-plus-circle-fill/>
                        </b-btn>
                        <b-btn variant="success"
                               @click="$router.push(`/locations/${locId}/cards/${cardId}/chart`)"
                               :disabled="currentAction === 'chart' || !locId || !cardId">
                            <b-icon-graph-up/>
                            {{ $i18n.gt('CHART_TOOLTIP') }}
                        </b-btn>
                        <b-btn variant="success"
                               @click="$router.push(`/locations/${locId}/cards/${cardId}/view`)"
                               :disabled="currentAction === 'view' || !locId || !cardId">
                            <img src="../../assets/icons/qr-code-white.svg" style="width: 14px; height: 14px;">
                            {{ $i18n.gt('QR_TOOLTIP') }}
                        </b-btn>
                    </div>

            </div>
        </spinner-until>
        </b-col>
    </div>
</template>

<script>

import CardFields from '../models/Card'
import SpinnerUntil from "@/components/SpinnerUntil.vue";

export default {
    name: 'CardActions',
    components: {SpinnerUntil},
    props: {
        locId: String,
        cardId: String,
        disabled: Boolean,
        confirmChange: String,
    },
    data() {
        return {
            selectedCard: null,
            cardOptions: [],
            cardModel: CardFields,
            currentLocation: {},
            prevCard: '',
            cardLoaded: false,
        }
    },
    computed: {
        currentAction() {
            const url = window.location.href
            if (url.includes('scouts/create') || (url.includes('scouts') && url.includes('edit'))) {
                return 'scouts/create'
            } else if (url.includes('/applications/create') || (url.includes('applications') && url.includes('edit'))) {
                return 'applications/create'
            } else if (url.includes('chart')) {
                return 'chart'
            } else if (url.includes('view')) {
                return 'view'
            }
            return ''
        },
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async getCardOptions() {
            const cards = { options: [] }
            await this.cardModel.loadCardOptionsGrouped(cards, true)
            if (!this.locId || !this.cardId) {
                cards.options.push({text: 'Select a Card', value: null})
            }
            return cards.options
        },
        getCard(locId, cardId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/${locId}/cards`, cardId)
        },
        getLocation(locId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/`, locId)
        },
        async loadNewCard(path) {
            const segments = path.split('/')
            if ((this.confirmChange && this.prevCard && await this.$bvModal.msgBoxConfirm(this.$i18n.gt(this.confirmChange))) || !this.confirmChange || !this.prevCard) {
                this.cardLoaded = false
                this.currentCard = await this.getCard(segments[3], segments[5])
                this.currentLocation = await this.getLocation(segments[3])
                this.cardLoaded = true
                this.prevCard = this.selectedCard
                this.$emit('card-changed', `/locations/${segments[3]}/cards/${segments[5]}/${this.currentAction}`)
                this.$router.push(`/locations/${segments[3]}/cards/${segments[5]}/${this.currentAction}`)
            } else {
                this.selectedCard = this.prevCard
            }
        },
    },
    async mounted() {
        this.cardOptions = await this.getCardOptions()
        if (this.locId && this.cardId) {
            this.currentLocation = await this.getLocation(this.locId)
            this.selectedCard = `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}`
            this.prevCard = this.selectedCard
        }
        this.cardLoaded = true
    }
}

</script>

<style lang="scss">
@import "/sass/variables_mixins";

.card-actions {
    select {
        width: auto;
    }
}

.card-top-row {
    padding: 20px 0 0 0;

    .card-actions {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media (max-width: 767px) {
        select {
            margin-bottom: .5em;
            width: 100%;
        }
        .btn {
            margin-top: .5em;
        }
        .card-actions {
            justify-content: space-between;
        }
        .btn-success {width:48.5%;}
    }

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .badge-success {
            height:24px;
            line-height:24px;
        }
        > * {
            //margin: 15px 0;
        }
        .card-actions {
            width: 100%;
            height:44px;
        }

    }

    @media (min-width:768px) and (max-width:1023px) {
        .btn-success {width:24%;}
    }
    @media (max-width: 1023px) {
        .badge-success {
            margin-bottom: 1em;
        }
    }
    @media (min-width: 1024px) {
        .card-actions {
            width: auto;
        }
        .btn-success {
            margin-left: .5em;
        }
    }

    .btn-success {
        height: 44px;
    }

    :not(:last-child).btn-success {
        padding: 8px;
        font-size: 16px;
        //margin-right: 5px;
    }

    :last-child.btn-success {

    }

    .badge-success {
        display: inline-flex;
        color: #076A1A;
        font-size: 16px;
        padding: 10px 26px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        background-color: #90F1A3;
        background-color: darken($light-green,5%);
        border-radius: 12px;
        height: 100%;

    }
}

</style>
