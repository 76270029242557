<template>
    <div class="main-footer">

        <footer v-cloak>
            <div class="container">
                <b-row class="footer__links pb-2">
                    <router-link class="footer-terms" to="/terms">{{ $i18n.gt('TERMS_LABEL') }}</router-link>
                    <router-link class="footer-terms ml-3" to="/privacy">{{$i18n.gt('PRIVACY')}}</router-link>
                </b-row>
                <p class="footer-copyright">
                    © {{ $i18n.gt('TERMS_1') }} {{ year }} {{ $i18n.gt('TERMS_2') }}
                </p>
            </div>
        </footer>

    </div>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
    name: 'MainFooter',
    computed: {
        year () {
            return new Date().getFullYear()
        }
    }
})
</script>

<style lang="scss">
@import "/sass/variables_mixins";

.main-footer {

    footer {
        text-align: center;
        font-size: 12px !important;
        //border-top:solid 4px rgba(255,255,255,.15);
        background-color: darken($green,18%);
        p {
            margin-bottom: .5rem;
        }
        > .container {
            padding-top: 2em;
            padding-bottom: 2em;
            @media (min-width: 1024px) {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: center;
            }
        }
        @media(max-width: 1023px) {
            .row {
                justify-content: center;
            }
        }
    }

    .footer__links {
        a {
            color: darken($light-green, 10%);
            &:hover {
                color: #64ED71;
                text-decoration: none;
            }
        }
    }

    p.footer-copyright {
        color: #76AC6D;
        @media (min-width: 1024px) {
            //margin-bottom: 0;
        }
    }

    #root-app footer {
        background-color: var(--black);
        position: relative;
        /*z-index: 1;*/
    }

    @media (max-width: 599px) {
        .footer-copyright__who-credit {
            font-size: 12px;
            display: block;
        }
    }

    .footer-copyright__divider {
        @media (max-width: 599px) {
            display: none;
        }
        @media (min-width: 600px) {
            margin: 0 10px;
        }
    }
}

</style>