<template>
    <div class="home-public">

        <b-form-row class="m-auto justify-content-between home-public__ctas">
            <b-col sm="6" md="4" class="mb-2">
                <b-overlay :show="loggingIn" class="home-card home-card-login">
                    <b-input v-model="email" placeholder="Email"></b-input>
                    <b-form-input v-model="password" type="password" class="my-2" placeholder="Password"></b-form-input>
                    <b-button variant="success" @click="login" class="mx-auto my-2">{{ $i18n.gt('LOGIN') }}</b-button>
                    <router-link to="/resetPassword" class="mx-auto mt-2">{{ $i18n.gt('FORGOT_PASSWORD') }}</router-link>
                </b-overlay>
            </b-col>
            <b-col sm="6" md="4" class="mb-2" v-if="!isNative">
                <div class="home-card home-card-links">
                    <h4 class="mx-auto text-center">Links to play and Apple stores to purchase the app</h4>
                </div>
            </b-col>
            <b-col sm="6" md="4" class="mb-2" v-else>
                <div class="home-card home-card-links mx-auto">
                    <h4 class="text-center mx-auto">{{ $i18n.gt('HOME_CREATE_TIP') }}</h4>
                    <br>
                    <router-link to="/signup" class="btn btn-primary mt-1 mx-auto">{{ $i18n.gt('HOME_CREATE_LABEL') }}</router-link>
                </div>
            </b-col>
            <b-col sm="12" md="4">
                <div class="home-card home-card-about">
                    <h4 class="mx-auto text-center mb-2">{{ $i18n.gt('LEARN_MORE') }}</h4>
                    <b-button variant="success" class="mx-auto about-btn" @click="$router.push('/about')">{{ $i18n.gt('ABOUT_LABEL') }}</b-button>
                </div>
            </b-col>
        </b-form-row>

        <b-form-row class="mx-auto justify-content-center mt-2 mt-md-1">
            <b-col cols="12">
                <div class="home-card disclaimer p-3 mx-auto">
                    <small>
                        {{ $i18n.gt('HOME_DISCLAIMER') }}
                    </small>
                </div>
            </b-col>
        </b-form-row>

    </div>
</template>

<script>

import {Capacitor} from "@capacitor/core";

export default {
    name: 'Home',
    data() {
        return {
            loggingIn: false,
            email: null,
            password: null,
        }
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        },
        isNative() {
            return Capacitor.isNativePlatform()
        },
    },
    methods: {
        async login () {
            this.loggingIn = true
            await this.$api.loginUser(this, this.email, this.password, false)
            this.loggingIn = false
        }
    }
}
</script>

<style lang="scss">
    @import '/sass/variables_mixins';

body {
    margin: 0;
}

/*
.home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    box-sizing: border-box;
}

.home-public {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
*/


.main-footer {
    //margin-top: auto;
}


.home, .home-public {
    min-height: calc(100vh - 182px);
    @media (min-width:1024px) {
        min-height: calc(100vh - 188px);
    }
}

.home {
    overflow: hidden;
    .main-content & {
        margin-left:-20px;
        margin-right:-20px;
    }
}

.home-public {
    background: url("../../assets/images/home-background.png");
    background-size: cover;
    padding:20px;
    @media (min-width:768px) {
        padding:80px 20px 20px;
    }

    a {
        display: block;
    }

    .top-link {
        text-align: right;

        a {
            max-width: 350px;
            display: inline-block;
        }
    }
}

.home-card {
    border-radius: 5px;
    border: 9px solid #3C964E;
    background: rgba(214, 237, 206, 0.95);
    //width:290px;
    display: flex;
    flex-direction: column;
    padding:2em;
    min-height:100%;

    @media (min-width:940px) {
        min-height: 300px;
        max-width:300px;
    }

    &.about {
        width:unset;
        min-height: unset;
    }

    .about-btn {
        width: fit-content;
    }

    &.disclaimer {
        border-radius: unset;
        border: 0;
        max-width: 900px;
        width: unset;
        min-height: unset;
        box-shadow: 0 0 30px rgba(0, 75, 35,.5);
        color:$medium-grey;
    }

    .login__remember {
        color:$medium-grey;
    }
}

.home-public__ctas {
    max-width:900px;
}

    @media (min-width:576px) {
        .home-card-about {
            //margin-top:.5em;
        }
    }


</style>