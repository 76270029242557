<template>
    <div class="pesticides-create narrow-form">
        <h1 class="page-heading">{{ $i18n.gt('PESTICIDES_CREATE_HEADING') }}</h1>

        <data-form :field-model="pesticideModel"
                   :on-submit-handler="createPesticide"
                   :field-groups="fieldGroups"
                   cancel-route="/pesticides"
                   bottom-submit-button bottom-cancel-button/>

    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Pesticide from "../models/Pesticide"

export default {
    name: 'PesticidesCreate',
    data() {
        return {
            pesticideModel: Pesticide,
            fieldGroups: [
                ['name', 'name_es', 'textSections'],
            ]
        }
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async createPesticide(modelInstance) {
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadModelTextSectionImages(modelInstance)

            const itemResponse = await this.$api.createItem(this, `pesticides/`, modelInstance)

            // reload pesticide list in store to include new name
            await Pesticide.loadPesticides()

            this.navigate(`/pesticides`)
        }
    }
}
</script>

<style lang="scss">
.pesticides-create {
  label.custom-file-label {
    z-index: 0;
  }
}
</style>
