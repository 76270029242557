<template>
    <div class="profile narrow-form">
        <h1 class="my-5">Edit User</h1>

        <spinner-until :condition="userLoggedIn">

            <data-form :field-groups="fieldGroups"
                       :field-model="userModel"
                       :load-model-instance="loadModelInstance"
                       :on-submit-handler="onSubmit"
                       submit-success-route="/users"
                       :title="`Edit User: ${user.email} ${userFullName}`"/>

        </spinner-until>
    </div>
</template>

<script>
import User from '@/models/User'
import _ from "lodash"
import SpinnerUntil from "../components/SpinnerUntil";
import {store} from "@/store";

export default {
    name: 'UserEdit',
    components: { SpinnerUntil },
    props: {
        userId: String
    },
    data () {
        return {
            user: {},
            userModel: User,
            fieldGroups: [
                ['firstName', 'lastName', 'admin'],
            ],
        }
    },
    computed: {
        userLoggedIn () {
            return this.$api.userLoggedIn()
        },
        userFullName() {
            if (this.user.firstName || this.user.lastName) {
                return `(${this.user.firstName} ${this.user.lastName})`
            } else {
                return ''
            }
        },
    },
    methods: {
        async loadModelInstance () {
            let item = await this.$api.getItem(this, 'users', this.userId)
            this.user = item
            return item
        },
        async onSubmit (modelInstance) {
            const saveModel = _.transform(modelInstance, (result, value, key) => {
                if (value !== undefined) result[key] = value
            })
            await this.$api.updateItem(this, 'users', this.userId, saveModel)
            if (store.state.currentUser.uid === this.userId) {
                await this.$api.refreshDbUserState()
            }
            this.$toasted.success(this.$i18n.gt('USER_EDIT_SUCCESS'))
        }
    }
}
</script>

<style lang="scss">

</style>
