import Vue from 'vue'
import Router from 'vue-router'
import Api from '@/providers/Api'
import _ from "lodash";
import {ScreenOrientation} from "@capacitor/screen-orientation";
import {Capacitor} from "@capacitor/core";
import { App, URLOpenListenerEvent } from '@capacitor/app';

Vue.use(Router)

// Prevent NavigationDuplicated error
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err
    })
}

// Listen for app deeplinks
App.addListener('appUrlOpen', function (urlEvent) {
    const slug = urlEvent.url.split('.com').pop();
    if (slug) {
        router.push({
            path: slug,
        });
    }
});

const router = new Router(
    {
        mode: 'history',
        routes: [
            // ----------- Public Pages
            {
                path: '/',
                component: require('@/pages/Home').default,
            },
            {
                path: '/signup',
                component: require('@/pages/UserSignup').default,
                meta: {
                    security: () => Capacitor.isNativePlatform()
                }
            },
            {
                path: '/tutorial',
                component: require('@/pages/Tutorial').default,
            },
            {
                path: '/about',
                component: require('@/pages/About').default,
            },
            {
                path: '/contact',
                component: require('@/pages/Contact').default,
            },
            {
                path: '/terms',
                component: require('@/pages/Terms').default,
            },
            {
                path: '/privacy',
                component: require('@/pages/Privacy').default,
            },
            {
                path: '/login',
                component: require('@/pages/UserLogin').default
            },
            {
                path: '/resetPassword',
                component: require('@/pages/UserResetPassword.vue').default,
            },

            // ----------- Authenticated User Pages
            {
                path: '/locations',
                component: require('@/pages/Locations').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/create',
                component: require('@/pages/LocationsCreate').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/view',
                component: require('@/pages/LocationsView').default,
                props: (route) => ({
                    locId: route.params.locId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/edit',
                component: require('@/pages/LocationsEdit').default,
                props: (route) => ({
                    locId: route.params.locId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/cards/create',
                component: require ('@/pages/CardsCreate').default,
                props: (route) => ({
                    initialLocId: route.params.locId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/cards/:cardId/edit',
                component: require ('@/pages/CardsEdit').default,
                props: (route) => ({
                    locId: route.params.locId,
                    cardId: route.params.cardId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/cards/:cardId/view',
                component: require ('@/pages/CardsView').default,
                props: (route) => ({
                    locId: route.params.locId,
                    cardId: route.params.cardId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/scouts/create',
                component: require ('@/pages/ScoutsEdit').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/cards/:cardId/scouts/create',
                component: require ('@/pages/ScoutsEdit').default,
                props: (route) => ({
                    locId: route.params.locId,
                    cardId: route.params.cardId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/cards/:cardId/scouts/:scoutId/edit',
                component: require ('@/pages/ScoutsEdit').default,
                props: (route) => ({
                    locId: route.params.locId,
                    cardId: route.params.cardId,
                    scoutId: route.params.scoutId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/cards/:cardId/scouts/:scoutId/view',
                component: require ('@/pages/ScoutsView.vue').default,
                props: (route) => ({
                    locId: route.params.locId,
                    cardId: route.params.cardId,
                    scoutId: route.params.scoutId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/applications/create',
                component: require('@/pages/ApplicationsEdit').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/cards/:cardId/applications/:applicationId/view',
                component: require ('@/pages/ApplicationsView.vue').default,
                props: (route) => ({
                    locId: route.params.locId,
                    cardId: route.params.cardId,
                    applicationId: route.params.applicationId,
                    meta: {
                        security: () => Api.userLoggedIn()
                    }
                }),
            },
            {
                path: '/locations/:locId/cards/:cardId/applications/create',
                component: require ('@/pages/ApplicationsEdit').default,
                props: (route) => ({
                    locId: route.params.locId,
                    cardId: route.params.cardId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/cards/:cardId/applications/:applicationId/edit',
                component: require ('@/pages/ApplicationsEdit').default,
                props: (route) => ({
                    locId: route.params.locId,
                    cardId: route.params.cardId,
                    applicationId: route.params.applicationId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/locations/:locId/cards/:cardId/chart',
                component: require ('@/pages/CardsChart').default,
                props: (route) => ({
                    locId: route.params.locId,
                    cardId: route.params.cardId,
                }),
                meta: {
                    allowRotate: true,
                    security: () => Api.userLoggedIn()
                }
            },

            // ----------- Authenticated User/Admin pages

            {
                path: '/beneficials',
                component: require ('@/pages/Beneficials').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/beneficials/view/:beneficialId',
                component: require ('@/pages/Beneficials').default,
                props: (route) => ({
                    beneficialId: route.params.beneficialId
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/beneficials/:beneficialId/edit',
                component: require ('@/pages/BeneficialsEdit').default,
                props: (route) => ({
                    beneficialId: route.params.beneficialId,
                }),
                meta: {
                    security: () => Api.isAdmin()
                }
            },
            {
                path: '/beneficials/create',
                component: require ('@/pages/BeneficialsCreate').default,
                meta: {
                    security: () => Api.isAdmin()
                }
            },
            {
                path: '/pests',
                component: require ('@/pages/Pests').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/pests/view/:pestId',
                component: require ('@/pages/Pests').default,
                props: (route) => ({
                    pestId: route.params.pestId,
                    meta: {
                        security: () => Api.userLoggedIn()
                    }
                }),
            },
            {
                path: '/pests/:pestTypeId/:pestId/edit',
                component: require ('@/pages/PestsEdit').default,
                props: (route) => ({
                    pestTypeId: route.params.pestTypeId,
                    pestId: route.params.pestId,
                }),
                meta: {
                    security: () => Api.isAdmin()
                }
            },
            {
                path: '/pests/create',
                component: require ('@/pages/PestsCreate').default,
                meta: {
                    security: () => Api.isAdmin()
                }
            },

            {
                path: '/symptoms',
                component: require ('@/pages/Symptoms').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/symptoms/view/:symptomId',
                component: require ('@/pages/Symptoms').default,
                props: (route) => ({
                    symptomId: route.params.symptomId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/symptoms/:symptomId/edit',
                component: require ('@/pages/SymptomsEdit').default,
                props: (route) => ({
                    symptomId: route.params.symptomId,
                }),
                meta: {
                    security: () => Api.isAdmin()
                }
            },
            {
                path: '/symptoms/create',
                component: require ('@/pages/SymptomsCreate').default,
                meta: {
                    security: () => Api.isAdmin()
                }
            },

            {
                path: '/diseases',
                component: require ('@/pages/Diseases').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/diseases/view/:diseaseId',
                component: require ('@/pages/Diseases').default,
                props: (route) => ({
                    diseaseId: route.params.diseaseId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/diseases/:diseaseId/edit',
                component: require ('@/pages/DiseasesEdit').default,
                props: (route) => ({
                    diseaseId: route.params.diseaseId,
                }),
                meta: {
                    security: () => Api.isAdmin()
                }
            },
            {
                path: '/diseases/create',
                component: require ('@/pages/DiseasesCreate').default,
                meta: {
                    security: () => Api.isAdmin()
                }
            },

            {
                path: '/tasks',
                component: require ('@/pages/Tasks').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/tasks/:taskId/edit',
                component: require ('@/pages/TasksEdit').default,
                props: (route) => ({
                    taskId: route.params.taskId,
                }),
                meta: {
                    security: () => Api.isAdmin()
                }
            },
            {
                path: '/tasks/create',
                component: require ('@/pages/TasksCreate').default,
                meta: {
                    security: () => Api.isAdmin()
                }
            },

            {
                path: '/pesticides',
                component: require ('@/pages/Pesticides').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/pesticides/view/:pesticideId',
                component: require ('@/pages/Pesticides').default,
                props: (route) => ({
                    pesticideId: route.params.pesticideId
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/pesticides/:pesticideId/edit',
                component: require ('@/pages/PesticidesEdit').default,
                props: (route) => ({
                    pesticideId: route.params.pesticideId,
                }),
                meta: {
                    security: () => Api.isAdmin()
                }
            },
            {
                path: '/pesticides/create',
                component: require ('@/pages/PesticidesCreate').default,
                meta: {
                    security: () => Api.isAdmin()
                }
            },

            {
                path: '/interactions',
                component: require ('@/pages/Interactions').default,
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },
            {
                path: '/interactions/beneficials/:beneficialId',
                component: require ('@/pages/Interactions').default,
                props: (route) => ({
                    beneficialId: route.params.beneficialId,
                }),
                meta: {
                    security: () => Api.userLoggedIn()
                }
            },

            // Admin Pages
            {
                path: '/users',
                component: require ('@/pages/UserManager').default,
                meta: {
                    security: () => Api.isAdmin()
                }
            },
            {
                path: '/user/:userId/edit',
                component: require('@/pages/UserEdit').default,
                props: (route) => ({
                    userId: route.params.userId,
                }),
                meta: {
                  security: () => Api.isAdmin()
                }
            },
            {
                path: '/profile',
                component: require('@/pages/UserProfile').default,
                meta: {
                    security: () => Api.isVerified()
                }
            },
            {
                path: '/database',
                component: require ('@/pages/DatabaseManager').default,
                meta: {
                    security: () => Api.isAdmin()
                }
            },

            // default: page not found
            {
                path: '*',
                component: require('@/pages/NotFound').default,
            },
        ],
    }
)


// navigation guard - check authorization
router.beforeEach(async (to, from, next) => {
    // check for security rules
    let allow = true
    if (_.isFunction(to.meta.security)) {
        // wait for api to initialize
        await Api.isInitialized
        // call security function defined by route
        allow = to.meta.security()
    }

    if (!allow) {
        router.app.$toasted.error('Unauthorized', { duration: 5000 })
        next(from)
    } else {
        next()
    }
    if (Capacitor.isNativePlatform()) {
        if (from.meta && from.meta.allowRotate) {
            await ScreenOrientation.lock({orientation: 'portrait'})
        }
        if (to.meta && to.meta.allowRotate) {
            await ScreenOrientation.unlock()
        }
    }
})


export default router


