<template>
    <div class="pests card-list">
        <b-row class="row-heading">
            <b-col class="d-flex justify-content-between">
                <h1>{{ $i18n.gt('PESTS_LABEL') }}</h1>
                <span class="btn-add--top">
                    <b-btn v-if="isAdmin"
                           @click="navigate('/pests/create')" variant="success">
                        <img src="../../assets/icons/wasp.svg">
                        {{ $i18n.gt('PESTS_CREATE_HEADING') }}
                        <b-icon-plus-circle-fill/>
                    </b-btn>
                </span>
            </b-col>
        </b-row>

        <b-row v-for="pestType in pestTypes" :key="pestType.pestTypeId">
            <b-col cols="12">
                <h3>{{$i18n.getPestTypeName(pestType.pestTypeId)}}</h3>
            </b-col>
            <b-col cols="12" md="6" v-for="item in pestType.pests" :key="item.id">
                <b-card no-body class="mb-3">
                    <b-card-header>
                        <b-row>
                            <b-col class="card-list__name">
                                {{ $i18n.getPestName(item.id) }}
                            </b-col>
                            <b-col class="text-right">
                                <b-button v-b-toggle="`collapse-${item.id}`" size="sm" variant="light">
                                    <b-icon-caret-down/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-header>
                    <b-collapse :id="`collapse-${item.id}`">
                        <b-card-body>
                            <div class="pest__scientific-name scientific-name"><em v-if="item.scientificName">{{ item.scientificName }}</em></div>
                            <div cols="12" md="12" class="pest__image px-0">
                                <image-viewer :source="item.mainImage"/>
                            </div>
                            <hr></hr>
                            <text-section-view v-for="(section, i) in item.textSections" :key="i"
                                               :section="section" class="my-3"/>
                            <div v-if="item.beneficials" class="pest__beneficials">
                                <strong>{{ $i18n.gt('BENEFICIALS_LABEL') }}:</strong>
                                <ul class="ml-4">
                                    <li v-for="ben in getBeneficials(item)" :key="`${item.id}-${ben.ref}`">
                                        <router-link :to="`/${ben.ref.split('/')[0]}/view/${ben.ref.split('/')[1]}`">
                                            {{ ben.name }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="item.symptoms" class="pest__symptoms">
                                <strong>{{ $i18n.gt('SYMPTOMS_LABEL') }}:</strong>
                                <ul class="ml-4">
                                    <li v-for="symptom in getSymptoms(item)" :key="`${item.id}-${symptom.ref}`">
                                        <router-link :to="`/${symptom.ref.split('/')[0]}/view/${symptom.ref.split('/')[1]}`">
                                            {{ symptom.name }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </b-card-body>
                        <b-card-footer class="text-right"
                                       v-if="isAdmin || item.factSheet">
                            <template v-if="item.factSheetUrl">
                                <b-btn size="sm" variant="secondary" class="btn-icon mr-3"
                                       v-b-popover.top.hover="$i18n.gt('FACT_SHEET_TOOLTIP')"
                                       :href="item.factSheetUrl" target="_blank">
                                    <b-icon-link45deg/>
                                </b-btn>
                            </template>
                            <template v-if="isAdmin">
                                <b-btn size="sm" variant="danger" class="btn-icon mr-3"
                                       v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')"
                                       @click="deletePest(item)">
                                    <b-icon-x-square-fill/>
                                </b-btn>
                                <b-btn size="sm" variant="success" class="btn-icon"
                                       v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')"
                                       @click="navigate(`/pests/${pestType.pestTypeId}/${item.id}/edit`)">
                                    <b-icon-pencil-fill/>
                                </b-btn>
                            </template>
                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col class="d-flex justify-content-end">
                <b-btn v-if="isAdmin"
                       @click="navigate('/pests/create')" variant="success">
                    <img src="../../assets/icons/wasp.svg">
                    {{ $i18n.gt('PESTS_CREATE_HEADING') }}
                    <b-icon-plus-circle-fill/>
                </b-btn>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import * as _ from 'lodash'
import Pest from "../models/Pest";
import ImageViewer from "@/components/ImageViewer.vue";

export default {
    name: 'Pests',
    components: {ImageViewer},
    props: {
        pestId: String,
    },
    data() {
        return {
            pestTypes: [],
        }
    },
    computed: {
        isAdmin() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.admin
            } else return !!this.$store.state.currentDbUser.admin
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        getBeneficials(item) {
            const bens = []
            if (item.beneficials) {
                const benRefs = item.beneficials.split(',')
                benRefs.forEach((ben) => {
                    bens.push({name: this.$i18n.getBeneficialName(ben), ref: ben})
                })
            }
            return _.sortBy(bens, (item) => {
                return item.name
            })
        },
        getSymptoms(item) {
            const symps = []
            if (item.symptoms) {
                const sympRefs = item.symptoms.split(',')
                sympRefs.forEach((symp) => {
                    symps.push({name: this.$i18n.getSymptomName(symp), ref: symp})
                })
            }
            return _.sortBy(symps, (item) => ['name'])
        },
        async getItems() {
            this.pestTypes = this.$store.state.pestsNested
        },
        async deletePest(item) {
            if (confirm('Permanently delete this Pest?')) {
                // NB: pestTypeId is 'pest_types/xxx'
                await this.$api.deleteItem(this, `${item.pestTypeId}/pests/`, item)
                this.$toasted.success(this.$i18n.gt('PEST_DELETED_SUCCESS'))

                // reload pest list in store to include new name
                await Pest.loadPestsNested()
                await Pest.loadPestsUnnested()

                this.getItems()
            }
        },
    },
    async mounted() {
        await this.getItems()
        // if a pest id is passed, un-collapse it by default
        if (this.pestId) {
            await this.$root.$emit('bv::toggle::collapse', `collapse-${this.pestId}`)
            document.getElementById(`collapse-${this.pestId}`).parentElement.scrollIntoView()
            this.$router.replace('/pests')
        }
    },
}
</script>

<style lang="scss">
.pests {
    img {
        max-width: 300px;
    }
}
</style>
