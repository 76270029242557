<template>
    <div class="database-manager my-5">
        <h1>Database Manager</h1>
        <b-card class="row card-shadow">
            <b-col>
                Copy from
                <b-form-select :options="copyFromEnvironments" v-model="from"/>
            </b-col>
            <b-col>
                Copy to
                <b-form-select :options="copyToEnvironments" v-model="to"/>
            </b-col>
            <b-col>
                Collection Path
                <b-icon-info-circle-fill style="margin-left:5px; color: gray" v-b-popover.righttop.hover="$i18n.gt('DATABASE_TOOLTIP')"/>
                <b-form-input v-model="collection"/>
            </b-col>
        </b-card>
        <b-row>
            <b-button variant="success" @click="onsubmit()">Copy</b-button>
        </b-row>
    </div>
</template>

<script>

export default {
    name: 'DatabaseManager',
    data() {
        return {
            from: null,
            to: null,
            collection: '',
        }
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        },
        environments() {
            return [
                { value: null, text: 'Please select an environment' },
                { value: '/greenhouse_dev/data/', text: 'Development' },
                { value: '/greenhouse_staging/data/', text: 'Staging' },
                { value: '/greenhouse_prod/data/', text: 'Production' }
            ]
        },
        copyFromEnvironments() {
            const copy = _.cloneDeep(this.environments)
            _.remove(copy, (env) => {
                return env.value && env.value === this.to
            })
            return copy
        },
        copyToEnvironments() {
            const copy = _.cloneDeep(this.environments)
            _.remove(copy, (env) => {
                return env.value && env.value === this.from
            })
            return copy
        }
    },
    methods: {
        onsubmit() {
            if (this.collection && this.from && this.to && this.from !== this.to) {
                const collectionArray = this.collection.split(',')
                this.$api.copyCollection(collectionArray, this.from, this.to)
            } else this.$toasted.error('Invalid Input');

        }
    }
}
</script>

<style lang="scss">
@import "/sass/variables_mixins";

.database-manager {
    h1 {
        margin-top: 15px;
    }

    .card-body {
        display: inherit;
    }
    .btn-success {
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
    }
}
</style>