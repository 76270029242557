<template>
    <div class="privacy mt-3">
        <h1>{{$i18n.gt('PRIVACY')}}</h1>
        <h2>{{$i18n.gt('PRIVACY_COMMITMENT')}}</h2>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_1')}}</p>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_2')}}</p>
        <p>
            {{$i18n.gt('PRIVACY_PARAGRAPH_3_1')}}
            <a href="https://scout.pocketipm.com/" target="_blank">
                (https://scout.pocketipm.com/)
            </a>
            {{$i18n.gt('PRIVACY_PARAGRAPH_3_2')}}
        </p>

        <h3>{{$i18n.gt('PRIVACY_HEADING_1')}}</h3>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_4')}}</p>

        <h3>{{$i18n.gt('PRIVACY_HEADING_2')}}</h3>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_5')}}</p>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_6')}}</p>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_7')}}</p>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_8')}}</p>

        <h3>{{$i18n.gt('PRIVACY_HEADING_3')}}</h3>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_9')}}</p>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_10')}}</p>

        <h3>{{$i18n.gt('PRIVACY_HEADING_4')}}</h3>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_11')}}</p>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_12')}}</p>

        <h3>{{$i18n.gt('PRIVACY_HEADING_5')}}</h3>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_13')}}</p>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_14')}}</p>

        <h3>{{$i18n.gt('PRIVACY_HEADING_6')}}</h3>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_15')}}</p>

        <h3>{{$i18n.gt('PRIVACY_HEADING_7')}}</h3>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_16')}}</p>

        <h3>{{$i18n.gt('PRIVACY_HEADING_8')}}</h3>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_17')}}</p>

        <h3>{{$i18n.gt('PRIVACY_HEADING_9')}}</h3>
        <p>{{$i18n.gt('PRIVACY_PARAGRAPH_18')}}</p>
        <span>{{$i18n.gt('PRIVACY_OL_HEADING')}}</span>
        <ol>
            <li>{{$i18n.gt('PRIVACY_LI_1')}}</li>
            <li>{{$i18n.gt('PRIVACY_LI_2')}}</li>
            <li>{{$i18n.gt('PRIVACY_LI_3')}}</li>
            <li>{{$i18n.gt('PRIVACY_LI_4')}}</li>
            <li>{{$i18n.gt('PRIVACY_LI_5')}}</li>
            <li>{{$i18n.gt('PRIVACY_LI_6')}}</li>
            <li>{{$i18n.gt('PRIVACY_LI_7')}}</li>
        </ol>

        <h3>{{$i18n.gt('PRIVACY_HEADING_10')}}</h3>
        <p>
            {{$i18n.gt('PRIVACY_PARAGRAPH_19')}}
            <a href="https://privacy.cornell.edu/webform/contact-us#no-back" target="_blank">
                https://privacy.cornell.edu/webform/contact-us#no-back
            </a>
        </p>
    </div>
</template>

<script>

export default {
    name: 'Privacy',
    data() {
        return {}
    },
    computed: {
    },
}
</script>

<style lang="scss">
.privacy {
    ol {
      margin-left: 2rem;
    }
}
</style>