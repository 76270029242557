<template>
    <b-overlay :show="loading" class="user-login card my-5 p-4">

        <data-form :field-groups="fieldGroups"
                   :field-model="userModel"
                   :save-button-label="$i18n.gt('LOGIN')"
                   bottom-submit-button
                   :on-submit-handler="login"
                   :title="$i18n.gt('LOGIN')"/>

        <div class="mx-auto">
            <router-link style="display: inline" to="/resetPassword"><strong>{{ $i18n.gt('FORGOT_PASSWORD') }}</strong></router-link>
        </div>

        <div class="mt-3 mb-3 mx-auto" v-if="isNative()">{{ $i18n.gt('HOME_CREATE_TIP') }}<br>
            <router-link to="/signup" class="btn btn-secondary mt-1">{{ $i18n.gt('HOME_CREATE_LABEL') }}</router-link>
        </div>

    </b-overlay>
</template>

<script>

import UserFields from "@/models/User";
import {Capacitor} from "@capacitor/core";

export default {
    name: 'UserLogin',
    data () {
        return {
            userModel: UserFields,
            fieldGroups: [
                ['email', 'password'],
            ],
            loading: false,
        }
    },
    computed: {
        uid () {
            return this.$store.state.currentUser.uid
        },
        isNative () {
            return Capacitor.isNativePlatform
        }
    },
    methods: {
        async login (modelInstance) {
            this.loading = true
            if (this.uid) {
                this.$toasted.success('Already Logged in')
            } else {
                await this.$api.loginUser(this, modelInstance.email, modelInstance.password)
            }
            this.loading = false
        }
    }
}
</script>

<style lang="scss">
.user-login {
    max-width:460px!important;
    margin: 0 auto;
    small {
        display: none;
    }
}
</style>