<template>
    <div class="about">
        <h1>About</h1>
    </div>
</template>

<script>

export default {
    name: 'About',
    data() {
        return {}
    },
    computed: {
    },
}
</script>

<style lang="scss">
.about {
}
</style>