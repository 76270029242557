<template>
    <div class="diseases-edit narrow-form">
        <h1 class="page-heading">Edit Disease</h1>

        <b-row>
            <b-col cols="12">

                <data-form :field-model="diseaseModel"
                           :load-model-instance="loadModelInstance"
                           :on-submit-handler="updateDisease"
                           :field-groups="fieldGroups"
                           cancel-route="/diseases"
                           bottom-submit-button bottom-cancel-button/>

            </b-col>
        </b-row>

    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Disease from "../models/Disease"

export default {
    name: 'DiseasesEdit',
    props: {
        diseaseId: String,
    },
    data() {
        return {
            modelInstance: {},
            diseaseModel: Disease,
            fieldGroups: [
                ['name', 'name_es', 'symptoms', 'factSheetUrl', 'textSections'],
            ]
        }
    },
    computed: {},
    methods: {
        async loadModelInstance() {
            const item = await this.$api.getItem(this, `diseases`, this.diseaseId)
            this.modelInstance = item
            return item
        },
        async updateDisease(modelInstance, vform) {
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadModelTextSectionImages(modelInstance)

            await this.$api.updateItem(this, `diseases`, this.diseaseId, modelInstance)

            // reload disease list in store (in case name changed)
            await Disease.loadDiseases()

            this.$router.push(`/diseases/view/${this.diseaseId}`)
            this.$toasted.success(this.$i18n.gt('DISEASE_UPDATED_SUCCESS'))
        }

    }
}
</script>

<style lang="scss">
.diseases-edit {
  label.custom-file-label {
    z-index: 0;
  }

  img.location-image {
    max-height: 200px;
  }
}
</style>
