<template>
    <spinner-until :condition="fbUserLoaded && dataLoaded" style="height: 100%">
        <div class="notch-spacing" v-if="isNative"></div>
        <div class="page-container">
            <header class="header">
                <nav-bar/>
            </header>

            <!-- show sidebar menu when logged in and not on home page -->
            <side-menu v-if="loggedIn && $route.path !== '/' && $mq === 'xl'"/>

            <div class="content content-outer">
                <div class="container main-content">
                    <router-view/>
                </div>
            </div>

        </div>
        <main-footer/>
    </spinner-until>
</template>

<script>
import Vue from 'vue'
import {store} from './store'
import MainFooter from "@/components/MainFooter";
import {Capacitor} from "@capacitor/core";
import {ScreenOrientation} from "@capacitor/screen-orientation";

export default Vue.extend({
    name: 'app',
    components: {
        MainFooter,
    },
    computed: {
        // don't show anything until fb auth has been initialized!
        fbUserLoaded() {
            // NB: uid is undefined if fb auth hasn't kicked in; uid is null if no user logged in
            return  this.$store.state.currentUser && this.$store.state.currentUser.uid !== undefined &&
                this.$store.state.currentDbUser?.email !== undefined
        },
        loggedIn() {
            return this.$store.state.currentUser && this.$store.state.currentUser.uid;
        },
        dataLoaded() {
            return !!this.$store.state.translationsLoaded &&
                !!this.$store.state.lookupsLoaded
        },
        isNative() {
            return Capacitor.isNativePlatform()
        },
    },
    beforeCreate() {
        this.$api.initApp()
    },
    created() {
        this.$i18n.loadTranslations('en', store)
        this.$i18n.loadLookups()
        if (Capacitor.isNativePlatform()) {
            ScreenOrientation.lock({orientation: 'portrait'})
        }
    }
})

</script>

<style lang="scss">
@import '../sass/base';

body > .spinner-until {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

header {
  background-color: #fff;
  @media (min-width: 1024px) {
    height: 68px;
  }
}

.content-outer {
  overflow: hidden;
}

.container-outer {
  max-width: 100%;
  overflow: hidden;

  > div:not(.home) {
    margin: 0 auto;
    padding-bottom: 20px;
  }
}
.notch-spacing {
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    height: env(safe-area-inset-top);
    z-index: 1036;
}

</style>
