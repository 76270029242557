import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        currentUser: {}, // Firebase auth user object returned by sign in functions
        currentDbUser: {}, // user record from DB
        maskUser: {},
        currentLang: localStorage.getItem('language') ?? 'en', // check local language for persisted lang selection
        translationsLoaded: false,

        // simple lookup arrays - just arrays of documents
        beneficials: [],
        diseases: [],
        symptoms: [],
        tasks: [],
        pesticides: [],
        pestTypes: [],

        // more complex lookup arrays
        pestsNested: [],   // array of {pestTypeId: xxx, pests: [...]} (pests grouped by pest type)
        pestsUnnested: [], // array of pests (with pestType added as attribute on each)

        lookupsLoaded: false,
        // unsynced: null,
    },
    actions: {
        // submit (context, payload) {
        //     context.commit(payload.mutation, payload.payload)
        //     localStorage.setItem('vuex', JSON.stringify(context.state))
        // },
        // initStore (context) {
        //     if (localStorage.getItem('vuex')) {
        //         const savedState = JSON.parse(localStorage.getItem('vuex'))
        //         _.each(savedState, (value, key) => {
        //             Vue.set(context.state, key, value)
        //         })
        //     }
        // }
    },
    mutations: {
        setPestTypes(state, vals) {
            state.pestTypes = vals
        },
        setPestsNested(state, vals) {
            state.pestNested = vals
        },

        // initStore (state) {
        //     if (localStorage.getItem('vuex')) {
        //         const savedState = JSON.parse(localStorage.getItem('vuex'))
        //         _.each(savedState, (value, key) => {
        //             Vue.set(state, key, value)
        //         })
        //     }
        // },
        // currentUser (state, currentUser) {
        //     Vue.set(state, 'currentUser', currentUser)
        // },
        // unsynced (state, unsynced) {
        //     Vue.set(state, 'unsynced', unsynced)
        // }
    },
})
