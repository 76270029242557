<template>
    <div class="pesticides-edit narrow-form">
        <h1 class="page-heading">Edit Pesticide</h1>

        <b-row>
            <b-col cols="12">

                <data-form :field-model="pesticideModel"
                           :load-model-instance="loadModelInstance"
                           :on-submit-handler="updatePesticide"
                           :field-groups="fieldGroups"
                           cancel-route="/pesticides"
                           bottom-submit-button bottom-cancel-button/>

            </b-col>
        </b-row>

    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Pesticide from "../models/Pesticide"

export default {
    name: 'PesticidesEdit',
    props: {
        pesticideId: String,
    },
    data() {
        return {
            modelInstance: {},
            pesticideModel: Pesticide,
            fieldGroups: [
                ['name', 'name_es', 'textSections'],
            ]
        }
    },
    computed: {},
    methods: {
        async loadModelInstance() {
            const item = await this.$api.getItem(this, `pesticides`, this.pesticideId)
            this.modelInstance = item
            return item
        },
        async updatePesticide(modelInstance, vform) {
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadModelTextSectionImages(modelInstance)

            await this.$api.updateItem(this, `pesticides`, this.pesticideId, modelInstance)

            // reload pesticide list in store (in case name changed)
            await Pesticide.loadPesticides()

            this.$router.push(`/pesticides`)
            this.$toasted.success(this.$i18n.gt('PESTICIDE_UPDATED_SUCCESS'))
        }

    }
}
</script>

<style lang="scss">
.pesticides-edit {
  label.custom-file-label {
    z-index: 0;
  }

  img.location-image {
    max-height: 200px;
  }
}
</style>
