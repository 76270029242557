/**
 * Model class for Beneficials
 */

import Api from '@/providers/Api'
import * as _ from 'lodash'
import {store} from '@/store'
import i18n from '@/providers/i18n'
import {required} from "vuelidate/lib/validators"
import Pest from '@/models/Pest'

export default {

    // load master list of beneficials into the store
    async loadBeneficials() {
        store.state.beneficials = await Api.getAllItems(null, 'beneficials')
    },

    // build checkbox options based on master list
    buildBeneficialOptions() {
        const beneficials = store.state.beneficials
        let options = _.map(beneficials, (beneficial) => {
            return {
                value: `beneficials/${beneficial.id}`, text: i18n.getBeneficialName(beneficial.id)
            }
        })
        return _.sortBy(options, ['text'])
    },

    fields: {
        name: {
            translationKey: 'BENEFICIAL_NAME_FIELD',
            type: 'text',
            validations: { required }
        },
        name_es: {
            translationKey: 'BENEFICIAL_NAME_ES_FIELD',
            type: 'text',
            // validations: { required }
        },
        scientificName: {
            translationKey: 'BENEFICIAL_SCIENTIFIC_NAME_FIELD',
            type: 'text',
            // validations: { required }
        },
        mainImage: {
            translationKey: 'BENEFICIAL_MAIN_IMAGE_FIELD',
            type: 'image',
        },
        factSheetUrl: {
            translationKey: 'BENEFICIAL_FACT_SHEET_URL_FIELD',
            type: 'url',
        },
        pesticides: {
            // TODO
        },
        pests: {
            translationKey: 'BENEFICIAL_PESTS_FIELD',
            type: 'checksgroups',
            options: [],
            loadOptions: async function(model) {
                this.options = Pest.buildPestOptionsNested(this)
            },
            loadOptionsUnnested: async function(model) {
                this.options = Pest.buildPestOptionsUnnested(this)
            },
        },
        textSections: {
            translationKey: 'BENEFICIAL_TEXT_SECTIONS_FIELD',
            type: 'textsections',
        },
    }
}
