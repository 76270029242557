<template>
    <div class="user-profile narrow-form mt-5">

        <spinner-until :condition="userLoggedIn">
            <!-- require password if no recent login -->
            <b-overlay v-if="!recentAuth" :show="authenticating">
                <form>
                    <h3>{{$i18n.gt('PROFILE_PASSWORD_ENTRY')}}</h3>
                    <b-form-group :label="$i18n.gt('PROFILE_EMAIL')">
                        <b-form-input type="email" :disabled="true" v-model="$store.state.currentUser.email"/>
                    </b-form-group>
                    <b-form-group :label="$i18n.gt('PROFILE_PASSWORD')">
                        <b-form-input type="password" v-model="password"/>
                    </b-form-group>
                    <b-btn variant="success" @click="reauth">{{$i18n.gt('PROFILE_SUBMIT')}}</b-btn>
                </form>
            </b-overlay>

            <template v-if="recentAuth">
                <data-form :field-groups="fieldGroups"
                           :field-model="userModel"
                           :load-model-instance="loadModelInstance"
                           :on-submit-handler="onSubmit"
                           :cancel-button-label="$i18n.gt('LABEL_CANCEL')"
                           :on-cancel-handler="onCancel"
                           title="My Profile"/>
                <div class="mx-auto">
                    <router-link style="display: inline" to="/resetPassword"><strong>{{ $i18n.gt('PROFILE_PASSWORD_RESET') }}</strong></router-link>
                </div>
            </template>

        </spinner-until>
    </div>
</template>

<script>
import User from '@/models/User'
import _ from "lodash"
import SpinnerUntil from "../components/SpinnerUntil";
import {store} from '@/store'

export default {
    name: 'UserProfile',
    components: { SpinnerUntil },
    props: {
        userId: String
    },
    data () {
        return {
            user: {},
            userModel: User,
            fieldGroups: [
                ['firstName', 'lastName', 'zip', 'email'],
            ],
            password: '',
            recentAuth: false,
            authenticating: false,
        }
    },
    computed: {
        uid () {
            return this.$store.state.currentUser?.uid
        },
        userLoggedIn () {
            return this.$api.userLoggedIn()
        },
        isAdmin() {
            return this.$api.isAdmin()
        },
    },
    methods: {
        async reauth () {
            this.authenticating = true
            await this.$api.loginUser(this, this.$store.state.currentUser.email, this.password, false)
            this.password = ''
            this.recentAuth = true
            this.authenticating = false
            setTimeout(() => {
                this.recentAuth = false
            }, 300000)
        },
        async loadModelInstance () {
            let item = await this.$api.getItem(this, 'users', this.uid)
            // create db user if it does not exist
            if (!item && this.uid === this.userId) {
                item = {
                    email: this.$store.state.currentUser.email,
                    emailReadonly: this.$store.state.currentUser.email
                }
                await this.$api.setItem(this, `users`, this.userId, item)
            }
            this.user = item
            return item
        },
        async onSubmit (modelInstance) {
            const saveModel = _.transform(modelInstance, (result, value, key) => {
                if (value !== undefined) {
                    result[key] = value
                }
            })
            if (modelInstance.email !== this.$store.state.currentUser.email) {
                if (!await this.$api.updateEmail(this, modelInstance.email)) {
                    return
                }
            }
            await this.$api.updateItem(this, 'users', this.uid, saveModel)
            if (store.state.currentUser.uid === this.uid) {
                await this.$api.refreshDbUserState()
            }
            this.$toasted.success(this.$i18n.gt('PROFILE_SAVED_SUCCESS'))
            await this.$router.push('/')
        },
        onCancel() {
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="scss">

</style>
