/**
 * Model class for Locations
 */

import {email, minLength, maxLength, numeric, required, sameAs} from 'vuelidate/lib/validators'
import i18n from '../providers/i18n'
import Api from '@/providers/Api'
import * as _ from 'lodash'
import {store} from '@/store'

export default {
    // helper method to load locations
    async loadLocationOptions(modelField) {
        let userId = ''
        if (store.state.maskUser.email) {
            userId = store.state.maskUser.id
        } else userId = store.state.currentUser.uid
        if (userId) {
            const locations = await Api.getAllItems(null, `users/${userId}/locations`)
            modelField.options = _.map(locations, (location) => {
                return {
                    value: `users/${userId}/locations/${location.id}`, text: location.name
                }
            })
        }
    },
    async getCardOptionsForLocation(locationPath) {
        const cards = await Api.getAllItems(null, `${locationPath}/cards`)
        const options = _.map(cards, (card) => {
            return {value: `${locationPath}/cards/${card.id}`, text: card.name}
        })
        return options
    },
    fields: {
        name: {
            translationKey: 'LOCATION_NAME_FIELD',
            type: 'text',
            validations: { required }
        },
        image: {
            translationKey: 'LOCATION_IMAGE_FIELD',
            type: 'image',
        },
        notes: {
            translationKey: 'LOCATION_NOTES_FIELD',
            type: 'textarea',
        }
    }
}
