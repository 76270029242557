<template>
    <div class="TrixCMS">
        <template v-if="isAdmin">
            <b-button class="my-3 cms-button" size="sm" variant="primary"
                      @click="toggleEditing">
                <b-icon-pencil-fill v-if="!isEditing"/>
                <b-icon-archive-fill v-if="isEditing"/>
                <span class="cms-button-label">
                {{ isEditing ? 'Save' : 'Edit' }} [{{ id }}]
                </span>
            </b-button>
            <br>
            <div :style="{display: isEditing ? 'block' : 'none'}">
                <input :id="id" v-model="trixText" name="content" type="hidden">
                <trix-editor ref="trix" :input="id" class="trix-content"/>
            </div>
        </template>

        <template v-if="!isEditing">
            <div class="trix-content" v-html="content.html"/>
        </template>
    </div>
</template>

<script>

import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage"

export default {
    name: 'TrixCMS',
    props: {
        id: String,
    },
    data() {
        return {
            trixText: '',
            content: {},
            isEditing: false,
            isAdmin: false,
        }
    },
    beforeMount() {
        this.isAdmin = this.$api.isAdmin()
    },
    async mounted() {
        this.content = (await this.$api.getItem(this, '/greenhouse_cms', this.id)) ?? {}

        if (this.isAdmin) {
            this.$refs.trix.addEventListener('trix-attachment-add', this.uploadFileAttachment)
            this.$refs.trix.addEventListener("trix-initialize", this.initLinkInputPattern)

            if (this.content.json) {
                this.$refs.trix.editor.loadJSON(JSON.parse(this.content.json))
            }
        }
    },
    beforeDestroy() {
        this.$refs.trix?.removeEventListener('trix-attachment-add', this.uploadFileAttachment)
        this.$refs.trix?.removeEventListener("trix-initialize", this.initLinkInputPattern)
    },
    methods: {
        toggleEditing() {
            if (this.isEditing) {
                this.save()
            }
            this.isEditing = !this.isEditing
        },
        initLinkInputPattern(event) {
            // change href pattern to allow local links
            const {toolbarElement} = event.target
            const inputElement = toolbarElement.querySelector("input[name=href]")
            inputElement.type = "text"
            inputElement.pattern = "(https?://|/).+"
        },
        uploadFileAttachment(event) {
            const attachment = event.attachment
            if (attachment.file) {
                this.uploadFile(attachment.file, progress => {
                    attachment.setUploadProgress(progress)
                }, attributes => {
                    attachment.setAttributes(attributes)
                })
            }
        },
        uploadFile(file, progressCallback, successCallback) {
            const key = this.createStorageKey(file)

            const storageRef = ref(getStorage(), key)
            const uploadTask = uploadBytesResumable(storageRef, file)

            uploadTask.on('state_changed', snapshot => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes
                progressCallback(progress)
            }, error => {
                console.log(error)
            }, () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    const attributes = {
                        url: url,
                        href: url + '?content-disposition=attachment'
                    }
                    successCallback(attributes)
                })
            })
        },
        createStorageKey(file) {
            const date = new Date()
            return `${date.getTime()} - ${file.name}`
        },
        save() {
            const editor = this.$refs.trix.editor
            const html = this.$refs.trix.innerHTML

            const json = JSON.stringify(editor)
            this.content.html = html
            this.$api.setItem(this, '/greenhouse_cms', this.id, {json: json, html: html})
        }
    },
}
</script>

<style lang="scss">
.TrixCMS {
    position: relative;

    .cms-button {
        position: absolute;
        left: -50px;
        top: 0;
        background-color: #ccc;
        color: #fff;
        border: none;
        padding: 0 10px;
        box-shadow: none;
        z-index: 100;

        .cms-button-label {
            display: none;
        }

        &:hover {
            .cms-button-label {
                display: inline;
            }
        }
    }
}

.attachment__caption {
    display: none;
}

.trix-content img {
    vertical-align: baseline;
}

.attachment__toolbar {
    display: none;
}
</style>