<template>
    <div class="tasks card-list">
        <b-row class="row-heading">
            <b-col class="d-flex justify-content-between">
                <h1>{{ $i18n.gt('TASKS_HEADING') }}</h1>
                <span>
                    <b-btn v-if="isAdmin"
                           @click="navigate('/tasks/create')" variant="success">
                        <b-icon-card-checklist />
                        Add Task
                        <b-icon-plus-circle-fill/>
                    </b-btn>
                </span>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="3" v-for="item in items" :key="item.id">
                <b-card no-body class="mb-3">
                    <b-card-header>
                        <b-row>
                            <b-col class="card-list__name">
                                {{ $i18n.getTaskName(item.id) }}
                            </b-col>
                            <b-col class="text-right">
                                <b-button v-b-toggle="`collapse-${item.id}`" size="sm" variant="light">
                                    <b-icon-caret-down/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-header>
                    <b-collapse :id="`collapse-${item.id}`" class="mt-2">
                        <b-card-body>
                            <text-section-view v-for="(section, index) in item.textSections"
                                               :key="index"
                                               :section="section"/>
                        </b-card-body>
                        <b-card-footer class="text-right"
                                       v-if="isAdmin">
                            <b-btn size="sm" variant="danger" class="btn-icon mr-3"
                                   v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')"
                                   @click="deleteTask(item)">
                                <b-icon-x-square-fill/>
                            </b-btn>
                            <b-btn size="sm" variant="success"
                                   v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')"
                                   @click="navigate(`/tasks/${item.id}/edit`)" >
                                <b-icon-pencil-fill/>
                            </b-btn>
                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </b-col>
        </b-row>


    </div>
</template>

<script>

import * as _ from 'lodash'
import Task from "../models/Task"

export default {
    name: 'Tasks',
    data() {
        return {
            items: [],
        }
    },
    computed: {
        isAdmin() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.admin
            } else return !!this.$store.state.currentDbUser.admin
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async getItems() {
            const items = await this.$api.getAllItems(this, `tasks`)
            this.items = _.sortBy(items, 'name')
        },
        async deleteTask(item) {
            if (confirm('Permanently delete this Task?')) {
                await this.$api.deleteItem(this, 'tasks', item)
                this.$toasted.success(this.$i18n.gt('TASK_DELETED_SUCCESS'))

                // reload list in store to include new name
                await Task.loadTasks()

                this.getItems()
            }
        },

    },
    mounted() {
        this.getItems()
    },
}
</script>

<style lang="scss">
.tasks {
}
</style>
