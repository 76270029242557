<template>
    <div class="scouts-view">
        <b-row class="m-3 mt-4 justify-content-between">
            <h1>{{$i18n.gt('SCOUT_VIEW')}}</h1>
            <div>
                <b-btn variant="success" class=" ml-2 btn-icon"
                       @click="$router.push(`/locations/${locId}/cards/${cardId}/scouts/${scoutId}/edit`)">
                    <b-icon-pencil/>
                    {{ $i18n.gt('SCOUT_EDIT_HEADING') }}
                </b-btn>
                <b-btn class="delete-loc ml-2 btn-icon" variant="danger" @click="deleteScout()">
                    <b-icon-x-lg/>
                    {{ $i18n.gt('SCOUT_DELETE') }}
                </b-btn>
            </div>
        </b-row>
        <spinner-until :condition="loaded">
            <b-card class="data">
                <b-row>
                    <b-col class="">
                        <strong>{{ $i18n.gt('SCOUT_DATE_FIELD') }}</strong>
                    </b-col>
                    <b-col class="">
                        {{ formatDate(scout.date) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="">
                        <strong>{{ $i18n.gt('SCOUT_PEST_HEADING') }}</strong>
                    </b-col>
                    <b-col class="">
                        <ul>
                            <li v-for="(pest, index) in getPests(scout.pests)" :key="index">
                                <router-link :to="pest.link">{{ pest.name }}</router-link>
                                 : {{ pest.count }}
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="">
                        <strong>{{ $i18n.gt('SCOUT_BENEFICIAL_HEADING') }}</strong>
                    </b-col>
                    <b-col class="">
                        <ul>
                            <li v-for="(beneficial, index) in getBeneficials(scout.beneficials)" :key="index">
                                <router-link :to="beneficial.link">{{ beneficial.name }}</router-link>
                                : {{ beneficial.count }}
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="">
                        <strong>{{ $i18n.gt('SCOUT_DISEASE_HEADING') }}</strong>
                    </b-col>
                    <b-col class="">
                        <ul>
                            <li v-for="(disease, index) in getDiseases(scout.diseases)" :key="index">
                                <router-link :to="disease.link">{{ disease.name }}</router-link>
                                : {{ disease.count }}
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="">
                        <strong>{{ $i18n.gt('SCOUT_NOTES_FIELD') }}</strong>
                    </b-col>
                    <b-col class="">
                        {{ scout.notes }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="">
                        <strong>{{ $i18n.gt('SCOUT_IMAGES_FIELD') }}</strong>

                    </b-col>
                    <b-col class="images">
                        <b-row class="m-0" v-if="$mq === 'lg' || $mq === 'xl'">
                            <image-viewer v-for="(image, index) in scout.imageRefs" :key="index" :source="image"/>
                        </b-row>
                        <template v-else>
                            <image-viewer v-for="(image, index) in scout.imageRefs" :key="index" :source="image"/>
                        </template>
                    </b-col>
                </b-row>
            </b-card>
        </spinner-until>
    </div>
</template>

<script>

import SpinnerUntil from "@/components/SpinnerUntil.vue";
import moment from 'moment'
import * as _ from "lodash";
import ImageViewer from "@/components/ImageViewer.vue";

export default {
    name: 'ScoutsView',
    components: {ImageViewer, SpinnerUntil},
    props: {
        locId: String,
        cardId: String,
        scoutId: String,
    },
    data() {
        return {
            scout: {},
            loaded: false,
            fields: ['date', 'pests', 'beneficials', 'diseases', 'notes', 'imageRefs']
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format('MM/DD/YY')
        },
        async deleteScout() {
            if (await this.$bvModal.msgBoxConfirm(this.$i18n.gt('SCOUT_DELETE_CONFIRM'), {okVariant: 'danger', okTitle: this.$i18n.gt('DELETE_TOOLTIP')})) {
                await this.$api.deleteItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/scouts`, {id: this.scoutId})
                this.$toasted.success(this.$i18n.gt('SCOUT_DELETE_SUCCESS'))
                this.$router.push(`/locations/${this.locId}/cards/${this.cardId}/chart`)
            }
        },

        getPests(scoutPests) {
            const pestItems = []
            _.forEach(scoutPests, (pestScout) => {
                pestItems.push({
                    name: this.$i18n.getPestName(pestScout.ref),
                    count: pestScout.count,
                    link: `/pests/view/${pestScout.ref.split('/')[3]}`,
                })
            })
            return pestItems
        },
        getBeneficials(scoutBeneficials) {
            const beneficialItems = []
            _.forEach(scoutBeneficials, (beneficialScout) => {
                beneficialItems.push({
                    name: this.$i18n.getBeneficialName(beneficialScout.ref),
                    count: beneficialScout.count,
                    link: `/beneficials/view/${beneficialScout.ref.split('/')[1]}`,
                })
            })
            return beneficialItems
        },
        getDiseases(scoutDiseases) {
            const diseaseItems = []
            _.forEach(scoutDiseases, (diseaseScout) => {
                diseaseItems.push({
                    name: this.$i18n.getDiseaseName(diseaseScout.ref),
                    count: diseaseScout.count,
                    link: `/diseases/view/${diseaseScout.ref.split('/')[1]}`,
                })
            })
            return diseaseItems
        },
    },
    async mounted() {
        this.scout = await this.$api.getItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/scouts/`, this.scoutId)
        this.loaded = true;
    }
}
</script>

<style lang="scss">
div.scouts-view {
    margin-top: 2em !important;

    .data > .card-body > .row:nth-child(odd){
        background-color: #e5e5e5;
    }
    .row {
        margin: .5em 1em;
    }
    ul {
        list-style-type: none;
        margin-bottom: 0;
    }
    @media (max-width:767px) {
        .image-viewer {
            max-width: 100%;
            margin: 0 0 10px 0;
        }
    }
    @media (min-width:768px) {
        .image-viewer {
            max-width: 30%;
            margin: 0 10px 0 0;
        }
    }
}

</style>