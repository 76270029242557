<template>
    <div class="locations-create narrow-form">
        <h1 class="page-heading">{{ $i18n.gt('LOCATIONS_CREATE_HEADING') }}</h1>
        <b-overlay :show="saving">
            <data-form :field-model="locationModel"
                       :on-submit-handler="createLocation"
                       :field-groups="fieldGroups"
                       cancel-route="/locations"
                       bottom-submit-button bottom-cancel-button/>
        </b-overlay>

    </div>
</template>

<script>

import LocationFields from '../models/Location'
import Utils from '@/providers/Utils'

export default {
    name: 'LocationsCreate',
    data() {
        return {
            locationModel: LocationFields,
            fieldGroups: [
                ['name', 'image', 'notes'],
            ],
            saving: false,
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async createLocation(modelInstance) {
            this.saving = true
            const item = {name: modelInstance.name, notes: modelInstance.notes ?? '', image: ''}
            if (modelInstance.image) {
                await Utils.uploadFile(modelInstance, 'image')
                item.image = modelInstance.image
            }
            const itemResponse = await this.$api.createItem(this, `users/${this.userId}/locations/`, item)
            this.saving = true
            this.navigate(`/locations/${itemResponse.id}/cards/create`)
        }
    }
}
</script>

<style lang="scss">
.locations-create {
    label.custom-file-label {
        z-index: 0;
    }
}
</style>