<template>
    <div class="terms">
        <h1>Terms</h1>
    </div>
</template>

<script>

export default {
    name: 'Terms',
    data() {
        return {}
    },
    computed: {
    },
}
</script>

<style lang="scss">
.terms {
}
</style>