<template>
    <div class="data-form">

        <b-modal hide-footer
                 no-close-on-backdrop
                 body-class="light-bg"
                 :id="modalId"
                 @show="onShow">

            <template v-slot:modal-header>
                <b-row>
                    <b-col cols="6">
                        <h5>{{ title }}</h5>
                    </b-col>
                    <b-col cols="3" class="text-right">
                        <b-button variant="default" class="mr-3"
                                  @click="hideModal()">
                            <span v-if="$mq === 'sm'">
                                <b-icon icon="x"/>
                            </span>
                            <span v-else>
                                Cancel
                            </span>
                        </b-button>
                    </b-col>
                    <b-col cols="3" class="text-right">
                        <b-button variant="primary"
                                  class="mt-2 mt-sm-0 btn-light"
                                  :disabled="$v.form && $v.form.$invalid"
                                  @click="submitForm()">
                            <span v-if="$mq === 'sm'">
                                <b-icon icon="check"/>
                            </span>
                            <span v-else>
                                {{ saveButtonLabel }}
                            </span>
                        </b-button>
                    </b-col>
                </b-row>
            </template>

            <b-form ref="form">

                <!-- form inputs for specified fields -->
                <data-form-input class="mt-3"
                                 v-for="(fieldName,i) in fieldList"
                                 v-model="form[fieldName]"
                                 :key="i"
                                 v-bind:$v="$v"
                                 :form="form"
                                 :field-model="fieldModel"
                                 :field-name="fieldName"
                                 :horizontal="false"/>

            </b-form>

            <!-- slot for additional content -->

            <slot/>

        </b-modal>
    </div>
</template>

<script>

/**
 * A generic modal form for creating or updating an instance of a model, with validation.
 * Similar to DataForm, but with different form layout and submit actions.
 *
 * Requires a field model specifying the types and validation behaviors of the model's fields,
 * as well as a list of names of fields to display on the form
 * (these are not grouped as they are for DataForm).
 *
 * Functions (not promises) must also be provided to load or initialize the model instance, and to save it.
 */

import Vue from 'vue'
import Validation from '@/providers/Validation'
import {validationMixin} from 'vuelidate'

export default Vue.extend({
    name: 'DataFormModal',
    mixins: [validationMixin],
    props: {
        modalId: String,
        title: String,
        saveButtonLabel: String,
        fieldList: Array,
        fieldModel: Object,
        modelInstance: Object,
        onSubmitHandler: Function,
    },
    data () {
        return {
            form: Validation.buildFormModel(this.fieldModel, this.fieldList),
        }
    },
    validations () {
        return Validation.buildFormValidations(this.fieldModel, this.fieldList)
    },
    methods: {
        onShow (evt) {
            Validation.populateFormModel(this.form, this.fieldModel, this.modelInstance, this.fieldList)
        },
        hideModal () {
            this.$bvModal.hide(this.modalId)
        },
        submitForm () {
            this.$v.form.$touch()
            if (!this.$v.form.$anyError) {
                Validation.extractFormModel(this.form, this.$v.form, this.fieldModel, this.modelInstance, this.fieldList)
                this.$bvModal.hide(this.modalId)
                this.onSubmitHandler()
            }
        },
    },
})

</script>
