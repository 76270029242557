<template>
    <div class="data-table">
        <!-- filters (optional) -->
        <div v-if="showFilters" class="mb-4">
            <div class="card page-search text-left mb-0">
                <b-card-header>
                    <h3
                        class="my-0">
                        Search
                    </h3>
                    <b-btn size="sm"
                           class="float-right my-0 btn-light"
                           @click="resetFilters()">
                        Reset
                    </b-btn>
                    <slot name="buttons"/>
                </b-card-header>

                <b-card-body>
                    <!-- slot for optional custom rendering of filters -->
                    <slot name="filters">

                        <!-- default content: generic filter form based on filters spec -->
                        <b-row class="page-search__filters flex-column flex-sm-row">
                            <b-col v-for="(f,i) in filtersNotHidden" :key="i">
                                <b-form-group :label="f.label">

                                    <!-- input for any filter operator -->
                                    <b-form-input type="text"
                                                  v-model="filters[f.field].value"
                                                  @input="filterChanged(f)"/>

                                </b-form-group>
                            </b-col>
                        </b-row>


                        <!-- ... or the parent component can define a custom template, using arbitrary inputs
                            based on the defined filters:

                            <b-form-group horizontal label="Email">
                                <b-form-input v-model="filters.email.value"/>
                            </b-form-group>
                        -->
                    </slot>
                </b-card-body>
            </div>
        </div>
        <div class="responsive">
            <div v-if="selectableRows" class="mb-2">
                <b-btn variant="light" size="sm" class="mr-2"
                       @click="selectAllRows()">
                    Select All
                </b-btn>
                <b-btn variant="light" size="sm"
                       @click="clearSelected()">
                    Clear Selection
                </b-btn>
            </div>

            <b-table striped
                     :items="itemProvider"
                     :selectable="selectableRows"
                     select-mode="multi"
                     @row-selected="rowSelected"
                     ref="table"
                     :id="tableId"
                     :fields="allFields"
                     :current-page="context.currentPage"
                     :per-page="context.perPage"
                     :sort-by.sync="context.sortBy"
                     :sort-desc.sync="context.sortDesc"
                     no-local-sorting
                     :busy.sync="isBusy">

                <!-- loading spinner -->
                <template v-slot:table-busy>
                    <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"/>
                        <strong class="px-3">Loading...</strong>
                    </div>
                </template>

                <!-- template for show-details column -->
                <template v-slot:cell(show_details)="row">
                    <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
                    <b-button size="sm" class="btn-icon"
                              @click.stop="row.toggleDetails">
                        <b-icon :icon="row.detailsShowing ? 'chevron-bottom' : 'chevron-right'"/>
                    </b-button>
                </template>

                <!-- template for custom row details display -->
                <template v-slot:row-details="row">
                    <slot name="customRowDetails" v-bind:row="row">
                        <!-- the parent component can define a custom template for this slot - e.g.:

                            <template v-slot:customRowDetails="slotProps">
                                My row ID is: {{ slotProps.row.item.id }}
                            </template>
                        -->
                    </slot>
                </template>

                <!-- template for custom column rendering -->
                <template v-slot:cell()="row">
                    <slot name="customField" v-bind:row="row">
                        <!-- default content: -->
                        <!-- if the field has a 'get' method (computed value) -->
                        <div v-if="row.field.get">
                            {{ row.field.get(row.item) }}
                        </div>
                        <div v-else-if="row.field.type === 'timestamp'">
                            <timestamp :date="row.item[row.field.key]"/>
                        </div>
                        <div v-else-if="row.field.type === 'boolean'">
                            <b-icon-check font-scale="3" v-if="row.item[row.field.key]"/>
                            <b-icon-x font-scale="3" v-else/>
                        </div>
                        <div v-else-if="row.field.type === 'check'">
                            <b-icon-check font-scale="3" v-if="row.item[row.field.key]"/>
                            <b-icon-x font-scale="3" v-else/>
                        </div>
                        <!-- otherwise -->
                        <div v-else>
                            {{ row.item[row.field.key] }}
                        </div>

                        <!-- ... or the parent component can define custom templates for arbitrary fields, e.g.:

                            <template v-slot:customField="slotProps">
                                <div v-if="slotProps.row.field.key === 'field1'">
                                    My field1 value: {{ slotProps.row.item.field1 }}
                                </div>
                            </template>

                                If none of the custom templates match for a given column, the above default rendering
                                rules will be used.
                            -->
                    </slot>
                </template>
            </b-table>
        </div>
        <!-- pager (optional) -->
        <div v-if="showPager" class="my-3 data-table__pager">
            <div class="d-flex">
                <div class="text-right mr-3" v-if="showPerPage">
                    <!-- Dropdown items per page select (optional) -->
                    <b-form-select class="per-page-select"
                                   v-model="context.perPage">
                        <option value="2">2</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                    </b-form-select>
                </div>
            </div>
        </div>
    </div>
</template>

<!-- ----------------------------------------------------------------------- -->

<script>
/*
 * This component wraps b-table and b-pagination to provide a generic data table with sortable columns,
 * plus optional filters and pagination.
 *
 * See comments in template above for custom column rendering.
 *
 * Table columns and filters are specified via 'fields' and 'filters', resp., in the data method
 * (OR as computed properties).
 *
 * data() {
 *     return {
 *         ...,
 *         fields: [
 *             { key: 'myField', sortable: true, label: 'My Field' },
 *             { key: 'participant.firstname', sortable: true, label: 'First Name',
 *               get: (item) => { return item.participant.firstname } },
 *         ],
 *         filters: {
 *             text: {field: 'text', type: 'string', value: null, label: 'First/Last Name'},
 *             type: {field: 'type', type: 'select', value: null, label: 'Type', options: this.typeOptions},
 *             practiceId: {field: 'practiceId', type: 'hidden', value: this.practiceId},
 *         }
 *
 * Allowable filter types are 'string', 'select', 'multiselect', or 'hidden'.
 * For select/multiselect filter formats, see DataTableFilterSelect.vue, DataTableFilterMultiselect.vue (resp.)
 *
 * Field specifications can also include a 'get' function, as in the participant.firstname example,
 * in order to calculate a display value from the row's data item.
 */

import Vue from 'vue'
import * as _ from 'lodash'

export default Vue.extend({
    name: 'DataTableFirestoreSort',
    props: {
        tableId: { type: String, default: 'table' },
        showFilters: {
            type: Boolean,
            default: false
        },
        showPager: {
            type: Boolean,
            default: false
        },
        showDetails: {
            type: Boolean,
            default: false
        },
        selectableRows: {
            type: Boolean,
            default: false
        },
        getItems: Function,
        value: {
            type: Object,
            default: null
        },
        fields: Array,
        showPerPage: {
            type: Boolean,
            default: true
        },
        defaultItemsPerPage: Number,
    },
    data () {
        return {
            context: {
                perPage: this.defaultItemsPerPage,
                currentPage: 1,
                sortBy: this.fields[0].key,
                sortDesc: false,
            },
            isBusy: false,
            booleanOptions: [
                { value: '', text: 'All' },
                { value: true, text: 'Yes' },
                { value: false, text: 'No' },
            ]
        }
    },
    computed: {
        filters: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        allFields () {
            const detailsFields = (this.showDetails
                ? [{ key: 'show_details', sortable: false, label: '' }]
                : [])
            return _.concat(detailsFields, this.fields)
        },
        filtersNotHidden () {
            return _.pickBy(this.filters, (filter) => filter.type !== 'hidden')
        },
    },
    methods: {
        getContext () {
            return this.context
        },
        itemProvider (ctx) {
            return this.getItems(ctx).then((items) => {
                this.context = ctx
                if (items && items.length === 1) {
                    Vue.set(items[0], '_showDetails', true)
                }
                return items
            })
        },
        resetFilters () {
            _.each(this.filters, (f) => {
                // reset filters from route to provided value
                const routeValue = this.$route.params[f.routeParam]
                if (routeValue) {
                    f.value = routeValue
                }
                // reset value to null (except for hidden filter)
                else if (f.type !== 'hidden') {
                    f.value = null
                }
            })
            this.filterChanged()
        },
        filterChanged (filter = null) {
            //  refresh
            this.debouncedRefresh()
            //  emit
            this.$emit('input', this.filters, filter)
        },
        // percolate an item-selected event emitted by a multiselect filter input
        multiselectItemSelected (field, item) {
            this.$emit('multiselect-item-selected', field, item)
        },
        selectItemSelected (field, item) {
            this.$emit('select-item-selected', field, item)
        },
        // percolate a row-selected event from the b-table
        rowSelected (rows) {
            this.$emit('row-selected', rows)
        },
        selectAllRows () {
            this.$refs.table.selectAllRows()
        },
        clearSelected () {
            this.$refs.table.clearSelected()
        },
        refresh () {
            if (this.$refs.table) {
                this.$refs.table.refresh()
            }
        },
        setFiltersFromRoute () {
            _.each(this.$route.params, (val, key) => {
                const filter = _.find(this.filters, (f) => {
                    return f.routeParam === key
                })
                if (filter) {
                    Vue.nextTick(() => {
                        filter.value = val
                    })
                }
            })
        },
    },
    created () {
        this.debouncedRefresh = _.debounce(this.refresh, 500)
        this.setFiltersFromRoute()
    },
    watch: {
        filters: {
            deep: true,
            handler: function (newVal, oldVal) {
                if (!this.$refs.table.busy) {
                    this.debouncedRefresh()
                }
            },
        }

    }
})

</script>

<!-- ----------------------------------------------------------------------- -->

<style lang="scss">

.search-max-width .page-search fieldset {
    max-width: 500px;
}

.page-search {
    .col-form-label {
        padding-bottom: 3px;
    }

    .card-header {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: .5rem 1.25rem;

        h3 {
            margin-bottom: 0 !important;
        }

        .btn {
            max-width: 200px;
        }
    }

    .card-body {
        padding: .75rem 1.25rem 0;
    }
}

.page-search__filters .col {
    max-width: 500px;
}

.card.page-search {
    border: none;

    .card-header {
        background-color: var(--black);
        color: #fff;
        @media (max-width: 575px) {
            .btn {
                font-size: 16px;
            }
        }
    }

    .card-body {
        background-color: var(--pod-bg-color);
        background-color: var(--yellow-lighten-30);
    }

}

.data-table {
    .per-page-select {
        width: auto;
    }

    .data-table__pager {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    /* Busy table styling */
    table.b-table[aria-busy='true'] {
        opacity: 0.6;
    }
}

</style>
