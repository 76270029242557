/**
 * Model class for Diseases
 */

import {email, minLength, maxLength, numeric, required, sameAs} from 'vuelidate/lib/validators'
import Api from '@/providers/Api'
import { store } from '@/store'
import i18n from '@/providers/i18n'
import * as _ from 'lodash'
import Symptom from '@/models/Symptom'

export default {
    // load master list of diseases into the store
    async loadDiseases () {
        store.state.diseases = await Api.getAllItems(null, 'diseases')
    },

    // helper method to load diseases
    buildDiseaseOptions () {
        const diseases = store.state.diseases
        let options = _.map(diseases, (disease) => {
            return {
                value: `diseases/${disease.id}`, text: i18n.getDiseaseName(disease.id)
            }
        })
        return _.sortBy(options, ['text'])
    },

    fields: {
        name: {
            translationKey: 'DISEASE_NAME_FIELD',
            type: 'text',
            validations: { required }
        },
        name_es: {
            translationKey: 'DISEASE_NAME_ES_FIELD',
            type: 'text',
            // validations: { required }
        },
        symptoms: {
            translationKey: 'DISEASE_SYMPTOMS_FIELD',
            type: 'checks',
            options: [],
            class: 'disease-symptoms',
            loadOptions: async function(model) {
                this.options = Symptom.buildSymptomOptions()
            },
            collapsible: true,
        },
        factSheetUrl: {
            translationKey: 'BENEFICIAL_FACT_SHEET_URL_FIELD',
            type: 'url',
        },
        textSections: {
            translationKey: 'DISEASE_TEXT_SECTIONS_FIELD',
            type: 'textsections',
        },
        // TODO: symptoms?
    }
}
