<template>
    <div class="data-table-filter-multiselect">
        <multiselect v-model="selectedItem"
                     :label="labelField"
                     track-by="id"
                     placeholder="Type to search"
                     open-direction="bottom"
                     :options="items"
                     :multiple="false"
                     :searchable="true"
                     :loading="isLoading"
                     :internal-search="false"
                     :clear-on-select="false"
                     :close-on-select="true"
                     :options-limit="300"
                     :limit="10"
                     :max-height="600"
                     :show-no-results="true"
                     :hide-selected="true"
                     @search-change="doSearch"
                     @select="itemSelected"
                     @remove="itemSelected">

            <span slot="noResult">No matches found (Enter at least 2 characters)</span>

            <span slot="noOptions">
                Start typing...
            </span>

        </multiselect>
    </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'

/**
 * A wrapper for the vue-multiselect widget (single-selection mode only), for use as a typeahead with DataTable filters.
 * Search results are retrieved dynamically from the API, optionally using a custom search callback.
 * This widget tracks the ID of the selected item, and emits an item-selected event (with the full selected item),
 * which can be used e.g. to update a page title. It also handles the reset action on a filter form.
 */

export default Vue.extend({
    name: 'DataTableFilterMultiselect',
    components: { Multiselect },
    props: {
        value: {
            required: true
        },
        search: {
            // optional custom callback to search for matching records - e.g.
            // (query) => { return Api.getItems(this, 'company', { name: { field: 'name', value: query } }) }
            type: Function
        },
        apiTable: {  // the table/controller path from which to fetch matches (e.g. 'company' or 'company-user')
            type: String,
            required: true
        },
        labelField: {  // the field to use to fetch a display label for each search result item (e.g. 'name')
            type: String,
            required: true
        }
    },
    data () {
        return {
            localValue: null,
            items: [],           // the search results for the current query
            selectedItem: null,  // the object selected from the search results
            isLoading: false,
        }
    },
    methods: {
        // trigger search for typeahead
        async doSearch (query) {
            this.isLoading = true
            if (query && query.length > 1) {
                if (this.search) {
                    // optional custom search
                    this.items = await this.search(query)
                } else {
                    this.items = await this.$api.getItems(this, this.apiTable, { name: { field: 'name', value: query } })
                }
            } else {
                this.items = []
            }
            this.isLoading = false
        },
        // called when a result is selected
        itemSelected (item) {
            this.localValue = item.id
            this.selectedItem = item
            this.$emit('item-selected', item)
            this.$emit('input', this.localValue)
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: async function (newVal, oldVal) {
                // handle value change (including initialization, filter form reset to null or non-null value)
                if (!newVal) {
                    this.localValue = null
                    this.selectedItem = null
                    this.$emit('item-selected', null)
                    this.$emit('input', this.localValue)
                } else {
                    this.localValue = newVal
                    const item = await this.$api.getItem(this, this.apiTable, newVal)
                    this.selectedItem = item
                    this.$emit('item-selected', item)
                    this.$emit('input', this.localValue)
                }
            }
        }
    }
})
</script>

<style lang="scss">
@import "../../node_modules/vue-multiselect/dist/vue-multiselect.min";
</style>