<template>
    <div class="beneficials card-list">
        <b-row class="row-heading">
            <b-col class="d-flex justify-content-between">
                <h1>{{ $i18n.gt('BENEFICIALS_LABEL') }}</h1>
                <span class="btn-add--top">
                    <b-btn v-if="isAdmin"
                           @click="navigate('/beneficials/create')" variant="success">
                    <img src="../../assets/icons/wasp.svg">
                    Add Beneficial
                    <b-icon-plus-circle-fill/>
                </b-btn>
                </span>
            </b-col>
        </b-row>

        <spinner-until :condition="loaded">

            <b-row>
                <b-col cols="12" md="4" lg="4" v-for="item in items" :key="item.id">
                    <b-card no-body class="mb-3">
                        <b-card-header>
                            <b-row>
                                <b-col class="card-list__name">
                                    {{ $i18n.getBeneficialName(item.id) }}
                                </b-col>
                                <b-col class="text-right">
                                    <b-button v-b-toggle="`collapse-${item.id}`" size="sm" variant="light">
                                        <b-icon-caret-down/>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-header>
                        <b-collapse :id="`collapse-${item.id}`">
                            <b-card-body>
                                <em v-if="item.scientificName">{{ item.scientificName }}</em>
                                <b-col cols="12" md="8" class="my-2">
                                    <image-viewer :source="item.mainImage"/>
                                </b-col>
                                <text-section-view v-for="(section, index) in item.textSections"
                                                   :key="index"
                                                   :section="section" class="my-3"/>
                                <div class="beneficial__pests" v-if="item.pestRefs.length > 0">
                                    <strong>{{ $i18n.gt('PESTS_LABEL') }}: </strong>
                                    <template v-for="pest in item.pestRefs" >
                                        <router-link :to="`pests/view/${pest.ref}`"
                                                     :key="`${item.id}-${pest.ref}`">
                                            {{pest.name}}
                                        </router-link>
                                    </template>
                                </div>
                            </b-card-body>
                            <b-card-footer class="text-right">
                                <template v-if="item.factSheetUrl">
                                    <b-btn size="sm" variant="secondary" class="btn-icon mr-3"
                                           v-b-popover.top.hover="$i18n.gt('FACT_SHEET_TOOLTIP')"
                                           :href="item.factSheetUrl" target="_blank">
                                        <b-icon-link45deg/>
                                    </b-btn>
                                </template>
                                <b-btn size="sm" variant="secondary" class="btn-icon mr-3"
                                       v-b-popover.top.hover="$i18n.gt('PESTICIDE_INTERACTIONS_TOOLTIP')"
                                       @click="$router.push(`/interactions/beneficials/${item.id}`)">
                                    <img src="../../assets/icons/spray.svg" style="width: 14px;">
                                </b-btn>
                                <template v-if="isAdmin">
                                    <b-btn size="sm" variant="danger" class="btn-icon mr-3"
                                           v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')"
                                           @click="deleteBeneficial(item)">
                                        <b-icon-x-square-fill/>
                                    </b-btn>
                                    <b-btn size="sm" variant="success" class="btn-icon"
                                           v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')"
                                           @click="navigate(`/beneficials/${item.id}/edit`)">
                                        <b-icon-pencil-fill/>
                                    </b-btn>
                                </template>
                            </b-card-footer>
                        </b-collapse>
                    </b-card>
                </b-col>
            </b-row>

        </spinner-until>

        <b-row class="mt-3">
            <b-col class="d-flex justify-content-end">
                <b-btn v-if="isAdmin"
                       @click="navigate('/beneficials/create')" variant="success">
                    <img src="../../assets/icons/wasp.svg">
                    Add Beneficial
                    <b-icon-plus-circle-fill/>
                </b-btn>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import * as _ from 'lodash'
import Beneficial from "../models/Beneficial";
import ImageViewer from "@/components/ImageViewer.vue";
import Pest from "../models/Pest";
import SpinnerUntil from "@/components/SpinnerUntil.vue";

export default {
    name: 'Beneficials',
    components: {SpinnerUntil, ImageViewer},
    props: {
        beneficialId: String
    },
    data() {
        return {
            items: [],
            loaded: false,
        }
    },
    computed: {
        isAdmin() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.admin
            } else return !!this.$store.state.currentDbUser.admin
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        getPests(item) {
            const pestRefs = _.filter(this.$store.state.pestsUnnested, (pest) => {
                return !!pest.beneficials && pest.beneficials.includes(item.id)
            })
            const pests = []
            if (pestRefs) {
                _.forEach(pestRefs,(pest, key) => {
                    pests.push({
                        name: `${this.$i18n.getPestName(pest.id)}${key !== pestRefs.length-1 ? ', ' : ''}`,
                        ref: pest.id
                    })
                })
            }
            const sorted = _.sortBy(pests, (item) => {
                return item.name
            })
            return sorted
        },
        async getItems() {
            const items = _.sortBy(this.$store.state.beneficials, 'name')
            _.forEach(items, (ben) => {
                ben.pestRefs = this.getPests(ben)
            })
            this.items = items
        },
        async deleteBeneficial(item) {
            if (confirm('Permanently delete this Beneficial?')) {
                await this.$api.deleteItem(this, 'beneficials', item)
                this.$toasted.success(this.$i18n.gt('BENEFICIAL_DELETED_SUCCESS'))

                // reload beneficial list in store to include new name
                await Beneficial.loadBeneficials()

                this.getItems()
            }
        },
    },
    async mounted() {
        await this.getItems()
        this.loaded = true
        // if a ben id is passed, un-collapse it by default
        if (this.beneficialId) {
            setTimeout(async () => {
                await this.$root.$emit('bv::toggle::collapse', `collapse-${this.beneficialId}`)
                document.getElementById(`collapse-${this.beneficialId}`).parentElement.scrollIntoView()
                this.$router.replace('/beneficials')
            })
        }
    },
}
</script>

<style lang="scss">
.beneficials {
    .card {
        //border-color:#ccc;
    }
}
</style>
