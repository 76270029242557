<template>
    <div class="datetime-picker">
        <b-form-input type="date" v-model="date" :state="state" @input="dateInput"></b-form-input>
        <b-form-input type="time" v-model="time" :state="state" @input="timeInput"></b-form-input>
    </div>
</template>

<script>
export default {
    name: "DatetimePicker",
    props: {
        value: String,
        state: Boolean,
    },
    data () {
        const date = this.value ? this.value.slice(0, 10) : null
        const time = this.value ? this.value.slice(11, 19) : null
        return {
            date: date,
            time: time,
            datetime: null,
        }
    },
    methods: {
        dateInput (value) {
            this.date = value
            this.emitDatetime()
        },
        timeInput (value) {
            this.time = value
            this.emitDatetime()
        },
        emitDatetime () {
            if (this.date && this.time) {
                this.datetime = this.date + " " + this.time
            } else {
                this.datetime = null
            }

            this.$emit('input', this.datetime)
        },
    }
}
</script>

<style scoped>

</style>