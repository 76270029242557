<template>
    <div class="not-found">

        <h1>Page Not Found</h1>

    </div>
</template>

<!-- ----------------------------------------------------------------------- -->

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'NotFound',
})

</script>