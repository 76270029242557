<template>
    <div class="home">
        <HomePublic v-if="!loggedIn"/>
        <HomeUser v-else/>
    </div>
</template>

<script>

import HomePublic from '@/pages/HomePublic'
import HomeUser from '@/pages/HomeUser'

export default {
    name: 'Home',
    components: {
        HomePublic,
        HomeUser,
    },
    data() {
        return {}
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        },
        loggedIn () {
            return !!this.$store.state.currentUser.uid;
        },
        isAdmin () {
            if (this.maskUser.email) {
                return this.maskUser.admin
            } else return !!this.$store.state.currentDbUser.admin
        },
    },
}
</script>

<style lang="scss">
.home {
    a {
        display: block;
    }

    .top-link {
        text-align: right;

        a {
            max-width: 350px;
            display: inline-block;
        }
    }
}
</style>