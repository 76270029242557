<template>
    <b-overlay :show="loading" class="user-signup card my-5 p-4">
        <data-form :field-groups="fieldGroups"
                   :field-model="userModel"
                   :save-button-label="$i18n.gt('SIGNUP')"
                   :on-submit-handler="signup"
                   bottom-submit-button
                   :title="$i18n.gt('SIGNUP')"/>

        <div class="my-3 mx-auto">
            {{ $i18n.gt('SIGNUP_EXISTING_TIP') }}<br>
            <router-link style="display: inline" to="/login" class="btn btn-secondary mt-2 mx-auto d-block">{{ $i18n.gt('SIGNUP_EXISTING_LABEL') }}</router-link>
        </div>
    </b-overlay>
</template>

<script>

import UserFields from "@/models/User";

export default {
    name: 'UserSignup',
    data () {
        return {
            userModel: UserFields,
            fieldGroups: [
                ['email', 'password', 'passwordConfirm'],
            ],
            loading: false,
        }
    },
    methods: {
        async signup (modelInstance) {
            this.loading = true
            await this.$api.signupUser(this, modelInstance.email, modelInstance.password)
            if (this.$store.state.currentUser.uid) {
                this.$toasted.success(this.$i18n.gt('SIGNUP_SUCCESS'))
            } else {
                this.$toasted.error(this.$i18n.gt('SIGNUP_FAILED'))
            }
            this.loading = false
        }
    }
}
</script>

<style lang="scss">
.user-signup{
    max-width:460px!important;
    margin: 0 auto;
    small {
        display: none;
    }
}
</style>