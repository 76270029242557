/**
 * Translation service - loads all cached translations from the server, and provides a gt()
 * function similar to that formerly used on the PHP side.
 *
 * The former translation setup had extensive machinery to handle dynamically-changing content
 * sources - specifically, questionnaires and questions that could be added in the database.
 *
 * In this new simplified version, all translations are stored in CSV files (for ease of updating),
 * which are loaded at app startup.
 */
import Api from '@/providers/Api'
import * as _ from 'lodash'
import Papa from 'papaparse'
import {store} from '@/store'
import Beneficial from '@/models/Beneficial'
import Disease from '@/models/Disease'
import Symptom from '@/models/Symptom'
import Task from '@/models/Task'
import Pesticide from '@/models/Pesticide'
import Pest from '@/models/Pest'
import {SplashScreen} from "@capacitor/splash-screen";

export default {

    translationRows: [],

    async loadTranslations(language, store) {
        const csvs = await Promise.all([
            Api.loadTranslations()
        ])

        _.each(csvs, (csv) => {
            Papa.parse(csv, {
                complete: (results, file) => {
                    if (results.errors.length) {
                        alert('Error loading translation file')
                    }
                    this.translationRows = _.concat(this.translationRows, results.data)
                }
            })
        })

        store.state.translationsLoaded = true
    },

    async loadLookups() {
        await Api.isInitialized
        if (store.state.currentUser.uid) {
            await Beneficial.loadBeneficials()
            await Disease.loadDiseases()
            await Symptom.loadSymptoms()
            await Task.loadTasks()
            await Pesticide.loadPesticides()
            await Pest.loadPestTypes()
            await Pest.loadPestsNested()
            await Pest.loadPestsUnnested()
        }
        store.state.lookupsLoaded = true
        await SplashScreen.hide()
    },

    // in the following getXName methods, id can be a simple ID or a path
    // - e.g. 'beneficials/xxx' or 'pest_types/xxx/pests/yy'
    getBeneficialName(idOrPath) {
        const item = _.find(store.state.beneficials, (b) => {
            return b.id == this.getBareId(idOrPath)
        })
        return this.getItemName(item)
    },

    getDiseaseName(idOrPath) {
        const item = _.find(store.state.diseases, (d) => {
            return d.id == this.getBareId(idOrPath)
        })
        return this.getItemName(item)
    },

    getSymptomName(idOrPath) {
        const item = _.find(store.state.symptoms, (s) => {
            return s.id == this.getBareId(idOrPath)
        })
        return this.getItemName(item)
    },

    getTaskName(idOrPath) {
        const item = _.find(store.state.tasks, (t) => {
            return t.id == this.getBareId(idOrPath)
        })
        return this.getItemName(item)
    },

    getPesticideName(idOrPath) {
        const item = _.find(store.state.pesticides, (p) => {
            return p.id == this.getBareId(idOrPath)
        })
        return this.getItemName(item)
    },

    getPestName(idOrPath) {
        const item = _.find(store.state.pestsUnnested, (p) => {
            return p.id == this.getBareId(idOrPath)
        })
        return this.getItemName(item)
    },

    getPestTypeName(idOrPath) {
        const item = _.find(store.state.pestTypes, (p) => {
            return p.id == this.getBareId(idOrPath)
        })
        return this.getItemName(item)
    },

    // returns the final segment of the path if it is a path, or the passed id if it's not a path
    getBareId(pathOrId) {
        let id = pathOrId
        if (!!pathOrId && pathOrId.indexOf('/') != -1) {
            const parts = pathOrId.split('/')
            id = _.last(parts)
        }
        return id
    },

    // item is a beneficial, pest, pest type, disease, or pesticide - with 'name' and 'name_es' attributes
    getItemName(item) {
        const attr = (store.state.currentLang == 'en' ? 'name' : 'name_es')
        if (!item) {
            return '[Item not found]'
        } else if (item[attr]) {
            return item[attr]
        } else {
            return attr == 'name' ? item.name : 'es-' + item.name
        }
    },

    // use for fieldName 'heading' or 'text'
    getSectionFieldValue(section, fieldName) {
        const attr = (store.state.currentLang == 'en' ? fieldName : fieldName + '_es')
        return section[attr]
    },

    switchLanguage() {
        if (store.state.currentLang == 'en') {
            store.state.currentLang = 'es'
            document.documentElement.lang = 'es'
            document.body.classList.add('es')
            // persist language selection
            localStorage.setItem('language', 'es')
        } else {
            store.state.currentLang = 'en'
            document.documentElement.lang = 'en'
            document.body.classList.remove('es')
            // persist language selection
            localStorage.setItem('language', 'en')
        }
        window.dispatchEvent(new CustomEvent('language-changed'))
    },

    /**
     * Returns the translation for a given key in the current language.
     *
     * NB: calling this from a component template will return a bare key
     * if the translations are not loaded yet - so the topmost component should include
     * v-if="$store.state.translationsLoaded" on the main template element!
     *
     * For phrases that require parameter substitution, this can
     * be done using regular string replacement - e.g.:
     *
     *      this.$i18n.gt('MESSAGE_REPORT_MISSING_DATA')
     *         .replace('{enter_them_here}', '...')
     *
     * (but the replacement phrase will sometimes also need translation).
     */
    gt(key) {
        // find the last row with this key - to allow for custom overrides of specific entries
        const row = _.findLast(this.translationRows, (row) => {
            return row[0] == key
        })
        if (row) {
            // if no Spanish is selected and no translation is available append es- to the key
            return store.state.currentLang == 'en' ? row[1] : (row[2] ? row[2] : `es-${key}`)
        } else {
            // if no record at all, surround key in double square brackets
            return `[[${key}]]`
        }
    },
}
