/**
 * Model class for Pesticides
 */

import Api from '@/providers/Api'
import * as _ from 'lodash'
import {store} from '@/store'
import i18n from '@/providers/i18n'
import {required} from "vuelidate/lib/validators";

export default {

    // load master list of pesticides into the store
    async loadPesticides() {
        store.state.pesticides = await Api.getAllItems(null, 'pesticides')
    },

    // build checkbox options based on master list
    buildPesticideOptions() {
        const pesticides = store.state.pesticides
        let options = _.map(pesticides, (pesticide) => {
            return {
                value: `pesticides/${pesticide.id}`, text: i18n.getPesticideName(pesticide.id)
            }
        })
        return _.sortBy(options, ['text'])
    },

    fields: {
        name: {
            translationKey: 'PESTICIDE_NAME_FIELD',
            type: 'text',
            validations: { required }
        },
        name_es: {
            translationKey: 'PESTICIDE_NAME_ES_FIELD',
            type: 'text',
            // validations: { required }
        },
        textSections: {
            translationKey: 'PESTICIDE_TEXT_SECTIONS_FIELD',
            type: 'textsections',
        },
    }
}
