<template>
    <div class="locations-edit narrow-form">
        <h1 class="my-5 text-center">{{ $i18n.gt('LOCATIONS_EDIT_HEADING') }}</h1>
        <b-overlay :show="saving">
            <b-row>
                <b-col cols="12">
                    <data-form :field-model="locationModel"
                               :load-model-instance="loadModelInstance"
                               :on-submit-handler="updateLocation"
                               :field-groups="fieldGroups"
                               cancel-route="/locations"
                               bottom-submit-button bottom-cancel-button/>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>

import LocationFields from '../models/Location'

export default {
    name: 'LocationsEdit',
    props: {
        locId: String
    },
    data() {
        return {
            modelInstance: {},
            locationModel: LocationFields,
            fieldGroups: [
                ['name', 'image', 'notes'],
            ],
            saving: false,
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async loadModelInstance() {
            const item = await this.$api.getItem(this, `users/${this.userId}/locations`, this.locId)
            this.modelInstance = item
            return item
        },
        async updateLocation(modelInstance) {
            this.saving = true
            if (this.modelInstance.image instanceof File) { // new image has been selected
                await this.$api.uploadImage(this.userId, modelInstance, 'image', modelInstance.image)
            }
            await this.$api.updateItem(this, `users/${this.userId}/locations`, this.locId, modelInstance)
            this.saving = false
            this.navigate(`/locations`)
        }
    }
}
</script>

<style lang="scss">
.locations-edit {
  label.custom-file-label {
    z-index: 0;
  }
  img.location-image {
    max-height: 200px;
  }
}
</style>
