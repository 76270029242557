<template>
    <div class="data-table-filter-select">
        <b-form-select v-model="localValue"
                       :options="localOptions"
                       @input="valueSelected"/>
    </div>
</template>

<script>
import Vue from 'vue'
import * as _ from 'lodash'

/**
 * A wrapper for a select input that implements behaviors similar to the DataTableFilterMultiselect component.
 * Emits an item-selected event (with a value/text object), which can be used e.g. to update a page title.
 * It also handles the reset action on a filter form.
 */

export default Vue.extend({
    name: 'DataTableFilterSelect',
    props: {
        value: {
            required: true
        },
        options: {   // optional: specify options statically - format: [{value: ..., text: '...'}, ...]
            type: Array
        },
        apiTable: {  // the table/controller path from which to fetch options from the API (e.g. 'company' or 'company-user')
            type: String,
            required: false
        },
        labelField: {  // the field to use to fetch a display label for each option item (e.g. 'name')
            type: String,
            default: 'name'
        }
    },
    data () {
        return {
            localValue: null,
            localOptions: [],
            isLoading: false,
        }
    },
    methods: {
        // called when a value (ID) is selected
        async valueSelected (id) {
            this.localValue = id
            const item = this.findOption(id)
            this.$emit('item-selected', item)
            this.$emit('input', this.localValue)
        },
        async loadOptions () {
            if (this.options) {
                this.localOptions = this.options
            } else {
                const items = await this.$api.getAllItems(this, this.apiTable)
                this.localOptions = _.map(items, (item) => {
                    return { value: item.id, text: item[this.labelField] }
                })
            }
        },
        findOption (id) {
            return _.find(this.localOptions, (opt) => {
                return opt.value === +id
            })
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: async function (newVal, oldVal) {
                // NB: do this here rather than in created() or mounted() so options are available below
                await this.loadOptions()

                // handle value change (including initialization, filter form reset to null or non-null value)
                if (!newVal) {
                    this.localValue = null
                } else {
                    this.localValue = newVal
                    const item = this.findOption(newVal)
                    if (item) {
                        this.$emit('item-selected', item)
                        this.$emit('input', this.localValue)
                    }
                }
            }
        }
    }
})
</script>
