<template>
    <div class="breadcrumbs">
        <router-link to="/">
            Home
        </router-link>
        <template v-for="(link, i) in links">
            >
            <router-link :to="link.route" :key="i">
                {{ link.name }}
            </router-link>
        </template>
    </div>
</template>

<script>

export default {
    name: 'Breadcrumbs',
    props: {
        links: Array
    },
}
</script>

<style lang="scss">

</style>