<template>
    <div class="pests-edit narrow-form">
        <b-overlay :show="saving">
            <b-row>
                <b-col cols="12">
                    <h1 class="page-heading">Edit Pest</h1>
                    <data-form :field-model="pestModel"
                               :load-model-instance="loadModelInstance"
                               :on-submit-handler="updatePest"
                               :field-groups="fieldGroups"
                               cancel-route="/pests"
                               bottom-submit-button bottom-cancel-button/>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Pest from "../models/Pest"

export default {
    name: 'PestsEdit',
    props: {
        pestTypeId: String,
        pestId: String,
    },
    data() {
        return {
            modelInstance: {},
            pestModel: Pest,
            fieldGroups: [
                ['name', 'name_es', 'scientificName', 'pestTypeId', 'mainImage', 'factSheetUrl', 'symptoms', 'beneficials', 'textSections'],
            ],
            saving: false,
        }
    },
    computed: {},
    methods: {
        async loadModelInstance() {
            const item = await this.$api.getItem(this, `pest_types/${this.pestTypeId}/pests`, this.pestId)
            this.modelInstance = item
            return item
        },
        async updatePest(modelInstance, vform) {
            this.saving = true
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadFile(modelInstance, 'mainImage')
            await Utils.uploadModelTextSectionImages(modelInstance)

            this.$api.updateItem(this, `pest_types/${this.pestTypeId}/pests`, this.pestId, modelInstance)

            // reload pest list in store (in case name changed)
            await Pest.loadPestsNested()
            await Pest.loadPestsUnnested()
            this.saving = false
            this.$router.push(`/pests/view/${this.pestId}`)
            this.$toasted.success(this.$i18n.gt('PEST_UPDATED_SUCCESS'))
        }

    }
}
</script>

<style lang="scss">
.pests-edit {
  label.custom-file-label {
    z-index: 0;
  }

    .image-preview {
        margin-bottom:1em;
    }

  img.location-image {
    max-height: 200px;
  }
}
</style>
