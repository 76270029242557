<template>
    <div class="cards-edit">
        <h1 class="page-heading">{{ $i18n.gt('CARDS_EDIT_HEADING') }}</h1>

        <data-form :field-model="cardModel"
                   :on-submit-handler="updateCard"
                   ref="dataForm"
                   :field-groups="fieldGroups"
                   cancel-route="/locations"
                   :load-model-instance="getCard"
                   :submit-success-message="successMessage"
                   bottom-submit-button bottom-cancel-button>
        </data-form>
    </div>
</template>

<script>

import CardFields from '../models/Card'

export default {
    name: 'CardsEdit',
    props: {
        locId: String,
        cardId: String,
    },
    data() {
        return {
            cardModel: CardFields,
            fieldGroups: [
                ['location', 'name'],
                ['pests'],
                ['beneficials'],
                ['diseases', 'notes']
            ],
            successMessage: this.$i18n.gt('CARD_CREATE_SUCCESS')
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        }
    },
    methods: {
        getLocId(path) {
            const pattern = /.*(locations\/)/g
            return path.replace(pattern, '')
        },
        navigate(route) {
            this.$router.push(route)
        },
        async updateCard(modelInstance) {
            delete modelInstance.existingCards
            if (modelInstance.beneficials && typeof modelInstance.beneficials == 'string') {
                modelInstance.beneficials = modelInstance.beneficials.split(',')
            }
            if (modelInstance.diseases && typeof modelInstance.diseases == 'string') {
                modelInstance.diseases = modelInstance.diseases.split(',')
            }
            const location = this.getLocId(modelInstance.location)
            const item = this.$api.updateItem(this, `users/${this.userId}/locations/${location}/cards`, this.cardId, modelInstance)
            this.navigate('/locations')
            return item
        },
        async getCard() {
            const item = await this.$api.getItem(this, `users/${this.userId}/locations/${this.locId}/cards/`, this.cardId)
            if (item.beneficials) item.beneficials = item.beneficials.join(',')
            if (item.diseases) item.diseases = item.diseases.join(',')
            return item
        }
    },
    mounted() {
        this.$refs.dataForm.form.location = `users/${this.userId}/locations/${this.locId}`
    }
}
</script>

<style lang="scss">
    @import "/sass/variables_mixins";
    .cards-edit {
        margin: auto;

        .field-group-0 .form-elements {
            background-color: #fff;
            box-shadow: 0 0 20px rgba(0,0,0,.1);
            padding: 1.5rem 1.5rem .5rem;
            margin-bottom: 1rem !important;
        }

        form div.row {
            margin: 0 auto !important;
        }

        .field-group-0, .field-group-3 {
            width: 100%;

            @media (min-width:768px) {
                div.form-elements {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
                .field-group-0 {
                    .data-form-input {
                        width: 32%;
                    }
                }
            }
        }

        .field-group-0 {

            fieldset > div > div {
                font-weight: bold;
                padding-bottom: .5em;
            }

            @media (max-width: 767px) {
                > .form-elements {
                    flex-direction: column;

                    > div.data-form-input {
                        width: 100%;
                    }
                }
            }
            @media (min-width:768px) {
                .data-form-input {
                    margin-right: 1rem;
                }
            }

        }

        .field-group-2 {
            div[role=group] {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .custom-control-inline {
                    margin: 0;
                }
            }

            .custom-control {
                width: 48%;
                @media (min-width: 980px) {
                    width: 32%;
                }
            }
        }

        .field-group-3 {
            .data-form-input {
                width: 100%;
            }

            .data-form-input {
                .collapse {
                    background-color: #fff;
                    border: solid 1px #CED0CD;
                    border-top: none;
                    border-radius: 0 0 5px 5px;
                    overflow: hidden;
                    padding: 1.5em 1em;
                }

            }

            @media(min-width: 768px) {

                div.form-elements {
                    justify-content: space-between;
                }
                .data-form-input {
                    width: 49%;
                }
            }
        }

        .field-group-1, .field-group-2, .field-group-3 {
            div[role=button].not-collapsed {
                border-radius: 5px 5px 0 0;
            }

            .collapse {
                background-color: #fff;
                border: solid 1px lighten($neutral-border-color,10%);
                border-top: none;
                border-radius: 0 0 5px 5px;
                overflow: hidden;
                padding: 0 1.5em;

            }
        }
    }

.t-pests {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    padding-bottom: 20px;
    @media (min-width: 980px) {
      width: 50%;
    }
  }

  h6 {
    font-weight: bold;
    font-size: 1.1em;
  }
}

.t-beneficials {
  padding-bottom: 10px;

  > div {
    //padding-bottom: 10px;
  }
}

.t-notes {
  //margin-top: 1em;
}
/*
.cards-edit {
    .field-group-0 {
        .custom-select, input {
            background-color: #fff;
            width:100%;
        }
        @media (min-width: 601px) {
            max-width: 800px;
            .form-elements {
                display: flex;
                align-items: flex-start;
            }
            .data-form-input {
                margin-right: 1rem;
                width:100%;
            }
        }
    }
    textarea {
        background-color: #fff;
    }

    .field-group-3 {
        .data-form-input:last-child {
            max-width:800px;
        }
    }
}
*/




</style>
