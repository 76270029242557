<template>
    <div class="tasks-create narrow-form">
        <h1 class="page-heading">{{ $i18n.gt('TASKS_CREATE_HEADING') }}</h1>

        <data-form :field-model="taskModel"
                   :on-submit-handler="createTask"
                   :field-groups="fieldGroups"
                   cancel-route="/tasks"
                   :save-button-label="$i18n.gt('LABEL_SAVE')"
                   :cancel-button-label="$i18n.gt('LABEL_CANCEL')"
                   bottom-submit-button bottom-cancel-button/>

    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Task from "../models/Task"

export default {
    name: 'TasksCreate',
    data() {
        return {
            taskModel: Task,
            fieldGroups: [
                ['name', 'name_es', 'textSections'],
            ]
        }
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async createTask(modelInstance) {
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadModelTextSectionImages(modelInstance)

            const itemResponse = await this.$api.createItem(this, `tasks/`, modelInstance)

            // reload task list in store to include new name
            await Task.loadTasks()

            this.navigate(`/tasks`)
        }
    }
}
</script>

<style lang="scss">
.tasks-create {
  label.custom-file-label {
    z-index: 0;
  }
}
</style>
