<template>
    <div class="interactions">
        <b-row class="mt-5 page-filters">

            <div class="col-12 col-md-8 col-xl-6 offset-xl-2 mb-3 d-flex flex-column align-items-center" v-if="!selectedId">
                <!-- input for search text/toString of current object -->
                <b-form-input type="text"
                              list="my-list-id"
                              ref="searchInput"
                              autocomplete="off"
                              class="col-12"
                              :placeholder="$i18n.gt('INTERACTIONS_BENEFICIAL_PLACEHOLDER')"
                              v-model="searchText"
                              debounce="500"/>

                <datalist id="my-list-id">
                    <option v-for="(result, i) in searchItems" :key="i">
                        {{result.text}}
                    </option>
                </datalist>
            </div>
            <div v-else class="beneficial-name-container col-12 col-md-8 col-xl-6 offset-xl-2 mb-3 flex-column align-items-center position-relative">
                <template v-if="searchText === selectedBeneficial.name">
                    <div class="beneficial-name">
                        {{ selectedBeneficial.name }}
                    </div>
                    <h3 class="beneficial-name__clear"
                        @click="clearSelected">
                        <b-icon-x-circle size="lg" class="clear-search"/>
                    </h3>
                </template>
                <h1 v-else>
                    &nbsp;
                </h1>
            </div>
            <div class="col-12 col-md-4">
                <b-form-select v-model="sortBy"
                               class="sort-select"
                               :options="sortOptions"/>
            </div>

        </b-row>

        <b-row class="mt-3" v-if="!selectedId">
            <b-col class="md-8 xl-6 offset-xl-2">
                <span>{{ $i18n.gt('INTERACTIONS_NO_SELECTION') }}</span>
            </b-col>
        </b-row>

        <template v-else>
            <b-row class="justify-content-center mb-4 scientific-name">
                <span v-if="selectedBeneficial.scientificName">{{ selectedBeneficial.scientificName }}</span>
            </b-row>
            <b-row>
                <div v-for="(pesticide, i) in pesticides" :key="i" class="col-12 col-sm-6 col-lg-4 my-2">

                    <div class="interaction">
                        <div class="interaction__header">
                            <span>{{ pesticide.name }}</span>
                            <b-icon-pencil v-if="isAdmin" class="mt-1 edit-btn"
                                           v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')"
                                           @click="openEditModal(pesticide.id, pesticide.name)"/>
                        </div>
                        <b-row class="interaction__content mx-1 my-2" v-if="getValue(pesticide.id)">
                            <div class="col-2">
                                {{ $i18n.gt('INTERACTIONS_EGG') }}
                                <div class="int" :class="`int-${getValue(pesticide.id).egg}`">
                                    {{ getValue(pesticide.id).egg }}
                                </div>
                            </div>
                            <div class="col-2">
                                {{ $i18n.gt('INTERACTIONS_IMM') }}
                                <div class="int" :class="`int-${getValue(pesticide.id).imm}`">
                                    {{ getValue(pesticide.id).imm }}
                                </div>
                            </div>
                            <div class="col-3">
                                {{ $i18n.gt('INTERACTIONS_ADULT') }}
                                <div class="int" :class="`int-${getValue(pesticide.id).adult}`">
                                    {{ getValue(pesticide.id).adult }}
                                </div>
                            </div>
                            <div class="col-5">
                                {{ $i18n.gt('INTERACTIONS_PERSISTENCE') }}
                                <div class="interaction__persistence">
                                    {{ getValue(pesticide.id).persistence }}
                                </div>
                            </div>
                        </b-row>
                    </div>

                </div>
            </b-row>
        </template>
        <b-modal id="editModalId"
                 :title="`${$i18n.gt('INTERACTIONS_EDIT_MODAL_TITLE')} - ${editName}`"
                 :ok-title="$i18n.gt('LABEL_SAVE')" @ok.prevent="submitEdit"
                 :cancel-title="$i18n.gt('LABEL_CANCEL')" cancel-variant="danger">
            <b-row class="mx-3 my-2 justify-content-between">{{ $i18n.gt('INTERACTIONS_EGG') }}
                <b-form-input class="col-2" v-model="editEgg"/>
            </b-row>
            <b-row class="mx-3 my-2 justify-content-between">{{ $i18n.gt('INTERACTIONS_IMM') }}
                <b-form-input class="col-2" v-model="editImm"/>
            </b-row>
            <b-row class="mx-3 my-2 justify-content-between">{{ $i18n.gt('INTERACTIONS_ADULT') }}
                <b-form-input class="col-2" v-model="editAdult"/>
            </b-row>
            <b-row class="mx-3 my-2 justify-content-between">{{ $i18n.gt('INTERACTIONS_PERSISTENCE') }}
                <b-form-input class="col-5" v-model="editPersistence"/>
            </b-row>
        </b-modal>
    </div>
</template>

<script>

import Vue from "vue";
import {Capacitor} from "@capacitor/core";

export default {
    name: 'Interactions',
    props: {
        beneficialId: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            selectedId: '',
            searchText: '',
            results: [],
            selectedBeneficial: {},
            interactions: [],
            sortBy: 'ALPHA',
            editEgg: null,
            editImm: null,
            editAdult: null,
            editPersistence: null,
            editId: '', // id of pesticide currently being edited
            editName: ''
        }
    },
    computed: {
        showSelect() {
            return this.results.length > 0 && !this.selectedId
        },
        editModalId() {
            return 'editModalId'
        },
        sortOptions() {
            return [
                {text: this.$i18n.gt('INTERACTIONS_SORT_ALPHA'), value: 'ALPHA'},
                {text: this.$i18n.gt('INTERACTIONS_SORT_MORTALITY'), value: 'MORTALITY'},
            ]
        },
        pesticides() {
            if (this.sortBy === 'ALPHA') {
                return _.sortBy(this.$store.state.pesticides, ['name'])
            } else {
                // if sort by imm, add imm to pesticide objects
                const pesticides = this.$store.state.pesticides
                _.forEach(pesticides, (pesticide, i) => {
                    const imm = this.getValue(pesticide.id).imm
                    pesticides[i] = {...pesticide, imm}
                })
                return _.sortBy(pesticides, ['imm'])
            }
        },
        isAdmin() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.admin
            } else return !!this.$store.state.currentDbUser.admin
        },
        searchItems() {
            const storeBeneficials = _.map(this.$store.state.beneficials, (item) => {
                return {text: _.lowerCase(item.name), value: item.id}
            })
            const capped = _.map(storeBeneficials, (item) => {
                return {text: _.upperFirst(item.text), value: item.value}
            })
            return _.sortBy(capped, ['text'])
        },
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async getItems() {
            const items = await this.$api.getAllItems(this, `beneficials`)
            this.items = _.sortBy(items, 'name')
        },
        getValue(id) {
            return _.find(this.interactions, (int) => {
                return int.ref === id
            }) ?? ''
        },
        clearSelected() {
            this.searchText = ''
            this.interactions = []
            this.$router.push('/interactions')
            if (!Capacitor.isNativePlatform) {
                Vue.nextTick(() => {
                    this.$refs.searchInput.focus()
                })
            }
        },
        checkMatch() {
            const match = _.find(this.searchItems, (item) => {
                return item.text === this.searchText
            })
            this.selectedId = match.value
        },
        openEditModal(pesticideId, pesticideName) {
            this.$bvModal.show(this.editModalId)
            this.editId = pesticideId
            this.editName = pesticideName
            this.editEgg = this.getValue(pesticideId).egg
            this.editImm = this.getValue(pesticideId).imm
            this.editAdult = this.getValue(pesticideId).adult
            this.editPersistence = this.getValue(pesticideId).persistence
        },
        async submitEdit() {
            // Validate input
            let valid = true
            if (!this.editEgg
                || !this.editImm
                || !this.editAdult
                || !this.editPersistence
                || +this.editEgg < 1 || +this.editEgg > 4
                || +this.editImm < 1 || +this.editImm > 4
                || +this.editAdult < 1 || +this.editAdult > 4
            ) {
                this.$toasted.error(this.$i18n.gt('INTERACTIONS_EDIT_ERROR'))
                return
            }
            if (this.selectedBeneficial && !this.selectedBeneficial.pesticides) {
                this.selectedBeneficial.pesticides = []
            }
            const index = _.findIndex(this.selectedBeneficial.pesticides, (item) => {
                return item.ref === this.editId
            })
            const pesticide = {
                adult: this.editAdult,
                egg: this.editEgg,
                imm: this.editImm,
                persistence: this.editPersistence,
                ref: this.editId
            }

            if (index !== -1) {
                this.interactions[index] = pesticide
                this.selectedBeneficial.pesticides[index] = pesticide
            } else {
                this.interactions.push(pesticide)
                this.selectedBeneficial.pesticides.push(pesticide)
            }
            await this.$api.setItem(this, 'beneficials', this.selectedId, this.selectedBeneficial)
            this.$toasted.success(this.$i18n.gt('INTERACTIONS_EDIT_SUCCESS'))
            this.$bvModal.hide(this.editModalId)

            // clear edit fields
            this.editId = ''
            this.editName = ''
            this.editAdult = null
            this.editEgg = null
            this.editImm = null
            this.editPersistence = null
        }
    },
    mounted() {
        this.selectedId = this.beneficialId
    },
    watch: {
        // trigger search or clear values if searchText is cleared
        searchText: function (newVal, oldVal) {
            if (newVal.length >= 1) {
                this.checkMatch()
            } else {
                this.selectedId = null
            }
        },
        selectedId: async function (newVal, oldVal) {
            if (newVal) {
                this.selectedBeneficial = await this.$api.getItem(this, 'beneficials', this.selectedId)
                this.searchText = this.selectedBeneficial.name
                _.forEach(this.selectedBeneficial.pesticides, (pesticide) => {
                    this.interactions.push(pesticide)
                })
                // if loaded direct route, don't need to change route
                if (!this.$route.fullPath.includes(newVal)) {
                    this.$router.push(`/interactions/beneficials/${newVal}`)
                }
            }
        }
    }
}
</script>

<style lang="scss">

@import '/sass/variables_mixins';

.interactions {
    .edit-btn {
        cursor: pointer;
    }
    .int {
        width: 2em;
        height: 2em;
        line-height: 2em;
        text-align: center;
    }
    .int-1 {
        background-color: #96e57a;
    }
    .int-2 {
        background-color: #F0DE37;
    }
    .int-3 {
        background-color: #FEB932;
    }
    .int-4 {
        background-color: #FF7E86;
    }
    .typeahead-select {
        position: absolute;
        top: 2.35em;
        z-index: 100;
    }
    .clear-search {
        cursor: pointer;
        &:hover {
            color: #dc3545;
        }
    }
}

.interaction__header {
    height:44px;
    //background-color:$table-header-color;
    background-color:$dark-green;
    border:none;
    border-radius:.25rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:0 10px;
    font-size:1.15rem;
    color:#fff;
    font-weight: bold;
    svg {
        margin:0!important;
        path {
            fill:lighten($green,5%);
        }
        &:hover {
            path {
                fill: lighten($green,20%);
            }
        }
    }
}

.interaction__content {
    font-weight:bold;
    font-size:.9rem;
    color:$label-color;
    .interaction__persistence {
        font-weight:normal;
    }
}

.beneficial-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.beneficial-name {
    background-color: #fff;
    border: solid 1px $neutral-border-color;
    padding: 10px 16px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    font-weight: bold;
    font-size:1.5rem;
}

.beneficial-name__clear {
    line-height: 1;
    position: absolute;
    bottom:-9px;
    right:4px;
    z-index: 1;
    font-size: 28px;
    //color:#777;
    background-color: #fff;
    border-radius:50%;
    box-shadow: 0px 0px 2px rgba(0,0,0,.1);
}

</style>