<template>
    <div class="contact">
        <h1>Contact</h1>
    </div>
</template>

<script>

export default {
    name: 'Contact',
    data() {
        return {}
    },
    computed: {
    },
}
</script>

<style lang="scss">
.contact {
}
</style>