/**
 * Model class for User profile validation
 */

import {email, minLength, maxLength, numeric, required, sameAs} from 'vuelidate/lib/validators'
//const isYes = (value) => value === 1;

export default {
    fields: {
        firstName: {
            translationKey: 'PROFILE_FIRST_NAME',
            type: 'text',
            //validations: { required }
        },
        lastName: {
            translationKey: 'PROFILE_LAST_NAME',
            type: 'text',
            //validations: { required }
        },
        email: {
            translationKey: 'PROFILE_EMAIL',
            type: 'text',
            validations: { required, email },
            invalidFeedback: { email: 'Must be a valid email address' },
            descriptionKey: 'PROFILE_EMAIL_DESCRIPTION',
        },
        password: {
            translationKey: 'PROFILE_PASSWORD',
            type: 'password',
            validations: { required, minLengthPassword: minLength(6) },
            invalidFeedback: { minLengthPassword: 'Passwords must be 6 or more characters' }
        },
        passwordConfirm: {
            translationKey: 'PROFILE_CONFIRM_PASSWORD',
            type: 'password',
            validations: { required, sameAsPassword: sameAs('password') },
            invalidFeedback: { sameAsPassword: 'Passwords much match' }
        },
        zip: {
            translationKey: 'PROFILE_ZIP',
            type: 'text',
            validations: {
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(5)
            }
        },
        admin: {
            label: 'Admin',
            type: 'check',
        },
    }
}
