<template>
    <div class="cards-chart">
        <card-actions :loc-id="locId" :card-id="cardId" @card-changed="loadNewCard"/>
        <spinner-until :condition="cardLoaded">

            <b-row>
                <b-col class="pt-3 pb-2 d-flex align-items-center justify-content-between">
                    <h1>{{ $i18n.gt('CHART_TOOLTIP') }}</h1>
                    <div class="chart-actions" v-if="canShow">
                        <b-button v-b-toggle="`collapse-filters`" id="toggle-filters" size="sm">
                            {{ $i18n.gt('CARD_CHART_FILTERS') }} <b-icon-caret-down/>
                        </b-button>
                        <b-btn size="sm ml-1" class="float-right" v-if="!isNative()"
                               @click="exportData">
                            <b-icon-download/>
                        </b-btn>
                        <b-collapse id="collapse-filters">
                            <b-card no-body class="chart-filters">
                                <b-card-header class="d-flex justify-content-between">
                                    <h5>{{ $i18n.gt('CARD_CHART_FILTERS') }}</h5>
                                    <b-button v-b-toggle="`collapse-filters`" variant="light" size="sm" class="close-collapse">
                                        <b-icon-x-lg/>
                                    </b-button>
                                </b-card-header>
                                <b-card-body>
                                    <div class="chart-filters__top">
                                        <b-form-group :label="$i18n.gt('CARD_CHART_INTERVALS')">
                                            <b-form-select :options="dateIntervalOptions"
                                                           v-model="dateInterval"
                                                           @input="dateIntervalSelected"/>
                                        </b-form-group>
                                        <b-form-group :label="$i18n.gt('CARD_CHART_START')">
                                            <b-form-datepicker v-model="dateStart" id="start-date-filter"
                                                               @input="startOrEndDateSelected"/>
                                        </b-form-group>
                                        <b-form-group :label="$i18n.gt('CARD_CHART_END')">
                                            <b-form-datepicker v-model="dateEnd" id="end-date-filter"
                                                               @input="startOrEndDateSelected"/>
                                        </b-form-group>
                                    </div>
                                    <h5>{{ $i18n.gt('CARD_CHART_TABLE_HEADING') }}</h5>
                                    <div class="chart-filters__scouted">
                                        <b-form-group v-for="(series, index) in seriesToggles" :key="index" class="col-3">
                                            <b-form-checkbox v-model="series.visible"
                                                             :value="true"
                                                             :unchecked-value="false">
                                                {{ series.name }}
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-collapse>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="12" style="display: flex; flex-direction: column; align-items: center;">

                    <highcharts :options="chartOptions" class="chart" v-if="canShow"></highcharts>
                    <em v-else>{{ $i18n.gt('CARD_CHART_ROTATE_TIP') }}</em>

                    <div class="chart__table-legend">
                        <span class="legend-item legend-item--scout chart__table-legend--scout">Scout</span>
                        <span class="legend-item legend-item--application chart__table-legend--application">Application</span>

                    </div>
                    <table class="table table-reports">
                        <thead>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Actions</th>
                        </thead>
                        <tbody>
                        <tr v-for="event in eventsFilteredByDate"
                            :class="`event-${event.type}`">
                            <td>{{ formatDate(event.date) }}</td>
                            <td v-if="event.type == 'scout'">
                                <b-row>
                                    <b-col>
                                        <div v-for="pest in event.scout.pests">
                                            {{ pest.name }}: {{ pest.count }}
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div v-for="beneficial in event.scout.beneficials">
                                            {{ beneficial.name }}: {{ beneficial.count }}
                                        </div>
                                    </b-col>
                                </b-row>
                            </td>
                            <td v-if="event.type == 'application'">
                                <template v-if="event.application.beneficialApplied">
                                    {{ $i18n.getBeneficialName(event.application.beneficialApplied) + $i18n.gt('APPLICATION_APPLIED') }}
                                    {{ event.application.beneficialQuantity }}
                                </template>
                                <template v-else-if="event.application.pesticide">
                                    {{ $i18n.getPesticideName(event.application.pesticide) + $i18n.gt('APPLICATION_APPLIED') }}
                                    {{ event.application.pesticideQuantity }}
                                </template>
                                <template v-else>
                                    <em>{{ $i18n.gt('APPLICATION_INVALID') }}</em>
                                </template>
                            </td>
                            <td class="actions-cell">
                                <!-- action buttons -->
                                <div v-if="event.type == 'application'">
                                    <router-link class="btn btn-secondary btn-sm mr-2 btn-icon"
                                                 :to="`/locations/${locId}/cards/${cardId}/applications/${event.application.id}/view`"
                                                 v-b-popover.top.hover="$i18n.gt('VIEW_TOOLTIP')">
                                        <b-icon-eye-fill/>
                                    </router-link>
                                    <router-link class="btn btn-secondary btn-sm mr-2 btn-icon"
                                                 :to="`/locations/${locId}/cards/${cardId}/applications/${event.application.id}/edit`"
                                                 v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')">
                                        <b-icon-pencil/>
                                    </router-link>
                                    <b-btn variant="danger" size="sm"
                                           @click="deleteEvent(event.application.id, 'applications')"
                                           v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')">
                                        <b-icon-x-square-fill/>
                                    </b-btn>
                                </div>
                                <div v-if="event.type === 'scout'">
                                    <router-link class="btn btn-secondary btn-sm mr-2 btn-icon"
                                                 :to="`/locations/${locId}/cards/${cardId}/scouts/${event.scout.id}/view`"
                                                 v-b-popover.top.hover="$i18n.gt('VIEW_TOOLTIP')">
                                        <b-icon-eye-fill/>
                                    </router-link>
                                    <router-link class="btn btn-secondary btn-sm mr-2 btn-icon"
                                                 :to="`/locations/${locId}/cards/${cardId}/scouts/${event.scout.id}/edit`"
                                                 v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')">
                                        <b-icon-pencil/>
                                    </router-link>
                                    <b-btn variant="danger" size="sm"
                                           @click="deleteEvent(event.scout.id, 'scouts')"
                                           v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')">
                                        <b-icon-x-square-fill/>
                                    </b-btn>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
                <b-col cols="12" md="4">
                    <!-- filters -->

                </b-col>
            </b-row>
        </spinner-until>
    </div>
</template>

<script>

import * as _ from 'lodash'
import moment from 'moment'
import Papa from 'papaparse'
import Utils from '@/providers/Utils'
import {dateFormat} from "highcharts";
import {Capacitor} from "@capacitor/core";

export default {
    name: 'CardsChart',
    props: {
        locId: String,
        cardId: String,
    },
    data() {
        return {
            cardLoaded: false,

            currentCard: {},
            currentLocation: {},
            events: [], // scouts and applications

            // filters
            dateInterval: null,
            dateIntervalOptions: [
                {text: 'Select Interval', value: null},
                {text: '1 month', value: 1},
                {text: '3 months', value: 3},
                {text: '6 months', value: 6},
                {text: '12 months', value: 12},
            ],
            dateStart: null, // '2023-12-13',
            dateEnd: null,   // '2023-12-14',

            seriesToggles: [], // e.g. {name: 'Thrips', visible: true}
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        },
        isNative() {
            return Capacitor.isNativePlatform
        },
        canShow() {
            return this.$mq === 'lg' || this.$mq === 'xl'
        },
        // scout or application events filtered by date
        eventsFilteredByDate() {
            return _.filter(this.events, (event) => {
                return this.dateIsWithinFilter(event.date)
            })
        },
        chartOptions() {
            return {
                chart: {
                    type: 'spline',
                    backgroundColor: 'transparent'
                },
                legend: {enabled: this.chartSeries.length > 0}, // hide empty series from legend if no scouts
                plotOptions: {},
                credits: {enabled: false},
                exporting: {enabled: false},
                title: {text: `Report for ${this.currentLocation.name} / ${this.currentCard.name}`},
                xAxis: {
                    type: 'datetime',
                    labels: {
                        enabled: true,
                        formatter: function() {
                            return dateFormat('%e %b', this.value);
                        }
                    },
                    tickInterval: 7 * 24 * 3600 * 1000, // one week
                    plotBands: this.chartPlotBands, // computed
                    min: this.chartXMin, // computed
                    max: this.chartXMax, // computed
                },
                yAxis: {
                    labels: {enabled: true},
                    title: {text: 'Count'},
                    min:0,
                    max:10
                },
                tooltip: {enabled: true},
                series: this.chartSeries.length > 0 ? this.chartSeries : [{}] // if no plot points, add empty series to still show chart
            }
        },
        // data series to show on the chart - filtered by scout date
        chartSeries() {
            const seriesList = []
            _.each(this.eventsFilteredByDate, (event) => {
                const bugs = event.type == 'scout'
                    ? _.union(event.scout.pests, event.scout.beneficials)
                    : []
                _.each(bugs, (bug) => {
                    if (bug.name && this.showSeries(bug.name)) {
                        let series = _.find(seriesList, (s) => {
                            return s.name == bug.name
                        })
                        if (!series) {
                            series = {data: [], name: bug.name, visible: true}
                            seriesList.push(series)
                        }
                        series.data.push([moment(event.date).valueOf(), +bug.count])
                        series.data = _.orderBy(series.data, [0], ['asc'])
                    }
                })
            })
            return seriesList
        },
        applicationEvents() {
            return _.filter(this.eventsFilteredByDate, (event) => {
                return event.type == 'application'
            })
        },
        // plot bands to show on the chart - filtered by application date
        chartPlotBands() {
            const plotBands = []
            // group applications in case multiple ones occur on same day (to avoid clashing plot bands)
            const groupedApplications = _.groupBy(this.applicationEvents, 'date')
            _.each(groupedApplications, (group) => {
                const names = _.map(group, (event) => {
                    return this.$i18n.getBeneficialName(event.application.beneficialApplied)
                }).join(', ')

                plotBands.push({
                    from: moment(group[0].date).valueOf(),
                    to: moment(group[0].date).valueOf(),
                    label: {
                        text: `${names} applied`,
                        rotation: 270,
                        borderWidth: 8,
                        verticalAlign: 'middle',
                        inside: false,
                        style: {
                            textOverflow: 'ellipsis',
                            width: 150,
                        },
                        x: -8,
                    }
                })
            })
            return plotBands
        },
        chartXMin() {
            const minEvent = _.minBy(this.eventsFilteredByDate, (event) => {
                return event.date
            })
            const minApplication = _.minBy(this.applicationEvents, (event) => {
                return event.date
            })
            if (minEvent && minApplication) {
                return Math.min(moment(minEvent.date).valueOf(), moment(minApplication.date).valueOf())
            } else {
                return null
            }
        },
        chartXMax() {
            const maxEvent = _.maxBy(this.eventsFilteredByDate, (event) => {
                return event.date
            })
            const maxApplication = _.maxBy(this.applicationEvents, (event) => {
                return event.date
            })
            if (maxEvent && maxApplication) {
                return Math.max(moment(maxEvent.date).valueOf(), moment(maxApplication.date).valueOf())
            } else {
                return null
            }
        }
    },
    methods: {
        getLocation(locId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/`, locId)
        },
        getCard(locId, cardId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/${locId}/cards`, cardId)
        },
        dateIsWithinFilter(d) {
            return (!this.dateStart || moment(d).isSameOrAfter(this.dateStart)) &&
                (!this.dateEnd || moment(d).isSameOrBefore(this.dateEnd))
        },
        startOrEndDateSelected() {
            this.dateInterval = null
        },
        dateIntervalSelected() {
            this.dateEnd = moment().format('YYYY-MM-DD')
            this.dateStart = moment().subtract(this.dateInterval, 'months').format('YYYY-MM-DD')
        },
        formatDate(d) {
            return Utils.formatDate(d)
        },
        // collect all scout/application events, sort in reverse date order
        // - the date filters will be applied to this list
        async getEvents(locId, cardId) {
            const cardPath = `users/${this.userId}/locations/${locId}/cards/${cardId}`
            this.events = []
            this.seriesToggles = []

            // get scouts, attach pests and beneficials to them
            const scouts = await this.$api.getAllItems(this, `${cardPath}/scouts`)
            _.each(scouts, async (scout) => {
                this.addSeriesToggles(scout.pests)
                this.addSeriesToggles(scout.beneficials)
                this.events.push({type: 'scout', date: scout.date, scout: scout})
            })

            // get applications
            const applications = await this.$api.getAllItems(this, `${cardPath}/applications`)
            _.each(applications, (application) => {
                this.events.push({type: 'application', date: application.date, application: application})
            })

            // sort events
            this.events = _.reverse(_.sortBy(this.events, (event) => {
                return event.date
            }))
        },
        // bugs is a list of pests or beneficials
        addSeriesToggles(bugs) {
            _.each(bugs, (bug) => {
                let toggle = _.find(this.seriesToggles, (st) => {
                    return st.name == bug.name
                })
                if (!toggle) {
                    toggle = {name: bug.name, visible: true}
                    this.seriesToggles.push(toggle)
                }
            })
        },
        showSeries(name) {
            const toggle = _.find(this.seriesToggles, (st) => {
                return st.name == name
            })
            return toggle?.visible
        },

        async loadData(locId, cardId) {
            this.currentCard = await this.getCard(locId, cardId)
            this.currentCard.id = cardId
            this.currentLocation = await this.getLocation(locId)
            this.currentLocation.id = locId
            this.getEvents(locId, cardId)
        },

        async loadNewCard(path) {
            const segments = path.split('/')
            this.cardLoaded = false
            const locId = segments[2]
            const cardId = segments[4]
            this.loadData(locId, cardId)
            this.$emit('card-changed', `/locations/${locId}/cards/${cardId}/chart`)
            this.$router.push(`/locations/${locId}/cards/${cardId}/chart`)
            this.cardLoaded = true
        },

        exportData() {
            const data = _.map(this.eventsFilteredByDate, (event) => {
                const row = {type: event.type, date: event.date}
                if (event.type == 'scout') {
                    row.pests = _.map(event.scout.pests, (bug) => {
                        return `${bug.name}: ${bug.count}`
                    }).join(', ')
                    row.beneficials = _.map(event.scout.beneficials, (bug) => {
                        return `${bug.name}: ${bug.count}`
                    }).join(', ')
                    row.applications = ''
                } else if (event.type == 'application') {
                    row.pests = ''
                    row.beneficials = ''
                    row.applications = `${event.application.beneficialApplied}: ${event.application.count}`
                }
                return row
            })

            const csv = Papa.unparse(data)

            const dataStr = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
            const link = document.createElement("a")
            link.href = dataStr
            link.download = `Greenhouse Scout Report for ${this.currentLocation.name}, ${this.currentCard.name}.csv`
            document.body.appendChild(link) // Required for FF
            link.click()
        },
        async deleteEvent(id, collection) {
            if (await this.$bvModal.msgBoxConfirm(this.$i18n.gt('CARD_CHART_DELETE_EVENT'), {okVariant: 'danger', okTitle: this.$i18n.gt('DELETE_TOOLTIP')})) {
                await this.$api.deleteItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/${collection}`, {id: id})
                this.getEvents(this.currentLocation.id, this.currentCard.id)
            }
        },
    },
    async mounted() {
        this.cardLoaded = false
        this.loadData(this.locId, this.cardId)
        this.cardLoaded = true
    }
}
</script>

<style lang="scss">

@import "/sass/variables_mixins";

.cards-chart {
  max-width: 100vw;

  h1 {
      margin-bottom: 0;
  }

  div.chart {
      width: 100%;
  }

    @media (max-width: 1023px) {
        div.chart {
            width: calc(95vw - env(safe-area-inset-left) - env(safe-area-inset-right));
        }
        #collapse-filters {
            max-width: calc(85vw - env(safe-area-inset-left) - env(safe-area-inset-right));
        }
    }

  tr.event-scout {
    background-color: $neutral-light-green;
  }

  tr.event-application {
    background-color: lighten($rust,50%);
  }

  table {
      border-collapse:initial;
      //border-left:solid 2px #9DAB9F;
      border-left:none;
      //border-bottom:solid 2px #9DAB9F;
      thead {
          background-color: #004B23;
          //color:#C7E7CD;
          th {
              //border-top:solid 2px #9DAB9F;
              border-top:solid 2px #004B23;
              border-right-color:#076A1A;
          }
      }

      thead th:last-child {
          border-right:solid 2px #004B23;
      }
      td:first-child {
          //border-left:solid 2px #9DAB9F;
      }
      td:last-child {
          //border-right:solid 2px #9DAB9F;
      }

  }

    thead {
        background-color: #F3F4F3;
    }
    tbody tr:not(:first-child) {
        td {
            border-top:solid 1px #fff;
        }
    }
    tbody td:not(:last-child) {
        border-right: none;
    }
  .card {
      border:none;
  }


    .actions-cell {
        div {
            text-align: center;
            .btn {
                margin: .5rem !important;
                padding: .5rem .75rem;
            }
        }
    }



}

.chart {
    background-color: #fff;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-bottom: 30px;
    border:solid 2px $neutral-green;
}

#collapse-filters {
    position: absolute;
    right:42px;
    top:20px;
    width: 100vw;
    margin-top: 2.5em;
    z-index: 1;
    @media(min-width: 1200px) {
        width:75vw;
        max-width: 1000px;
    }
}

@media(max-width: 719px) {
    h1 {
       // margin-left: .5em !important;
    }
}

.chart-filters {
    .card-header {
        background-color: $dark-green;
        color:$light-green;
        border-bottom:none;
    }
    .card-body {
        background-color: #F3F4F3;
        border: solid 2px $neutral-border-color;
        border-top:none;
    }
    .b-form-datepicker {
        .form-control {
            border:none!important;
        }
    }
    .btn-light {
        border:none!important;
        background-color: $light-green;
    }
}

.chart-filters__top {
    display: flex;
    justify-content: space-between;
    fieldset {
        width:100%;
        &:not(:last-child){
            padding-right:10px;
        }
    }
    .form-control, select {
        background-color: #fff;
    }
}

.chart-filters__scouted {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.close-collapse {
    svg {
        path {
            fill: black !important;
        }
    }
}

.chart-actions {
    width: 30em;
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 2.25em;
    @media (max-width: 719px) {
        margin-top: -3em !important;
    }

     .btn-light {
        color:darken($neutral-green,25%);

        &:hover, &:focus {
            background-color: $green!important;
            border-color: $green!important;
            color:#fff;
            svg path {
                fill:#fff!important;
            }
        }

    }
}

.chart__table-legend {
    margin: 1rem auto;
    display: flex;
}

.legend-item {
    margin:0 .5rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: bold;
    &:before {
        content:'';
        display:inline-block;
        width:1rem;
        height:1rem;
        margin-right:.25rem;
    }
}

.legend-item--scout {
    &:before {
        background-color: lighten($green,30%);
    }
}

.legend-item--application {
    &:before {
        background-color: lighten($rust,35%);
    }
}

</style>
