<template>
    <div class="symptoms-create narrow-form">
        <h1 class="page-heading">{{ $i18n.gt('SYMPTOMS_CREATE_HEADING') }}</h1>
        <b-overlay :show="saving">
            <data-form :field-model="symptomModel"
                       :on-submit-handler="createSymptom"
                       :field-groups="fieldGroups"
                       cancel-route="/symptoms"
                       bottom-submit-button bottom-cancel-button/>
        </b-overlay>
    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Symptom from "../models/Symptom"
import Disease from "@/models/Disease";
import Pest from "@/models/Pest";

export default {
    name: 'SymptomsCreate',
    data() {
        return {
            symptomModel: Symptom,
            fieldGroups: [
                ['name', 'name_es', 'textSections', 'pests', 'diseases'],
            ],
            saving: false,
        }
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async createSymptom(modelInstance) {
            this.saving = true

            const diseases = modelInstance.diseases && modelInstance.diseases !== '' ? modelInstance.diseases.split(',') : []
            const pests = modelInstance.pests ? modelInstance.pests : []
            delete modelInstance.diseases
            delete modelInstance.pests

            Utils.initializeModelFields(modelInstance, ['name', 'name_es', 'textSections'])
            await Utils.uploadModelTextSectionImages(modelInstance)
            const itemResponse = await this.$api.createItem(this, `symptoms/`, modelInstance)

            await this.updateDiseases(diseases, itemResponse.id)
            await this.updatePests(pests, itemResponse.id)

            // reload symptom list in store to include new name
            await Symptom.loadSymptoms()
            this.saving = false

            this.navigate(`/symptoms/view/${itemResponse.id}`)
            this.$toasted.success(this.$i18n.gt('SYMPTOM_CREATED_SUCCESS'))
        },
        async updateDiseases(diseases, symptomId) {
            await Promise.all(diseases.map(async (id) => {
                const seg = id.split('/')
                const disease = await this.$api.getItem(this, 'diseases', seg[1])
                if (!disease.symptoms) disease.symptoms = ''
                disease.symptoms += disease.symptoms === '' ? `symptoms/${symptomId}` : `,symptoms/${symptomId}`
                await this.$api.updateItem(this, 'diseases', seg[1], disease)
            }))
            await Disease.loadDiseases()
        },
        async updatePests(pests, symptomId) {
            await Promise.all(pests.map(async (id) => {
                const seg = id.split('/')
                const pest = await this.$api.getItem(this, `pest_types/${seg[1]}/pests`, seg[3])
                if (!pest.symptoms) pest.symptoms = ''
                pest.symptoms += pest.symptoms === '' ? `symptoms/${symptomId}` : `,symptoms/${symptomId}`
                await this.$api.updateItem(this, `pest_types/${seg[1]}/pests`, seg[3], pest)
            }))
            await Pest.loadPestsNested()
            await Pest.loadPestsUnnested()
            await Pest.loadPestTypes()
        },
    }
}
</script>

<style lang="scss">
.symptoms-create {
  label.custom-file-label {
    z-index: 0;
  }
}
</style>
