<template>
    <div class="symptoms card-list">
        <b-row class="row-heading">
            <b-col class="d-flex justify-content-between">
                <h1>{{ $i18n.gt('SYMPTOMS_LABEL') }}</h1>
                <span class="btn-add--top">
                    <b-btn v-if="isAdmin"
                           @click="navigate('/symptoms/create')" variant="success">
                    <img src="../../assets/icons/clipboard-pulse.svg">
                    Add Symptom
                    <b-icon-plus-circle-fill/>
                </b-btn>
                </span>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="4" v-for="item in items" :key="item.id">
                <b-card no-body class="mb-3">
                    <b-card-header>
                        <b-row>
                            <b-col class="card-list__name">
                                {{ $i18n.getSymptomName(item.id) }}
                            </b-col>
                            <b-col class="text-right">
                                <b-button v-b-toggle="`collapse-${item.id}`" size="sm" variant="light">
                                    <b-icon-caret-down/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-header>
                    <b-collapse :id="`collapse-${item.id}`">
                        <b-card-body>

                            <text-section-view v-for="(section, index) in item.textSections"
                                               :key="index"
                                               :section="section"/>
                            <div class="card-list__associations">
                                <div v-if="listDiseases(item).length" class="mb-3">
                                    <h4>{{ $i18n.gt('DISEASES_HEADING') }}</h4>
                                    <div v-for="(disease, i) in listDiseases(item)" :key="i">
                                        <router-link :to="`/diseases/view/${disease.id}`">
                                            {{$i18n.getDiseaseName(disease.id)}}
                                        </router-link>
                                    </div>
                                </div>
                                <div v-if="listPests(item).length">
                                    <h4>{{ $i18n.gt('PESTS_HEADING') }}</h4>
                                    <div v-for="(pest, i) in listPests(item)" :key="i">
                                        <router-link :to="`/pests/view/${pest.id}`">
                                            {{$i18n.getPestName(pest.id)}}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                        <b-card-footer class="text-right"
                                       v-if="isAdmin">
                            <b-btn size="sm" variant="danger" class="btn-icon mr-3"
                                   v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')"
                                   @click="deleteSymptom(item)">
                                <b-icon-x-square-fill/>
                            </b-btn>
                            <b-btn size="sm" variant="success"
                                   v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')"
                                   @click="navigate(`/symptoms/${item.id}/edit`)" >
                                <b-icon-pencil-fill/>
                            </b-btn>
                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col class="d-flex justify-content-end">
                <b-btn v-if="isAdmin"
                       @click="navigate('/symptoms/create')" variant="success">
                    <img src="../../assets/icons/clipboard-pulse.svg">
                    Add Symptom
                    <b-icon-plus-circle-fill/>
                </b-btn>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import * as _ from 'lodash'
import Symptom from "@/models/Symptom"
import Pest from "@/models/Pest"
import Disease from "@/models/Disease";

export default {
    name: 'Symptoms',
    props: {
        symptomId: String,
    },
    data() {
        return {
            items: [],
        }
    },
    computed: {
        isAdmin() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.admin
            } else return !!this.$store.state.currentDbUser.admin
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async getItems() {
            this.items = _.sortBy(this.$store.state.symptoms, 'name')
        },
        async deleteSymptom(item) {
            if (confirm('Permanently delete this Symptom?')) {
                await this.$api.deleteItem(this, 'symptoms', item)
                const diseases = this.listDiseases(item)
                const pests = this.listPests(item)
                await Promise.all(diseases.map(async (disease) => {
                    let symptoms = disease.symptoms.split(',')
                    _.remove(symptoms, (symp) => {
                        return symp === `symptoms/${item.id}`
                    })
                    disease.symptoms = symptoms.join(',')
                    const diseaseId = disease.id
                    delete disease.id
                    await this.$api.updateItem(this, 'diseases', diseaseId, disease)
                }))
                await Promise.all(pests.map(async (pest) => {
                    let symptoms = pest.symptoms.split(',')
                    _.remove(symptoms, (symp) => {
                        return symp === `symptoms/${item.id}`
                    })
                    pest.symptoms = symptoms.join(',')
                    const pestId = pest.id
                    const pestTypeId = pest.pestTypeId
                    delete pest.id
                    delete pest.pestTypeId
                    delete pest.pestType
                    await this.$api.updateItem(this, `${pestTypeId}/pests`, pestId, pest)
                }))
                this.$toasted.success(this.$i18n.gt('SYMPTOM_DELETED_SUCCESS'))

                // reload beneficial list in store to include new name
                await Symptom.loadSymptoms()
                await Disease.loadDiseases()
                await Pest.loadPestsUnnested()
                await Pest.loadPestsNested()

                await this.getItems()
            }
        },
        listDiseases(symptom) {
            return _.filter(this.$store.state.diseases, (disease) => {
                return !!disease.symptoms && disease.symptoms.includes(symptom.id)
            })
        },
        listPests(symptom) {
            return _.filter(this.$store.state.pestsUnnested, (pest) => {
                return !!pest.symptoms && pest.symptoms.includes(symptom.id)
            })
        }
    },
    async mounted() {
        await this.getItems()
        if (this.symptomId) {
            setTimeout(async () => {
                await this.$root.$emit('bv::toggle::collapse', `collapse-${this.symptomId}`)
                document.getElementById(`collapse-${this.symptomId}`).parentElement.scrollIntoView()
                this.$router.replace('/symptoms')
            })
        }
    },
}
</script>

