/**
 * Model class for Tasks
 */

import Api from '@/providers/Api'
import * as _ from 'lodash'
import {store} from '@/store'
import i18n from '@/providers/i18n'
import {required} from "vuelidate/lib/validators";

export default {

    // load master list of tasks into the store
    async loadTasks() {
        store.state.tasks = await Api.getAllItems(null, 'tasks')
    },

    // build checkbox options based on master list
    buildTaskOptions() {
        const tasks = store.state.tasks
        let options = _.map(tasks, (task) => {
            return {
                value: `tasks/${task.id}`, text: i18n.getTaskName(task.id)
            }
        })
        return _.sortBy(options, ['text'])
    },

    fields: {
        name: {
            translationKey: 'TASK_NAME_FIELD',
            type: 'text',
            validations: { required }
        },
        name_es: {
            translationKey: 'TASK_NAME_ES_FIELD',
            type: 'text',
            // validations: { required }
        },
        textSections: {
            translationKey: 'TASK_TEXT_SECTIONS_FIELD',
            type: 'textsections',
        },
    }
}
