<template>
    <div class="beneficials-create narrow-form">
        <h1 class="page-heading">{{ $i18n.gt('BENEFICIALS_CREATE_HEADING') }}</h1>
        <b-overlay :show="saving">
            <data-form :field-model="beneficialModel"
                       :on-submit-handler="createBeneficial"
                       :field-groups="fieldGroups"
                       cancel-route="/beneficials"
                       bottom-submit-button bottom-cancel-button/>
        </b-overlay>
    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Beneficial from "../models/Beneficial"

export default {
    name: 'BeneficialsCreate',
    data() {
        return {
            beneficialModel: Beneficial,
            fieldGroups: [
                ['name', 'name_es', 'scientificName', 'mainImage', 'factSheetUrl', 'pests', 'textSections'],
            ],
            saving: false,
        }
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async createBeneficial(modelInstance) {
            this.saving = true
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadFile(modelInstance, 'mainImage')
            await Utils.uploadModelTextSectionImages(modelInstance)

            const itemResponse = await this.$api.createItem(this, `beneficials/`, modelInstance)

            // reload beneficial list in store to include new name
            await Beneficial.loadBeneficials()
            this.saving = false

            this.navigate(`/beneficials/view/${itemResponse.id}`)
        }
    }
}
</script>

<style lang="scss">
.beneficials-create {
  label.custom-file-label {
    z-index: 0;
  }
}
</style>
