<template>
    <div class="scouts-edit">
        <card-actions :loc-id="locId" :card-id="cardId" @card-changed="loadNewCard" confirm-change="SCOUT_CARD_CHANGE_CONFIRM" :disabled="!!scoutId"/>
        <spinner-until :condition="cardLoaded">
            <h1 class="page-heading">{{ scoutId ? $i18n.gt('SCOUT_EDIT_HEADING') : $i18n.gt('SCOUT_PAGE_HEADING') }}</h1>
            <hr v-if="cardId && locId">
            <b-row v-if="cardId && locId" class="mb-3">
                <b-col cols="12" md="7" class="mb-3 mb-md-0">
                    <b-overlay :show="saving">
                        <b-card>
                            <b-form-group :label="$i18n.gt('SCOUT_DATE_FIELD')">
                                <input type="date" class="date-select"
                                       v-model="scoutedDate">
                            </b-form-group>
                            <h5>{{ $i18n.gt('SCOUT_CREATE_HEADING') }}</h5>
                            <div class="form-tip mb-3">{{ $i18n.gt('SCOUT_INPUT_TIP') }}</div>
                            <div class="scout-input input-frame mb-3">
                                <legend class="col-form-label">{{ $i18n.gt('SCOUT_PEST_HEADING') }}</legend>
                                <b-row v-for="(pest, i) in currentPests" :key="i">
                                    <b-btn variant="danger" size="lg" @click="removeRelation('pest', i)"><b-icon-x-circle-fill/></b-btn>
                                    <b-input class="ml-3" v-model="scoutedPests[i]" inputmode="numeric" @change="addRemove"></b-input>
                                    <span class="ml-3">{{pest.name}}</span>
                                </b-row>
                                <b-form-select :options="addPestOptions"
                                               v-model="addPestSelected"
                                               @change="addRelation('pest')">
                                </b-form-select>
                            </div>
                            <div class="scout-input input-frame mb-3">
                                <legend class="col-form-label">{{ $i18n.gt('SCOUT_BENEFICIAL_HEADING') }}</legend>
                                <b-row v-for="(beneficial, i) in currentBeneficials" :key="i">
                                    <b-btn variant="danger" size="lg" @click="removeRelation('beneficial', i)"><b-icon-x-circle-fill/></b-btn>
                                    <b-input class="ml-3" v-model="scoutedBeneficials[i]" inputmode="numeric" @change="addRemove"></b-input>
                                    <span class="ml-3">{{beneficial.name}}</span>
                                </b-row>
                                <b-form-select :options="addBeneficialOptions"
                                               v-model="addBeneficialSelected"
                                               @change="addRelation('beneficial')">
                                </b-form-select>
                            </div>
                            <div class="scout-input input-frame mb-3">
                                <legend class="col-form-label">{{ $i18n.gt('SCOUT_DISEASE_HEADING') }}</legend>
                                <b-row v-for="(disease, i) in currentDiseases" :key="i">
                                    <b-btn variant="danger" size="lg" @click="removeRelation('disease', i)"><b-icon-x-circle-fill/></b-btn>
                                    <b-input class="ml-3" v-model="scoutedDiseases[i]" inputmode="numeric" @change="addRemove"></b-input>
                                    <span class="ml-3">{{disease.name}}</span>
                                </b-row>
                                <b-form-select :options="addDiseaseOptions"
                                               v-model="addDiseaseSelected"
                                               @change="addRelation('disease')">
                                </b-form-select>
                            </div>

                            <div class="scout-input input-frame collapse-toggle">
                                <div class="collapse-notes-toggle" v-b-toggle:collapse-notes>
                                    <legend class="col-form-label">{{ $i18n.gt('SCOUT_NOTES_FIELD') }}</legend>
                                    <b-icon-caret-down-fill class="when-closed"/>
                                    <b-icon-caret-up-fill class="when-open"/>
                                </div>
                                <b-collapse id="collapse-notes" class="collapse-notes">
                                    <b-form-textarea v-model="scoutedNotes">
                                    </b-form-textarea>
                                </b-collapse>
                            </div>

                            <div class="scout-input input-frame mt-3" :lang="$store.state.currentLang">
                                <legend class="col-form-label">{{ $i18n.gt('SCOUT_IMAGES_FIELD') }}</legend>
                                <div class="m-1">
                                    <div class="form-tip">
                                        {{ $i18n.gt('SCOUT_IMAGES_TIP') }}
                                        <br>
                                        <b-row v-if="scoutedImages.length > 0">
                                            <div v-for="(image, i) in scoutedImages" :key="i" class="image-container mx-2">
                                                <img :src="imagePreviews[i]">
                                                <b-btn variant="danger" @click="removeImage(i)" class="remove-image-button">
                                                    <b-icon-x-circle-fill/>
                                                </b-btn>
                                            </div>
                                        </b-row>
                                    </div>
                                    <div class="justify-content-center d-flex mb-1 mt-2">
                                        <b-file accept="image/jpeg, image/png, image/gif, image/heic, image/heif"
                                                v-model="imageInput"
                                                multiple
                                                :placeholder="$i18n.gt('FILE_PLACEHOLDER')"/>
                                    </div>
                                </div>
                            </div>

                            <div class="bottom-buttons">
                                <b-btn variant="danger" @click="$router.push('/locations')">
                                    <b-icon-x-circle-fill/>
                                    {{ $i18n.gt('LABEL_CANCEL') }}
                                </b-btn>
                                <b-btn variant="success" @click="submitScout" :disabled="invalid">
                                    <b-icon-cloud-arrow-up-fill/>
                                    {{ $i18n.gt('LABEL_SAVE') }}
                                </b-btn>
                                <b-btn variant="outline-primary" @click="createAnother" :disabled="invalid">
                                    <b-icon-cloud-arrow-up-fill/>
                                    {{ $i18n.gt('CARD_CREATE_ANOTHER_LABEL') }}
                                    <b-icon-plus-circle-fill/>
                                </b-btn>
                            </div>
                        </b-card>
                    </b-overlay>
                </b-col>
                <!-- Latest report column -->
                <div class="col-12 col-md-5">
                    <h5>{{ $i18n.gt('SCOUT_LATEST_REPORT') }}</h5>
                    <b-table v-if="!noLatestReport" class="latest-report" :items="latestReportItems"/>
                    <span v-else>{{ $i18n.gt('SCOUT_NO_PREVIOUS') }}</span>
                </div>
            </b-row>
            <b-row v-else class="justify-content-center">
                <b-col>
                    <h3>{{ $i18n.gt('SCOUT_NO_CARD') }}</h3>
                </b-col>
            </b-row>
        </spinner-until>
    </div>
</template>

<script>

import Vue from 'vue'
import moment from 'moment'
import cardFields from '@/models/Card'

export default {
    name: 'ScoutsEdit',
    props: {
        locId: String,
        cardId: String,
        scoutId: String,
    },
    data() {
        return {
            cardModel: cardFields,

            cardLoaded: true,
            selectedCard: '',
            currentCard: {},
            currentPests: [],
            currentDiseases: [],
            currentBeneficials: [],
            currentLocation: {},

            scoutedPests: [],
            scoutedBeneficials: [],
            scoutedDiseases: [],
            scoutedDate: '',
            scoutedNotes: '',
            scoutedImages: [],

            // image input buffer to prevent overwrite when selecting one at a time
            imageInput: [],

            addPestOptions: [{
                value: null,
                text: this.$i18n.gt('SCOUT_ADD_INSECT')
            }],
            addPestSelected: null,
            addBeneficialOptions: [{
                value: null,
                text: this.$i18n.gt('SCOUT_ADD_BENEFICIAL')
            }],
            addBeneficialSelected: null,
            addDiseaseOptions: [{
                value: null,
                text: this.$i18n.gt('SCOUT_ADD_DISEASE')
            }],
            addDiseaseSelected: null,

            latestReport: {},
            latestReportItems: [],
            noLatestReport: false,
            deletedImages: [],
            saving: false,
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        },
        imagePreviews() {
            return _.map(this.scoutedImages, (image) => {
                if (typeof image === 'string') {
                    return image
                }
                return URL.createObjectURL(image)
            })
        },
        invalid() {
            if (!this.scoutedDate) {
                return true
            }
            if (this.currentPests.length > 0 && (this.scoutedPests.length !== this.currentPests.length)) {
                return true
            }
            if (this.currentBeneficials.length > 0 && (this.scoutedBeneficials.length !== this.currentBeneficials.length)) {
                return true
            }
            if (this.currentDiseases.length > 0 && (this.scoutedDiseases.length !== this.currentDiseases.length)) {
                return true
            }
            if (this.currentPests.length < 1 && this.currentBeneficials.length < 1 && this.currentDiseases.length < 1) {
                return true
            }
            if (this.scoutedBeneficials.includes("") || this.scoutedBeneficials.includes(undefined) || this.scoutedPests.includes("") || this.scoutedPests.includes(undefined) || this.scoutedDiseases.includes("") || this.scoutedDiseases.includes(undefined)) {
                return true
            }
            return false
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        // computed values don't seem to update as consistently on mobile
        // this ensures that the validity of the form updates after each text input change
        addRemove() {
            this.currentPests.push({})
            this.currentPests.splice(this.currentPests.length -1, 1)
        },
        getCard(locId, cardId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/${locId}/cards`, cardId)
        },
        getLocation(locId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/`, locId)
        },
        async getReferences(references) {
            if (!references) return null
            const items = []
            // regex to split after last '/'
            const pattern = /\/(?!.*\/)/
            references.forEach(async (ref) => {
                const segments = ref.split(pattern)
                items.push({...(await this.$api.getItem(this, segments[0], segments[1])), ref: ref})
            })
            return items
        },
        async loadCard(location, card) {
            this.currentCard = await this.getCard(location, card)
            this.currentPests = await this.getReferences(this.currentCard.pests) ?? []
            this.currentDiseases = await this.getReferences(this.currentCard.diseases) ?? []
            this.currentBeneficials = await this.getReferences(this.currentCard.beneficials) ?? []
        },
        async loadNewCard(path) {
            const segments = path.split('/')
            this.cardLoaded = false
            await this.loadCard(segments[2], segments[4])
            this.currentLocation = await this.getLocation(segments[2])
            this.scoutedPests = []
            this.scoutedBeneficials = []
            this.scoutedImages = []
            this.scoutedDate = moment().format('yyyy-MM-DD')
            this.scoutedNotes = ''
            await this.loadRelationOptions()
            await this.loadLatestReport()
            this.cardLoaded = true
        },
        removeRelation(type, index) {
            switch (type) {
                case ('pest'):
                    this.addPestOptions.push({value: this.currentCard.pests[index], text: this.currentPests[index].name})
                    this.addPestOptions = _.sortBy(this.addPestOptions, ['text'])
                    this.currentCard.pests.splice(index, 1)
                    this.currentPests.splice(index, 1)
                    this.scoutedPests.splice(index, 1)
                    break
                case ('beneficial'):
                    this.addBeneficialOptions.push({value: this.currentCard.beneficials[index], text: this.currentBeneficials[index].name})
                    this.addBeneficialOptions = _.sortBy(this.addBeneficialOptions, ['text'])
                    this.currentCard.beneficials.splice(index, 1)
                    this.currentBeneficials.splice(index, 1)
                    this.scoutedBeneficials.splice(index, 1)
                    break
                case ('disease'):
                    this.addDiseaseOptions.push({value: this.currentCard.diseases[index], text: this.currentDiseases[index].name})
                    this.addDiseaseOptions = _.sortBy(this.addDiseaseOptions, ['text'])
                    this.currentCard.diseases.splice(index, 1)
                    this.currentDiseases.splice(index, 1)
                    this.scoutedDiseases.splice(index, 1)
                    break
            }
        },
        addRelation(type, ref = null) {
            let addition = ''
            switch (type) {
                case ('pest'):
                    addition = ref ? ref : this.addPestSelected
                    if (!this.currentCard.pests) {
                        this.currentCard.pests = []
                        this.currentPests = []
                    }
                    this.currentCard.pests.push(addition)
                    this.currentPests.push({
                        ref: addition,
                        name: _.find(this.addPestOptions, (pest) => {return pest.value === addition}).text
                    })
                    this.addPestOptions = _.filter(this.addPestOptions, (pest) => {
                        return pest && !this.currentCard.pests.includes(pest.value)
                    })
                    this.addPestSelected = null
                    break
                case ('beneficial'):
                    addition = ref ?? this.addBeneficialSelected
                    if (!this.currentCard.beneficials) {
                        this.currentCard.beneficials = []
                        this.currentBeneficials = []
                    }
                    this.currentCard.beneficials.push(addition)
                    this.currentBeneficials.push({
                        ref: addition,
                        name: _.find(this.addBeneficialOptions, (beneficial) => {return beneficial.value === addition}).text
                    })
                    this.addBeneficialOptions = _.filter(this.addBeneficialOptions, (beneficial) => {
                        return beneficial && !this.currentCard.beneficials.includes(beneficial.value)
                    })
                    this.addBeneficialSelected = null
                    break
                case ('disease'):
                    addition = ref ?? this.addDiseaseSelected
                    if (!this.currentCard.diseases) {
                        this.currentCard.diseases = []
                        this.currentDiseases = []
                    }
                    this.currentCard.diseases.push(addition)
                    this.currentDiseases.push({
                        ref: addition,
                        name: _.find(this.addDiseaseOptions, (disease) => {return disease.value === addition}).text
                    })
                    this.addDiseaseOptions = _.filter(this.addDiseaseOptions, (disease) => {
                        return disease && !this.currentCard.diseases.includes(disease.value)
                    })
                    this.addDiseaseSelected = null
                    break
            }
        },
        async loadRelationOptions () {
            await this.cardModel.fields.pests.loadOptionsUnnested()
            this.addPestOptions = this.cardModel.fields.pests.options
            this.addPestOptions.splice(0, 0, {
                value: null,
                text: this.$i18n.gt('SCOUT_ADD_INSECT')
            })
            await this.cardModel.fields.beneficials.loadOptions()
            this.addBeneficialOptions = this.cardModel.fields.beneficials.options
            this.addBeneficialOptions.splice(0, 0, {
                value: null,
                text: this.$i18n.gt('SCOUT_ADD_BENEFICIAL')
            })
            await this.cardModel.fields.diseases.loadOptions()
            this.addDiseaseOptions = this.cardModel.fields.diseases.options
            this.addDiseaseOptions.splice(0, 0, {
                value: null,
                text: this.$i18n.gt('SCOUT_ADD_DISEASE')
            })
            // remove currently selected options
            Vue.nextTick(() => {
                if (this.currentCard.pests) {
                    this.addPestOptions = _.filter(this.addPestOptions, (pest) => {
                        return pest && !this.currentCard.pests.includes(pest.value)
                    })
                }
                if (this.currentCard.beneficials) {
                    this.addBeneficialOptions = _.filter(this.addBeneficialOptions, (beneficial) => {
                        return beneficial && !this.currentCard.beneficials.includes(beneficial.value)
                    })
                }
                if (this.currentCard.diseases) {
                    this.addDiseaseOptions = _.filter(this.addDiseaseOptions, (disease) => {
                        return disease && !this.currentCard.diseases.includes(disease.value)
                    })
                }
            })
        },
        async createAnother() {
            this.cardLoaded = false
            await this.submitScout(false)
            this.scoutedDate = moment().format('yyyy-MM-DD')
            this.scoutedPests = []
            this.scoutedBeneficials = []
            this.scoutedDiseases = []
            this.scoutedNotes = ''
            this.scoutedImages = []
            window.scrollTo(0,0)
            if (this.locId && this.cardId) {
                await this.loadCard(this.locId, this.cardId)
                this.currentLocation = await this.getLocation(this.locId)
                this.selectedCard = `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}`
                await this.loadRelationOptions()
                await this.loadLatestReport()
            }
            this.cardLoaded = true
        },
        async submitScout(redirect = true) {
            this.saving = true
            const scoutingForm =  {
                date: moment(this.scoutedDate).format('YYYY-MM-DD') + moment().format(' HH:mm:ss'),
                pests: [],
                beneficials: [],
                diseases: [],
                notes: this.scoutedNotes,
                imageRefs: [],
            }
            _.forEach(this.currentCard.pests, (pest, i) => {
                scoutingForm.pests[i] = {
                    name: this.currentPests[i].name,
                    count: this.scoutedPests[i],
                    ref: pest
                }
            })
            _.forEach(this.currentCard.beneficials, (beneficial, i) => {
                scoutingForm.beneficials[i] = {
                    name: this.currentBeneficials[i].name,
                    count: this.scoutedBeneficials[i],
                    ref: beneficial
                }
            })
            _.forEach(this.currentCard.diseases, (disease, i) => {
                scoutingForm.diseases[i] = {
                    count: this.scoutedDiseases[i],
                    ref: disease
                }
            })

            // if previous report, remove images and image refs
            if (!this.noLatestReport) {
                await Promise.all(this.latestReport.imageRefs.map(async (ref) => {
                    const response = await this.$api.deleteStorageItem(this, ref)
                }))
                let updatedLatest = _.cloneDeep(this.latestReport)
                delete updatedLatest.id
                updatedLatest.imageRefs = []
                await this.$api.setItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/scouts`, this.latestReport.id, updatedLatest)
            }

            // sort out mix of old and new images and upload new ones to storage
            const newImages = []
            const oldImages = []
            if (this.scoutId) {
                _.forEach(this.scoutedImages, (image) => {
                    if (typeof image !== 'string') {
                        newImages.push(image)
                    } else {
                        oldImages.push(image)
                    }
                })
                this.scoutedImages = newImages
            }
            await this.$api.uploadMultiImage(this.userId, scoutingForm, 'imageRefs', this.scoutedImages)

            if (oldImages) {
                scoutingForm.imageRefs = [...oldImages, ...scoutingForm.imageRefs]
            }

            // delete deleted images
            if (this.deletedImages) {
                await Promise.all(this.deletedImages.map(async (image) => {
                    await this.$api.deleteStorageItem(this, image)
                }))
            }

            let itemResponse
            if (this.scoutId) {
                itemResponse = await this.$api.setItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/scouts/`, this.scoutId, scoutingForm)
            } else {
                itemResponse = await this.$api.createItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/scouts`, scoutingForm)
            }

            this.saving = false
            this.$toasted.success(this.$i18n.gt('SCOUT_CREATED_SUCCESS'))
            if (redirect) this.$router.push(`/locations/${this.locId}/cards/${this.cardId}/chart`)
        },
        async loadLatestReport() {
            let reports = await this.$api.getAllItems(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/scouts`)
            if (reports.length === 0) {
                this.noLatestReport = true
            }
            else {
                this.noLatestReport = false
                reports = _.sortBy(reports, ['date'])
                const latest = reports[reports.length - 1]
                this.latestReport = latest
                this.latestReportItems = []
                this.latestReportItems.push({item: 'Report Date', value: moment(latest.date).format('M/D/yy')})
                _.forEach(latest.pests, (pest) => {
                    this.latestReportItems.push({item: pest.name, value: pest.count})
                })
                _.forEach(latest.beneficials, (beneficial) => {
                    this.latestReportItems.push({item: beneficial.name, value: beneficial.count})
                })
                const diseases = {item: this.$i18n.gt('DISEASES_LABEL'), value: ''}
                if (!latest.diseases || latest.diseases.length === 0) {
                    diseases.value = this.$i18n.gt('SCOUT_NO_DISEASES')
                } else {
                    _.forEach(latest.diseases, async (disease, i) => {
                        if (typeof disease == 'object') {
                            disease = disease.ref
                        }
                        const segments = disease.split('/')
                        const diseaseDoc = await this.$api.getItem(this, segments[0], segments[1])
                        diseases.value += diseaseDoc ? diseaseDoc.name : this.$i18n.gt('SCOUT_DISEASE_MISSING')
                        if (i + 1 !== latest.diseases.length) diseases.value += ', '
                    })
                }

                this.latestReportItems.push(diseases)
            }
        },
        async loadScout() {
            const scout = await this.$api.getItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/scouts/`, this.scoutId)
            this.scoutedDate = scout.date ? moment(scout.date).format('yyyy-MM-DD') : this.scoutedDate

            this.currentPests = []
            this.scoutedPests = []
            const pestMap = _.map(scout.pests, (p) => p.ref)
            const removedPests = _.remove(this.currentCard.pests, (pest) => {
                return !pestMap.includes(pest)
            })
            _.forEach(removedPests, (p) => {
                this.addPestOptions.push({value: p, text: this.$i18n.getPestName(p)})
            })
            this.addPestOptions = _.sortBy(this.addPestOptions, ['text'])
            _.forEach(scout.pests, (pest, i) => {
                this.scoutedPests.push(pest.count)
                if (!this.currentCard.pests || !this.currentCard.pests.includes(pest.ref)) {
                    this.addRelation('pest', pest.ref)
                } else {
                    this.currentPests.push({
                        ref: pest.ref,
                        name: this.$i18n.getPestName(pest.ref)
                    })
                }
            })

            this.currentBeneficials = []
            this.scoutedBeneficials = []
            const benMap = _.map(scout.beneficials, (b) => b.ref)
            const removedBens = _.remove(this.currentCard.beneficials, (ben) => {
                return !benMap.includes(ben)
            })
            _.forEach(removedBens, (b) => {
                this.addBeneficialOptions.push({value: b, text: this.$i18n.getBeneficialName(b)})
            })
            this.addBeneficialOptions = _.sortBy(this.addBeneficialOptions, ['text'])
            _.forEach(scout.beneficials, (ben, i) => {
                this.scoutedBeneficials.push(ben.count)
                if (!this.currentCard.beneficials || !this.currentCard.beneficials.includes(ben.ref)) {
                    this.addRelation('beneficial', ben.ref)
                } else {
                    this.currentBeneficials.push({
                        ref: ben.ref,
                        name: this.$i18n.getBeneficialName(ben.ref)
                    })
                }
            })

            this.currentDiseases = []
            this.scoutedDiseases = []
            const disMap = _.map(scout.diseases, (d) => d.ref)
            const removedDis = _.remove(this.currentCard.diseases, (dis) => {
                return !disMap.includes(dis)
            })
            _.forEach(removedDis, (d) => {
                this.addDiseaseOptions.push({text: this.$i18n.getDiseaseName(d), value: d})
            })
            this.addDiseaseOptions = _.sortBy(this.addDiseaseOptions, ['text'])
            _.forEach(scout.diseases, (disease, i) => {
                this.scoutedDiseases.push(disease.count)
                if (!this.currentCard.diseases || !this.currentCard.diseases.includes(disease.ref)) {
                    this.addRelation('disease', disease.ref)
                } else {
                    this.currentDiseases.push({
                        ref: disease.ref,
                        name: this.$i18n.getDiseaseName(disease.ref)
                    })
                }
            })
            
            this.scoutedNotes = scout.notes
            if (scout.notes) {
                this.$root.$emit('bv::toggle::collapse', 'collapse-notes')
            }
            this.scoutedImages = scout.imageRefs ?? []
        },
        removeImage(index) {
            const deleted = this.scoutedImages.splice(index, 1)[0]
            if (typeof deleted === 'string') {
                this.deletedImages.push(deleted)
            }
        }
    },
    async mounted() {
        this.scoutedDate = moment().format('yyyy-MM-DD')
        if (this.locId && this.cardId) {
            await this.loadCard(this.locId, this.cardId)
            this.currentLocation = await this.getLocation(this.locId)
            this.selectedCard = `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}`
            await this.loadRelationOptions()
            await this.loadLatestReport()
            if (this.scoutId) {
                await this.loadScout()
            }
        }
        this.cardLoaded = true
    },
    watch: {
        imageInput: function (newVal, oldVal) {
            if (newVal.length + this.scoutedImages.length <= 3) {
                _.forEach(newVal, (val) => {
                    this.scoutedImages.push(val)
                })
            } else {
                this.$toasted.error(this.$i18n.gt('SCOUT_IMAGE_ERROR'))
                this.imageInput = ''
            }
        }
    }
}
</script>

<style lang="scss">

@import "/sass/variables_mixins.scss";

.scouts-edit {
    .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: 'Browse';
    }
    .custom-file-input:lang(es) ~ .custom-file-label::after {
        content: 'Navegar';
    }

    @media (min-width: 981px) {
        .page-heading {
            margin-top: 2.5rem;
        }
    }

    .scout-input {

        .row {
            margin: 1em 0;
            flex-wrap: nowrap;
            align-items: center;
            input {
                width: 5rem;
            }
            button.btn-danger {
                padding: .25rem .5rem;
                font-size: 14px;
            }
        }
        .image-container {
            position: relative;
            max-width: 30%;
            width: 30%;
            padding: 0
        }
        .remove-image-button {
            position: absolute;
            top: -.5em;
            right: -.5em;
        }

        &.collapse-toggle {
            padding: 0;
            .collapse-notes-toggle {
                width: 100%;
                display: flex;
                padding: 1em 1.5em;
                justify-content: space-between;
            }
            #collapse-notes {
                padding: 0 1.5em 1em 1.5em;
            }
            .collapsed .when-open,
            .not-collapsed .when-closed {
                display: none;
            }
            .bi-chevron-down, .bi-chevron-up {
                cursor: pointer;
            }
        }

    }
    .latest-report thead {
        display: none;
    }
}



</style>
