<template>
    <div class="data-form-input-typeahead">

        <!-- input for search text/toString of current object -->
        <b-form-input type="text"
                      :placeholder="field.placeholder"
                      v-model="searchText"
                      debounce="500"
                      :state="state(field)"/>

        <!-- list box with search results -->
        <b-form-select v-show="showSelect"
                       :options="resultOptions"
                       v-model="selectedResult"
                       :select-size="4"/>

        <!-- hidden input to store ID of selected object -->
        <input type="hidden"
               v-model="localValue">

    </div>
</template>

<script>
import Vue from 'vue'
import * as _ from 'lodash'

/**
 * A Typeahead widget for use with DataForm.
 *
 * The following attributes must be specified in the field model for the field using this widget:
 *
 *   * placeholder: string
 *   * noResultsLabel:string
 *   * getFieldObject: function returning the object corresponding to the ID in this field
 *       (this is NOT the object represented by the field model itself) - e.g.:
 *          (biometric) => biometric.participant
 *   * typeaheadToString: function returning a display string for the field object - e.g.:
 *          (participant) => {
 *              if (participant) {
 *                  return `${participant.fullname} ${participant.dob} ${participant.email}`
 *              } else {
 *                  return ''
 *              }
 *      }
 *   * doTypeaheadSearch: function invoking the appropriate API call to get search results - e.g.:
 *          (vue, searchText) => {
 *              return vue.$api.findPatients(vue, vue.$user.practitionerPracticeId(), searchText)
 *          }
 */

export default Vue.extend({
    name: 'DataFormInputTypeahead',
    props: {
        value: { type: String, required: true },
        $v: Object, // vuelidate validation model
        fieldModel: Object,
        fieldName: String,
        modelInstance: Object
    },
    data () {
        return {
            searchText: '',
            results: [],
            selectedResult: null,
        }
    },
    computed: {
        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        field () {
            return this.fieldModel.fields[this.fieldName]
        },
        // get the object corresponding to the current ID value in the field
        fieldObject () {
            if (this.modelInstance) {
                return this.field.getFieldObject(this.modelInstance)
            } else {
                return null
            }
        },
        // generate options for list box based on search results
        resultOptions () {
            if (this.results.length > 0) {
                return _.map(this.results, (participant) => {
                    return {
                        value: participant.id,
                        text: this.field.typeaheadToString(participant)
                    }
                })
            } else {
                return []
            }
        },
        showSelect () {
            return this.resultOptions.length > 0 && !this.selectedResult
        }
    },
    methods: {
        state (field) {
            const f = this.$v.form[this.fieldName]

            if (f) {
                return f.$dirty ? !f.$error : null
            } else {
                return null
            }
        },
        doSearch () {
            this.field.doTypeaheadSearch(this, this.searchText)
                .then((items) => {
                    this.results = items
                })
        },
    },
    watch: {
        // show description for existing value
        modelInstance: function (newVal, oldVal) {
            if (newVal.id) {
                this.searchText = this.field.typeaheadToString(this.fieldObject)
            }
        },
        // trigger search or clear values if searchText is cleared
        searchText: function (newVal, oldVal) {
            if (newVal.length >= 2) {
                this.doSearch()
            } else {
                this.results = []
                this.selectedResult = null
                this.$emit('input', null)
            }
        },
        // handle selection in list box
        selectedResult: function (newVal, oldVal) {
            if (newVal) { // = ID of selected item
                this.$emit('input', newVal)
                const result = _.find(this.results, (r) => {
                    return r.id === newVal
                })
                this.searchText = this.field.typeaheadToString(result)
                this.results = []
                this.selectedResult = null
            }
        },
    }

})
</script>

<style lang="scss">
.data-form-input-typeahead {
}
</style>
