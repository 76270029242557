<template>
    <div class="beneficials-edit narrow-form">
        <h1 class="page-heading">Edit Beneficial</h1>

        <b-row>
            <b-col cols="12">
                <b-overlay :show="saving">
                    <data-form :field-model="beneficialModel"
                               :load-model-instance="loadModelInstance"
                               :on-submit-handler="updateBeneficial"
                               :field-groups="fieldGroups"
                               cancel-route="/beneficials"
                               bottom-submit-button bottom-cancel-button/>
                </b-overlay>
            </b-col>
        </b-row>

    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Beneficial from "../models/Beneficial"
import Pest from "@/models/Pest";

export default {
    name: 'BeneficialsEdit',
    props: {
        beneficialId: String,
    },
    data() {
        return {
            modelInstance: {},
            beneficialModel: Beneficial,
            fieldGroups: [
                ['name', 'name_es', 'scientificName', 'mainImage', 'factSheetUrl', 'pests', 'textSections'],
            ],
            initialMI : {},
            saving: false,
        }
    },
    computed: {},
    methods: {
        async loadModelInstance() {
            const item = await this.$api.getItem(this, `beneficials`, this.beneficialId)
            item.pests = this.getPests()
            this.initialMI.pests = item.pests
            this.modelInstance = item
            return item
        },
        getPests() {
            const pests = _.filter(this.$store.state.pestsUnnested, (pest) => {
                return !!pest.beneficials && pest.beneficials.includes(this.beneficialId)
            })
            const pestArray = _.map(pests, (pest) => {
                return `pest_types/${pest.pestType.id}/pests/${pest.id}`
            })
            return pestArray
        },
        async updateBeneficial(modelInstance, vform) {
            this.saving = true
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadFile(modelInstance, 'mainImage')
            await Utils.uploadModelTextSectionImages(modelInstance)

            const pests = modelInstance.pests
            delete modelInstance.pests
            await this.updatePests(pests)

            await this.$api.updateItem(this, `beneficials`, this.beneficialId, modelInstance)

            // reload beneficial list in store (in case name changed)
            await Beneficial.loadBeneficials()
            this.saving = true
            this.$router.push(`/beneficials/view/${this.beneficialId}`)
            this.$toasted.success(this.$i18n.gt('BENEFICIAL_UPDATED_SUCCESS'))
        },
        async updatePests(pests) {
            const toRemove = _.filter(this.initialMI.pests, (initial) => {
                return !pests.includes(initial)
            })
            const toAdd = _.filter(pests, (pest) => {
                return !this.initialMI.pests.includes(pest)
            })
            await Promise.all(toRemove.map(async (id) => {
                const seg = id.split('/')
                const pest = await this.$api.getItem(this, `pest_types/${seg[1]}/pests`, seg[3])
                let pestBeneficials = pest.beneficials.split(',')
                _.remove(pestBeneficials, (beneficial) => {
                    return beneficial.split('/')[1] === this.beneficialId
                })
                pest.beneficials = pestBeneficials.join(',')
                await this.$api.updateItem(this, `pest_types/${seg[1]}/pests`, seg[3], pest)
            }))
            await Promise.all(toAdd.map(async (id) => {
                const seg = id.split('/')
                const pest = await this.$api.getItem(this, `pest_types/${seg[1]}/pests`, seg[3])
                pest.beneficials += pest.beneficials === '' ? `beneficials/${this.beneficialId}` : `,beneficials/${this.beneficialId}`
                await this.$api.updateItem(this, `pest_types/${seg[1]}/pests`, seg[3], pest)
            }))

            await Pest.loadPestsNested()
            await Pest.loadPestsUnnested()
            await Pest.loadPestTypes()
        },
    }
}
</script>

<style lang="scss">
.beneficials-edit {
  label.custom-file-label {
    z-index: 0;
  }

  img.location-image {
    max-height: 200px;
  }
}
</style>
