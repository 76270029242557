<template>
    <div class="image-viewer">
        <img :src="source" style="cursor: pointer;" @click="showingModal = true"/>
        <b-modal v-model="showingModal" :title="$i18n.gt('TEXTSECTIONS_IMAGE_FIELD')" size="lg">
            <img :src="source"/>
            <template v-slot:modal-footer>
                <b-btn size="sm" variant="secondary" :href="source" target="_blank"><b-icon-download/></b-btn>
            </template>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: 'ImageViewer',
    props: {
        source: String,
    },
    data() {
        return {
            showingModal: false,
        }
    },
}
</script>

<style lang="scss">
.image-viewer {
}
</style>
