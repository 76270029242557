<template>
    <div class="tutorial">
        <h1>Tutorial</h1>
    </div>
</template>

<script>

export default {
    name: 'Tutorial',
    data() {
        return {}
    },
    computed: {
    },
}
</script>

<style lang="scss">
.tutorial {
}
</style>