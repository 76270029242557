/**
 * Model class for Pests
 */

import Api from '@/providers/Api'
import * as _ from 'lodash'
import {store} from '@/store'
import i18n from '@/providers/i18n'
import Beneficial from '@/models/Beneficial'
import Symptom from '@/models/Symptom'
import {required} from "vuelidate/lib/validators";

// build checkbox options based on master list
function buildPestTypeOptions () {
    const pestTypes = store.state.pestTypes
    let options = _.map(pestTypes, (pestType) => {
        return {
            value: `pest_types/${pestType.id}`, text: i18n.getPestTypeName(pestType.id)
        }
    })
    options = _.sortBy(options, ['text'])
    return options
}

export default {

    // load master list of pests/types into the store
    async loadPestTypes() {
        const pestTypes = await Api.getAllItems(null, 'pest_types')
        store.state.pestTypes = pestTypes
    },

    async loadPestsNested() {
        const pestsNested = []
        const pestTypes = await Api.getAllItems(null, 'pest_types')
        for (const pestType of pestTypes) { // NB: don't use forEach here! (doesn't play well with async)
            const pests = await Api.getAllItems(null, `pest_types/${pestType.id}/pests`)
            pestsNested.push({
                pestTypeId: pestType.id,
                pestTypeName: pestType.name,
                pests: _.sortBy(pests, 'name'),
            })
        }
        store.state.pestsNested = _.sortBy(pestsNested, 'pestTypeName')
        // return new Promise(resolve => resolve(null))
    },

    async loadPestsUnnested() {
        const pestsUnnested = []
        const pestTypes = await Api.getAllItems(null, 'pest_types')
        for (const pestType of pestTypes) { // NB: don't use forEach here! (doesn't play well with async)
            const pests = await Api.getAllItems(null, `pest_types/${pestType.id}/pests`)
            _.forEach(pests, (pest) => {
                pest.pestType = pestType
                pestsUnnested.push(pest)
            })
        }
        store.state.pestsUnnested = _.sortBy(pestsUnnested, 'name')
    },

    buildPestTypeOptions: buildPestTypeOptions,

    // helper method to load pests
    buildPestOptionsNested() {
        const pestsNested = store.state.pestsNested
        const options = []
        _.forEach(pestsNested, (pn) => {
            options.push({
                groupName: i18n.getPestTypeName(pn.pestTypeId),
                typeId: pn.pestTypeId,
                options: _.map(pn.pests, (pest) => {
                    return {
                        value: `pest_types/${pn.pestTypeId}/pests/${pest.id}`,
                        text: i18n.getPestName(pest.id)
                    }
                })
            })
        })
        return options
    },

    // helper method to load pests without pest type nesting
    buildPestOptionsUnnested() {
        const pestsUnnested = store.state.pestsUnnested
        let options = _.map(pestsUnnested, (pest) => {
            return {
                value: `pest_types/${pest.pestType.id}/pests/${pest.id}`, text: i18n.getPestName(pest.id)
            }
        })

        return _.sortBy(options, ['text'])
    },

    fields: {
        name: {
            translationKey: 'PEST_NAME_FIELD',
            type: 'text',
            validations: { required }
        },
        name_es: {
            translationKey: 'PEST_NAME_ES_FIELD',
            type: 'text',
            // validations: { required }
        },
        scientificName: {
            translationKey: 'PEST_SCIENTIFIC_NAME_FIELD',
            type: 'text',
            // validations: { required }
        },
        pestTypeId: {
            translationKey: 'PEST_TYPE_FIELD',
            type: 'select',
            options: [],
            validations: { required },
            loadOptions: async function(model) {
                this.options = buildPestTypeOptions()
            },
        },
        mainImage: {
            translationKey: 'PEST_MAIN_IMAGE_FIELD',
            type: 'image',
        },
        factSheetUrl: {
            translationKey: 'PEST_FACT_SHEET_URL_FIELD',
            type: 'url',
        },
        symptoms: {
            translationKey: 'PEST_SYMPTOMS_FIELD',
            type: 'checks',
            options: [],
            class: 'disease-symptoms',
            loadOptions: async function(model) {
                this.options = Symptom.buildSymptomOptions()
            },
            collapsible: true,
        },
        beneficials: {
            translationKey: 'PEST_BENEFICIALS_FIELD',
            type: 'checks',
            options: [],
            loadOptions: async function(model) {
                this.options = Beneficial.buildBeneficialOptions()
            },
            collapsible: true,
        },
        textSections: {
            translationKey: 'PEST_TEXT_SECTIONS_FIELD',
            type: 'textsections',
        },
    }
}
