<template>
    <div class="applications-view">
        <b-row class="m-3 mt-4 justify-content-between">
            <h1>{{$i18n.gt('APPLICATION_VIEW')}}</h1>
            <div>
                <b-btn variant="success" class=" ml-2 btn-icon"
                       @click="$router.push(`/locations/${locId}/cards/${cardId}/applications/${applicationId}/edit`)">
                    <b-icon-pencil/>
                    {{ $i18n.gt('APPLICATION_EDIT') }}
                </b-btn>
                <b-btn class="delete-loc ml-2 btn-icon" variant="danger" @click="deleteApplication()">
                    <b-icon-x-lg/>
                    {{ $i18n.gt('APPLICATION_DELETE') }}
                </b-btn>
            </div>
        </b-row>
        <spinner-until :condition="loaded">
            <b-card class="data">
                <b-row>
                    <b-col class="">
                        <strong>{{ $i18n.gt('APPLICATION_DATE_FIELD') }}</strong>
                    </b-col>
                    <b-col class="">
                        {{ formatDate(application.date) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <strong>Location and Card</strong>
                    </b-col>
                    <b-col>
                        <span>
                            <router-link :to="`/locations/${locId}/view`">
                                <strong>{{location.name}}</strong>
                            </router-link>
                            -
                            <router-link :to="`/locations/${locId}/cards/${cardId}/view`">
                                {{card.name}}
                            </router-link>
                        </span>
                    </b-col>
                </b-row>
                <b-row v-if="application.pestTarget && application.pestTarget.length > 0">
                    <b-col class="">
                        <strong>{{ $i18n.gt('APPLICATION_TARGETED_PESTS') }}</strong>
                    </b-col>
                    <b-col class="">
                        <ul>
                            <li v-for="(pest, index) in getPests(application.pestTarget)" :key="index">
                                <router-link :to="pest.link">{{ pest.name }}</router-link>
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row v-if="application.diseaseTarget && application.diseaseTarget.length > 0">
                    <b-col class="">
                        <strong>{{ $i18n.gt('APPLICATION_TARGETED_DISEASES') }}</strong>
                    </b-col>
                    <b-col class="">
                        <ul>
                            <li v-for="(beneficial, index) in getDiseases(application.diseaseTarget)" :key="index">
                                <router-link :to="beneficial.link">{{ beneficial.name }}</router-link>
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row v-if="application.beneficialApplied">
                    <b-col class="">
                        <strong>{{ $i18n.gt('APPLICATION_APPLIED_BENEFICIAL') }}</strong>
                    </b-col>
                    <b-col class="">
                        <router-link :to="`/beneficials/view/${application.beneficialApplied.split('/')[1]}`">{{ $i18n.getBeneficialName(application.beneficialApplied)}}</router-link> : {{application.beneficialQuantity}}
                    </b-col>
                </b-row>
                <b-row v-if="application.pesticide">
                    <b-col class="">
                        <strong>{{ $i18n.gt('APPLICATION_APPLIED_PESTICIDE') }}</strong>
                    </b-col>
                    <b-col class="">
                        <router-link :to="`/pesticides/view/${application.pesticide.split('/')[1]}`">{{ $i18n.getPesticideName(application.pesticide)}}</router-link> : {{application.pesticideQuantity}}
                    </b-col>
                </b-row>
                <b-row v-if="application.tasks">
                    <b-col class="">
                        <strong>{{ $i18n.gt('APPLICATION_TASKS_FIELD') }}</strong>
                    </b-col>
                    <b-col class="">
                        <ul>
                            <li v-for="(task, index) in application.tasks" :key="index">
                                <span>{{$i18n.getTaskName(task)}}</span>
                            </li>
                        </ul>
                    </b-col>
                </b-row>
            </b-card>
        </spinner-until>
    </div>
</template>

<script>

import SpinnerUntil from "@/components/SpinnerUntil.vue";
import moment from 'moment'
import * as _ from "lodash";

export default {
    name: 'ApplicationsView',
    components: {SpinnerUntil},
    props: {
        locId: String,
        cardId: String,
        applicationId: String,
    },
    data() {
        return {
            application: {},
            location: {},
            card: {},
            loaded: false,
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format('MM/DD/YY')
        },
        async deleteApplication() {
            if (await this.$bvModal.msgBoxConfirm(this.$i18n.gt('APPLICATION_CONFIRM_DELETE'), {okVariant: 'danger', okTitle: this.$i18n.gt('DELETE_TOOLTIP')})) {
                await this.$api.deleteItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/applications`, {id: this.applicationId})
                this.$toasted.success(this.$i18n.gt('APPLICATION_DELETED_SUCCESS'))
                this.$router.push(`/locations/${this.locId}/cards/${this.cardId}/chart`)
            }
        },
        getPests(targetedPests) {
            const pestItems = []
            _.forEach(targetedPests, (targetedPest) => {
                pestItems.push({
                    name: this.$i18n.getPestName(targetedPest),
                    link: `/pests/view/${targetedPest.split('/')[3]}`,
                })
            })
            return pestItems
        },
        getBeneficials(appliedBeneficials) {
            const beneficialItems = []
            _.forEach(appliedBeneficials, (appliedBeneficial) => {
                beneficialItems.push({
                    name: this.$i18n.getBeneficialName(appliedBeneficial),
                    link: `/beneficials/view/${appliedBeneficial.split('/')[1]}`,
                })
            })
            return beneficialItems
        },
        getDiseases(targetedDiseases) {
            const diseaseItems = []
            _.forEach(targetedDiseases, (targetedDisease) => {
                diseaseItems.push({
                    name: this.$i18n.getDiseaseName(targetedDisease),
                    link: `/diseases/view/${targetedDisease.split('/')[1]}`,
                })
            })
            return diseaseItems
        },
    },
    async mounted() {
        this.application = await this.$api.getItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/applications/`, this.applicationId)
        this.location = await this.$api.getItem(this, `users/${this.userId}/locations/`, this.locId)
        this.card = await this.$api.getItem(this, `users/${this.userId}/locations/${this.locId}/cards/`, this.cardId)
        this.loaded = true;
    }
}
</script>

<style lang="scss">
div.applications-view {
    margin-top: 2em !important;

    .data > .card-body > .row:nth-child(odd){
        background-color: #e5e5e5;
    }
    .row {
        margin: .5em 1em;
    }
    ul {
        list-style-type: none;
        margin-bottom: 0;
    }
}

</style>