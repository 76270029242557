<template>
    <div class="diseases-create narrow-form">
        <h1 class="page-heading">{{ $i18n.gt('DISEASES_CREATE_HEADING') }}</h1>

        <data-form :field-model="diseaseModel"
                   :on-submit-handler="createDisease"
                   :field-groups="fieldGroups"
                   cancel-route="/diseases"
                   bottom-submit-button bottom-cancel-button/>

    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Disease from "../models/Disease"

export default {
    name: 'DiseasesCreate',
    data() {
        return {
            diseaseModel: Disease,
            fieldGroups: [
                ['name', 'name_es', 'symptoms', 'factSheetUrl', 'textSections'],
            ]
        }
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        async createDisease(modelInstance) {
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadModelTextSectionImages(modelInstance)

            const itemResponse = await this.$api.createItem(this, `diseases/`, modelInstance)

            // reload disease list in store to include new name
            await Disease.loadDiseases()

            this.navigate(`/diseases/view/${itemResponse.id}`)
        }
    }
}
</script>

<style lang="scss">
.diseases-create {
  label.custom-file-label {
    z-index: 0;
  }
}
</style>
