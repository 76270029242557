<template>
    <div class="data-form-input-checks-groups">
        <template v-for="(groupOptions, i) in options">
            <div v-bind:key="`div-${i}`">
                <h6 v-bind:key="`group-${i}`">
                    {{ groupOptions.groupName }}
                </h6>
                <b-form-checkbox-group
                    :checked="parseChecked(localValue, groupOptions.options)"
                    @change="onChange($event, i)"
                    :options="groupOptions.options"
                    v-bind:key="i"/>
            </div>
        </template>

    </div>
</template>

<script>
import Vue from 'vue'
import * as _ from 'lodash'
import Multiselect from 'vue-multiselect'

export default Vue.extend({
    name: 'DataFormInputChecksGroups',
    props: {
        value: {
            required: true
        },
        state: Boolean,
        options: {type: Array, required: true},
        selectedItems: Array // reloading with selection
    },
    data () {
        return {
            selectedArray2d: [], // 2d array that keeps track of values of each group, merged for overall value
            items: [],
            isLoading: false,
        }
    },
    computed: {
        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        parseChecked(checked, options) {
            const result = []
            if (checked) {
                _.forEach(options, (option) => {
                    if (checked.includes(option.value)) {
                        result.push(option.value)
                    }
                })
            }
            return result
        },
        onChange(checked, index) {
            this.selectedArray2d[index] = checked
            this.localValue = this.selectedArray2d.flat()
        },
        populateSelected() {
            const typeIds = _.map(this.options, (option) => {
                return option.typeId
            })
            _.forEach(this.value, (value) => {
                const address = value.split('/')
                const index = _.findIndex(typeIds, (type) => {
                    return type == address[1]
                })
                if (!this.selectedArray2d[index]) this.selectedArray2d[index] = []
                this.selectedArray2d[index].push(value)
            })
        }
    },
    mounted() {
        if (this.value && this.value.length > 0) {
            this.populateSelected()
        }
    }
})
</script>

<style lang="scss">
.data-form-input-checks-groups {
    > div > div {
        z-index: 0;
    }
}
</style>