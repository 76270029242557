/**
 * Model class for Card
 */

import {email, minLength, maxLength, numeric, required, sameAs} from 'vuelidate/lib/validators'
import i18n from '../providers/i18n'
import Api from '@/providers/Api'
import * as _ from 'lodash'
import { store } from "@/store"

import Beneficial from "@/models/Beneficial"
import Pest from "@/models/Pest"
import Location from "@/models/Location"
import Disease from "@/models/Disease"

/**
 * Helper method for loading cards
 * this function must be outside of export for use within this file
 * export contains a pointer for use outside of this file
 * @param modelField
 * @returns {Promise<void>}
 */
async function loadCardOptions (modelField) {
    if (store.state.currentUser.uid) {
        modelField.options = []
        const userId = store.state.currentUser.uid
        const locations = await Api.getAllItems(null, `users/${userId}/locations`)
        await Promise.all(locations.map( async (location) => {
            const locCards = await Api.getAllItems(null, `users/${userId}/locations/${location.id}/cards`)
            _.forEach(locCards, (card) => {
                modelField.options.push({
                    value: `users/${userId}/locations/${location.id}/cards/${card.id}`,
                    text: card.name
                })
            })
        }))
    }
}

async function loadCardOptionsGrouped (modelField) {
    let userId = ''
    if (store.state.maskUser.email) {
        userId = store.state.maskUser.id
    } else userId = store.state.currentUser.uid
    if (userId) {
        modelField.options = []
        const locations = await Api.getAllItems(null, `users/${userId}/locations`)
        await Promise.all(locations.map( async (location) => {
            const locCards = await Api.getAllItems(null, `users/${userId}/locations/${location.id}/cards`)
            const cardGroup = []
            _.forEach(locCards, (card) => {
                cardGroup.push({
                    value: `users/${userId}/locations/${location.id}/cards/${card.id}`,
                    text: card.name
                })
            })
            modelField.options.push({label: location.name, options: cardGroup})
        }))
    }
}

export default {
    loadCardOptions: loadCardOptions,
    loadCardOptionsGrouped: loadCardOptionsGrouped,
    fields: {
        location: {
            translationKey: 'CARD_LOCATION_FIELD',
            type: 'select',
            options: [],
            async loadOptions (model) {
                Location.loadLocationOptions(this)
            },
        },
        existingCards: {
            translationKey: 'CARD_EXISTING_FIELD',
            type: 'select',
            options: [],
            placeholder: 'CARD_EXISTING_PLACEHOLDER',
            async loadOptions (model) {
                loadCardOptionsGrouped(this)
            },
            // Autofill other form fields when a pre-existing card is selected
            async onChange (form, vmodel, fieldModel) {
                // split value into collection path and id
                if (form.existingCards) {
                    const card = form.existingCards.split('cards/')
                    const cardData = await Api.getItem({},`${card[0]}cards`, card[1])
                    vmodel.location.$model = cardData.location
                    vmodel.pests.$model = cardData.pests
                    vmodel.beneficials.$model = cardData.beneficials
                    vmodel.diseases.$model = cardData.diseases
                    vmodel.notes.$model = cardData.notes
                }
            }
        },
        name: {
            translationKey: 'CARD_NAME_FIELD',
            type: 'text',
            placeholder: 'Enter Card Name',
            validations: { required }
        },
        pests: {
            translationKey: 'CARD_PEST_FIELD',
            type: 'checksgroups',
            options: [],
            class: 'card-pests',
            loadOptions: async function(model) {
                this.options = Pest.buildPestOptionsNested(this)
            },
            loadOptionsUnnested: async function(model) {
                this.options = Pest.buildPestOptionsUnnested(this)
            },
            collapsible: true,
        },
        beneficials: {
            translationKey: 'CARD_BENEFICIAL_FIELD',
            type: 'checks',
            options: [],
            class: 'card-beneficials',
            loadOptions: async function(model) {
                this.options = Beneficial.buildBeneficialOptions()
            },
            collapsible: true,
        },
        diseases: {
            translationKey: 'CARD_DISEASES_FIELD',
            type: 'checks',
            options: [],
            loadOptions: async function(model) {
                this.options = Disease.buildDiseaseOptions()
            },
            collapsible: true,
        },
        notes: {
            translationKey: 'CARD_NOTES_FIELD',
            type: 'textarea',
            collapsible: true,
        },
    }
}
