import { store } from '@/store'
import i18n from "@/providers/i18n";
import Vue from 'vue'
import VueResource from 'vue-resource'
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage"

Vue.use(VueResource)

import {initializeApp} from "firebase/app"
import {Capacitor} from "@capacitor/core"
import {
    addDoc,
    CACHE_SIZE_UNLIMITED,
    collection,
    deleteDoc,
    doc,
    enableIndexedDbPersistence,
    getDoc,
    getDocs,
    initializeFirestore,
    query,
    setDoc,
    updateDoc
} from "firebase/firestore"
import 'firebase/storage'
import {
    createUserWithEmailAndPassword,
    getAuth,
    indexedDBLocalPersistence,
    initializeAuth,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateEmail,
    updatePassword
} from "firebase/auth"

// --------------------------------------------

const firebaseConfig = {
    apiKey: "AIzaSyA4k7GRKCYnm_HmIojpPbfdtLTg8LlbrCY",
    authDomain: "greenhouse-scout.firebaseapp.com",
    projectId: "greenhouse-scout",
    storageBucket: "greenhouse-scout.appspot.com",
    messagingSenderId: "86376345697",
    appId: "1:86376345697:web:4e2f24e842512486747a20"
};

// resolver function for Api.isInitialized, to be called after user is initialized in initApp()
let resolverFunction

const api = {
    db_env: 'greenhouse_prod',
    db: null,
    isInitialized: new Promise((resolve) => { resolverFunction = resolve }),

    async initApp () {
        if (window.location.hostname.includes('localhost') && !Capacitor.isNativePlatform()) {
            store.state.devMode = true
            this.db_env = 'greenhouse_dev'
        } else if (window.location.hostname.includes('firebaseapp.com') || window.location.hostname.includes('web.app')) {
            this.db_env = 'greenhouse_staging'
        }

        const app = initializeApp(firebaseConfig)
        if (Capacitor.isNativePlatform()) {
            initializeAuth(app, {
                persistence: indexedDBLocalPersistence
            });
        }
        this.db = initializeFirestore(app, {
            cacheSizeBytes: CACHE_SIZE_UNLIMITED
        })

        // load remembered user state from Firebase
        const uid = localStorage.getItem('uid')
        store.state.currentUser = uid ? {uid: uid} : null

        const mask= sessionStorage.getItem('maskUser')
        getAuth().onAuthStateChanged(async user => {
            await this.setUserState(user)
            resolverFunction(true)
            // TODO: if no active user, clear session storage maskUser
            if (mask) {
                store.state.maskUser = await this.getItem({}, 'users', mask)
                store.state.maskUser.id = mask
            }
        })
    },

    async signupUser (vue, email, password) {
        const auth = getAuth()
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            const user = userCredential.user
            // send verification email which directs back to home
            await sendEmailVerification(user)
            // create DB record for user
            await this.setItem(this, `users`, user.uid, { email: email, emailReadonly: email })

            await this.setUserState(user)

            await vue.$router.replace('/')

            return userCredential
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                vue.$toasted.error('Email already in use')
            }
        }
    },

    async loginUser (vue, email, password, redirect= true) {
        const auth = getAuth()
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password)
            const user = userCredential.user
            await this.setUserState(user)
            await i18n.loadLookups()
            if (redirect) await vue.$router.replace('/')
            vue.$toasted.success('Logged in')
            return userCredential
        } catch (error) {
            vue.$toasted.error(error.code)
        }
    },

    async logoutUser (vue) {
        const auth = getAuth()
        try {
            await signOut(auth)
            await this.setUserState(null)
            await vue.$router.replace('/')
            vue.$toasted.info('Signed Out')
        } catch (error) {
            vue.$toasted.error(error.code)
        }
    },

    isAdmin () {
        return store.state.currentDbUser.admin
    },

    isVerified () {
        const auth = getAuth()
        return auth.currentUser && auth.currentUser.emailVerified
    },

    async setUserState (fbUser) {
        if (fbUser) {
            store.state.currentUser = fbUser
            store.state.currentDbUser = await this.getItem(null, 'users', fbUser.uid)
            localStorage.setItem('uid', fbUser.uid)
        } else {
            store.state.currentUser = { uid: null }
            store.state.currentDbUser = { email: null }
            localStorage.setItem('uid', null)
        }
        if (!store.state.currentDbUser) {
            await this.setItem(this, `users`, fbUser.uid, { email: fbUser.email, emailReadonly: fbUser.email })
            await this.setUserState(fbUser);
        }
    },

    async refreshDbUserState () {
        store.state.currentDbUser = await this.getItem(null, 'users', store.state.currentUser.uid)
    },

    userLoggedIn () {
        return !!store.state.currentUser.uid
    },

    currentUserId () {
        return store.state.currentUser.uid
    },

    async resetPassword (vue, email) {
        const auth = getAuth()
        try {
            // send password reset email which redirects to login
            return await sendPasswordResetEmail(auth, email, { url: `${window.location.origin}/login` })
        } catch (error) {
            vue.$toasted.error(error.code)
        }
    },

    async updateEmail(vue, newEmail) {
        const auth = getAuth()
        try {
            await updateEmail(auth.currentUser, newEmail)
            vue.$toasted.success(i18n.gt('PROFILE_EMAIL_UPDATE_SUCCESS'))
        } catch (error) {
            vue.$toasted.error(i18n.gt('PROFILE_EMAIL_UPDATE_FAIL') + error)
            return false
        }
        try {
            await sendEmailVerification(auth.currentUser)
            vue.$toasted.success(i18n.gt('PROFILE_EMAIL_VERIFICATION_SENT'))
        } catch(error) {
            vue.$toasted.error(i18n.gt('PROFILE_EMAIL_VERIFICATION_SENT_FAIL') + error)
        }
        return true
    },

    async updatePassword (vue, newPassword) {
        const auth = getAuth()
        try {
            return await updatePassword(auth.currentUser, newPassword)
        } catch (error) {
            vue.$toasted.error(error.code)
        }
    },

    async sendEmail (vue, to, message, showToast = true) {
        const email = {
            to: to,
            message: message
        }

        const docRef = doc(collection(this.db, 'mail'))
        setDoc(docRef, email).then(() => {
            if (showToast) vue.$toasted.success('Email Sent')
        }, reason => {
            if (showToast) vue.$toasted.error(reason)
        })
    },

    async getItem (vue, collectionPath, id) {
        const docRef = doc(this.db, this.getFullCollectionPath(collectionPath), id)
        const docSnap = await getDoc(docRef)
        return docSnap.data()
    },

    // for use with DataTableLocalSort
    async getAllItems (vue, collectionPath) {

        let q = query(collection(this.db, this.getFullCollectionPath(collectionPath)))
        const documentSnapshots = await getDocs(q)

        const items = []
        documentSnapshots.forEach((doc) => {
            // tack the doc ID on to the rest of the doc data
            const data = _.cloneDeep(doc.data())
            data.id = doc.id
            items.push(data)
        })

        return new Promise(resolve => resolve(items))
    },

    // updates a Firestore doc, setting the given attributes (not overwriting the whole doc)
    async updateItem (vue, collectionPath, id, attrs) {
        const docRef = doc(this.db, this.getFullCollectionPath(collectionPath), id)
        await updateDoc(docRef, attrs)
    },

    async setItem (vue, collectionPath, id, attrs) {
        const docRef = doc(this.db, this.getFullCollectionPath(collectionPath), id)
        await setDoc(docRef, attrs)
    },

    async createItem (vue, collectionPath, item) {
        const docRef = await addDoc(collection(this.db, this.getFullCollectionPath(collectionPath)), item)
        return docRef
    },

    async deleteItem (vue, collectionPath, item) {
        const response = await deleteDoc(doc(this.db, this.getFullCollectionPath(collectionPath), item.id))
        return response
    },

    async deleteStorageItem (vue, path) {
        const storage = getStorage()
        const itemRef = ref(storage, path)
        deleteObject(itemRef).then(() => {
            return 'ok'
        }).catch((error) => {
            return error.message
        })
    },

    // a relative path (e.g. 'user') is resolved to e.g. 'greenhouse_dev/data/users';
    // an absolute path (e.g. '/greenhouse_dev') is used as is
    getFullCollectionPath (collectionPath) {
        if (_.startsWith(collectionPath, '/')) {
            return collectionPath
        } else {
            return `${this.db_env}/data/${collectionPath}`
        }
    },

    // uploads an image file (in fact any file type), and sets the given field on the modelInstance
    // (or other sub-object such as a TextSection) to the download URL generated by Firebase
    // - this includes a generated access token that is needed to retrieve the image from Firebase storage.
    async uploadImage (userId, modelInstance, fieldName, file, successCallback) {
        const storage = getStorage();
        const refString = userId + '/' +  new Date().getTime()
        const imageRef = ref(storage, refString)
        const uploadTask = uploadBytesResumable(imageRef, file)
        await uploadTask
        modelInstance[fieldName] = await getDownloadURL(uploadTask.snapshot.ref)
    },

    async uploadMultiImage (userId, modelInstance, fieldName, files) {
        const storage = getStorage();
        const refString = userId + '/' +  new Date().getTime()
        let uploadTasks = []
        for (let i = 0; i < files.length; i++) {
            const imageRef = ref(storage, refString+'-'+i)
            uploadTasks.push(uploadBytesResumable(imageRef, files[i]))
        }
        const downloadUrls = []
        await Promise.all(uploadTasks.map(async (task) => {
            await task
            downloadUrls.push(await getDownloadURL(task.snapshot.ref))
        }))
        modelInstance[fieldName] = downloadUrls
    },

    // ------------------ translations -----------------

    async loadTranslations () {
        const response = await Vue.http.get(`/translations.csv`)
        return response.data
    },

    // ------------------ Database Management -----------------

    async copyCollection(collections, fromPath, toPath){
        const collectionsClone = _.cloneDeep(collections)
        const collection = collectionsClone.shift()
        const absFromPath = fromPath + collection
        const absToPath = toPath + collection
        const items = await this.getAllItems(null, absFromPath)
        _.forEach(items, async (item) => {
            const id = item.id
            delete item.id
            await this.setItem(null, absToPath, id, item)
            if (collectionsClone.length > 0) {
                this.copyCollection(collectionsClone, `${absFromPath}/${id}/`, `${absToPath}/${id}/`)
            }
        })
    }
}

export default api
