<template>
    <div class="cards-create">
        <h1 class="page-heading">{{ $i18n.gt('CARDS_CREATE_HEADING') }}</h1>
        <b-overlay :show="saving">
            <data-form :field-model="cardModel"
                       :on-submit-handler="createCard"
                       ref="dataForm"
                       :field-groups="fieldGroups"
                       cancel-route="/locations"
                       :submit-success-message="successMessage"
                       submit-success-route="/locations"
                       bottom-submit-button bottom-cancel-button>
                <template v-slot:field-existingCards="form">
                    <b-btn variant="danger" @click="clearCopy" class="clear-existing" v-b-popover.top.hover="$i18n.gt('CARD_CLEAR_EXISTING_FIELD')">
                        <b-icon-x-square-fill/>
                    </b-btn>
                </template>
                <template v-slot:bottom-buttons="form">
                    <b-btn variant="outline-primary" @click="createAnother(form)"
                           :disabled="form.vuelidate.form && form.vuelidate.form.$invalid">
                        <b-icon-cloud-arrow-up-fill/>
                        {{ $i18n.gt('CARD_CREATE_ANOTHER_LABEL') }}
                        <b-icon-plus-circle-fill/>
                    </b-btn>
                </template>
            </data-form>
        </b-overlay>
    </div>
</template>

<script>

import CardFields from '../models/Card'
import Utils from '@/providers/Utils'

export default {
    name: 'CardsCreate',
    props: {
        initialLocId: String
    },
    data() {
        return {
            locId: this.initialLocId, // needs to be mutable in case user changes location on form
            cardModel: CardFields,
            fieldGroups: [
                ['location', 'existingCards', 'name'],
                ['pests'],
                ['beneficials'],
                ['diseases', 'notes']
            ],
            successMessage: this.$i18n.gt('CARD_CREATE_SUCCESS'),
            saving: false,
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        }
    },
    methods: {
        getLocId(path) {
            const pattern = /.*(locations\/)/g
            return path.replace(pattern, '')
        },
        navigate(route) {
            this.$router.push(route)
        },
        clearCopy() {
            let modelInstance = this.$refs.dataForm.form
            if (modelInstance.existingCards) {
                modelInstance.existingCards = ''
                modelInstance.pests = []
                modelInstance.beneficials = []
                modelInstance.diseases = []
                modelInstance.notes = ''
            }
        },
        async createAnother(form) {
            this.saving = true
            // submit form through dataform onDefaultSave method
            this.successMessage = this.$i18n.gt('CARD_CREATE_SUCCESS')
            this.successMessage += ': ' + form.form.name
            const itemResponse = await this.createCard(form.form)
            this.$toasted.success(this.successMessage)
            // reload existing card options to include card created by line above
            await this.cardModel.fields.existingCards.loadOptions()
            window.scrollTo(0, 0);
            // clear all fields (except location)
            form.form.name = ''
            form.form.existingCards = ''
            form.form.pests = []
            form.form.beneficials = []
            form.form.diseases = []
            form.form.notes = ''
            this.saving = false
        },
        async createCard(modelInstance) {
            Utils.initializeModelFields(modelInstance,['name', 'pests', 'beneficials', 'diseases', 'notes'])
            delete modelInstance.existingCards
            if (modelInstance.beneficials && typeof modelInstance.beneficials == 'string') {
                modelInstance.beneficials = modelInstance.beneficials.split(',')
            }
            if (modelInstance.diseases && typeof modelInstance.diseases == 'string') {
                modelInstance.diseases = modelInstance.diseases.split(',')
            }
            const location = this.getLocId(modelInstance.location)
            const item = this.$api.createItem(this, `users/${this.userId}/locations/${location}/cards/`, modelInstance)
            return item
        }
    },
    mounted() {
        this.$refs.dataForm.form.location = `users/${this.userId}/locations/${this.initialLocId}`
    }
}
</script>

<style lang="scss">

@import "/sass/variables_mixins";

.cards-create {
  margin: auto;

  .df-existingCards {
      width: 25% !important;
  }
  .clear-existing {
      margin-top: 32px;
      padding: 5px 10px;
      max-height: 46px;
      margin-left: -10px;
  }

  .field-group-0 .form-elements {
      background-color: #fff;
      box-shadow: 0 0 20px rgba(0,0,0,.1);
      padding: 1.5rem 1.5rem 0;
      margin-bottom: 1rem !important;
  }

  form div.row {
      margin: 0 auto !important;
  }

  .field-group-0, .field-group-3 {
    width: 100%;
    //max-width: unset;

    div.form-elements {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .data-form-input {
        //width:90%;padding-right:1em;
      }
    }
  }

  .field-group-0 {
    .data-form-input {
      width: 32%;
    }

    fieldset > div > div {
      font-weight: bold;
      padding-bottom: .5em;
    }

    @media (max-width: 767px) {
      .form-elements {
        flex-direction: column;

        > div.data-form-input {
          width: 100%;

          &.df-existingCards {
              width: 80% !important;
          }
        }
      }
    }
  }

  .field-group-2 {
    div[role=group] {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .custom-control-inline {
        margin: 0;
      }
    }

    .custom-control {
      width: 48%;
      @media (min-width: 980px) {
        width: 32%;
      }
    }
  }

  .field-group-3 {
    .data-form-input {
      width: 100%;
    }

    .data-form-input {
      .collapse {
        background-color: #fff;
        border: solid 1px #CED0CD;
        border-top: none;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        padding: 1.5em 1em;
      }

    }

    @media(min-width: 768px) {
      .data-form-input {
        width: 49%;
      }
    }
  }

  .field-group-1, .field-group-2, .field-group-3 {
    div[role=button].not-collapsed {
      border-radius: 5px 5px 0 0;
    }

    .collapse {
      background-color: #fff;
      border: solid 1px lighten($neutral-border-color,10%);
      border-top: none;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
      padding: 0 1.5em;

    }
  }
}

.t-pests {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    padding-bottom: 20px;
    @media (min-width: 980px) {
      width: 50%;
    }
  }

  h6 {
    font-weight: bold;
    font-size: 1.1em;
  }
}

.t-beneficials {
  padding-bottom: 10px;

  > div {
    //padding-bottom: 10px;
  }
}

.t-notes {
  //margin-top: 1em;
}

</style>
