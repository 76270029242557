<template>
    <div class="symptoms-edit narrow-form">
        <h1 class="page-heading">Edit Symptom</h1>
        <b-row>
            <b-col cols="12">
                <b-overlay :show="saving">
                    <data-form :field-model="symptomModel"
                               :load-model-instance="loadModelInstance"
                               :on-submit-handler="updateSymptom"
                               :field-groups="fieldGroups"
                               cancel-route="/symptoms"
                               bottom-submit-button bottom-cancel-button/>
                </b-overlay>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Symptom from "../models/Symptom"
import Disease from "../models/Disease"
import Pest from "../models/Pest"

export default {
    name: 'SymptomsEdit',
    props: {
        symptomId: String,
    },
    data() {
        return {
            modelInstance: {},
            symptomModel: Symptom,
            fieldGroups: [
                ['name', 'name_es', 'textSections', 'pests', 'diseases'],
            ],
            // keep a clone of initial model instance to keep track of changes to pests and diseases
            initialMI: {},
            saving: false,
        }
    },
    computed: {},
    methods: {
        async loadModelInstance() {
            const item = await this.$api.getItem(this, `symptoms`, this.symptomId)
            item.pests = this.getPests()
            item.diseases = this.getDiseases()
            this.initialMI.pests = item.pests
            this.initialMI.diseases = item.diseases !== '' ? item.diseases.split(',') : []
            this.modelInstance = item
            return item
        },
        async updateSymptom(modelInstance, vform) {
            this.saving = true
            // remove diseases and pests from model instance to save
            const diseases = modelInstance.diseases !== '' ? modelInstance.diseases.split(',') : []
            const pests = modelInstance.pests
            delete modelInstance.diseases
            delete modelInstance.pests

            await this.updateDiseases(diseases)
            await this.updatePests(pests)

            Utils.initializeModelFields(modelInstance, ['name', 'name_es', 'textSections'])
            await Utils.uploadModelTextSectionImages(modelInstance)

            await this.$api.updateItem(this, `symptoms`, this.symptomId, modelInstance)

            // reload symptom list in store (in case name changed)
            await Symptom.loadSymptoms()
            this.saving = false

            this.$router.push(`/symptoms/view/${this.symptomId}`)
            this.$toasted.success(this.$i18n.gt('SYMPTOM_UPDATED_SUCCESS'))
        },
        getDiseases() {
            const diseases = _.filter(this.$store.state.diseases, (disease) => {
                return !!disease.symptoms && disease.symptoms.includes(this.symptomId)
            })
            return _.map(diseases, (disease) => {
                return `diseases/${disease.id}`
            }).join(',')
        },
        async updateDiseases(diseases) {
            const toRemove = _.filter(this.initialMI.diseases, (initial) => {
                return !diseases.includes(initial)
            })
            const toAdd = _.filter(diseases, (disease) => {
                return !this.initialMI.diseases.includes(disease)
            })
            await Promise.all(toRemove.map(async (id) => {
                const seg = id.split('/')
                const disease = await this.$api.getItem(this, 'diseases', seg[1])
                let diseaseSymptoms = disease.symptoms.split(',')
               _.remove(diseaseSymptoms, (symptom) => {
                    return symptom.split('/')[1] === this.symptomId
                })
                disease.symptoms = diseaseSymptoms.join(',')
                await this.$api.updateItem(this, 'diseases', seg[1], disease)
            }))
            await Promise.all(toAdd.map(async (id) => {
                const seg = id.split('/')
                const disease = await this.$api.getItem(this, 'diseases', seg[1])
                disease.symptoms += disease.symptoms === '' ? `symptoms/${this.symptomId}` : `,symptoms/${this.symptomId}`
                await this.$api.updateItem(this, 'diseases', seg[1], disease)
            }))
            await Disease.loadDiseases()
        },
        getPests() {
            const pests = _.filter(this.$store.state.pestsUnnested, (pest) => {
                return !!pest.symptoms && pest.symptoms.includes(this.symptomId)
            })
            return _.map(pests, (pest) => {
                return `pest_types/${pest.pestType.id}/pests/${pest.id}`
            })
        },
        async updatePests(pests) {
            const toRemove = _.filter(this.initialMI.pests, (initial) => {
                return !pests.includes(initial)
            })
            const toAdd = _.filter(pests, (pest) => {
                return !this.initialMI.pests.includes(pest)
            })
            await Promise.all(toRemove.map(async (id) => {
                const seg = id.split('/')
                const pest = await this.$api.getItem(this, `pest_types/${seg[1]}/pests`, seg[3])
                let pestSymptoms = pest.symptoms.split(',')
                _.remove(pestSymptoms, (symptom) => {
                    return symptom.split('/')[1] === this.symptomId
                })
                pest.symptoms = pestSymptoms.join(',')
                await this.$api.updateItem(this, `pest_types/${seg[1]}/pests`, seg[3], pest)
            }))
            await Promise.all(toAdd.map(async (id) => {
                const seg = id.split('/')
                const pest = await this.$api.getItem(this, `pest_types/${seg[1]}/pests`, seg[3])
                pest.symptoms += pest.symptoms === '' ? `symptoms/${this.symptomId}` : `,symptoms/${this.symptomId}`
                await this.$api.updateItem(this, `pest_types/${seg[1]}/pests`, seg[3], pest)
            }))
            
            await Pest.loadPestsNested()
            await Pest.loadPestsUnnested()
            await Pest.loadPestTypes()
        },
    }
}
</script>

<style lang="scss">
.symptoms-edit {
  label.custom-file-label {
    z-index: 0;
  }

  img.location-image {
    max-height: 200px;
  }
}
</style>
